
import version_core              from "../../version.js";
import version_custom            from "../../../../custom/App.version.js";
import B_REST_Error              from "../B_REST_Error.js";
import B_REST_Utils              from "../B_REST_Utils.js";
import B_REST_API                from "../api/B_REST_API.js";
import { B_REST_Request_base }   from "../api/B_REST_Request.js";
import B_REST_Response           from "../api/B_REST_Response.js";
import B_REST_Descriptor         from "../descriptors/B_REST_Descriptor.js";
import B_REST_Model              from "../models/B_REST_Model.js";
import B_REST_ModelList          from "../models/B_REST_ModelList.js";
import B_REST_App_SharedList     from "./B_REST_App_SharedList.js";
import B_REST_App_RouteDef_base  from "./B_REST_App_RouteDef_base.js";
import B_REST_App_RouteInfo_base from "./B_REST_App_RouteInfo_base.js";

//NOTE: If we need to add new langs later, will need to add for frontend & backend's core & custom locs + imports for Vuetify locale. Check server's LocUtils
import locMsgs_core_fr from "../../loc/fr.json"; //NOTE: If we use this in the generator, we need to add " assert {type:'json'}" before the ";"
import locMsgs_core_en from "../../loc/en.json"; //NOTE: If we use this in the generator, we need to add " assert {type:'json'}" before the ";"
import locMsgs_core_es from "../../loc/es.json"; //NOTE: If we use this in the generator, we need to add " assert {type:'json'}" before the ";"



/*
Singleton class req for descriptors & models to work
Extend it in usage and set singleton ptr so it gets used in those classes
Note that we can refer to it globally via window.bRESTApp
*/

export default class B_REST_App_base
{
	static get CORE_LANGS() { return ["fr","en","es"]; } //Frontend's B_REST_App_base::CORE_LANGS must match w server's LocUtils::_CORE_LANGS
	
	static get WINDOW_SINGLETON_PROP_NAME() { return 'bRESTApp'; } //Make it accessible in the console too, via window.bRESTApp
	
	static get BOOT_STATUS_IDLE()    { return 'idle';    }
	static get BOOT_STATUS_LOADING() { return 'loading'; }
	static get BOOT_STATUS_DONE()    { return 'done';    }
	
	static get REBOOT_REASONS_MAINTENANCE()      { return 'maintenance';     } //Server's autoload.php died because maintenance.json said we should be down
	static get REBOOT_REASONS_VERSION()          { return 'version';         } //Because was holding data about the app in a prev version
	static get REBOOT_REASONS_TOKEN()            { return 'token';           } //Token not found / expired
	static get REBOOT_REASONS_USER()             { return 'user';            } //User disabled / resetting pwd
	static get REBOOT_REASONS_PERMS()            { return 'perms';           } //Trying to go somewhere we shouldn't. NOTE: We have in frontend's B_REST_App_base REBOOT_REASONS_PERMS, routes_go_x_403() & _routes_beforeNavigationChange(), and RouteParser_base::output_json_injectCore_redirect_403() in server
	static get REBOOT_REASONS_LOGOUT_MANUAL()    { return 'logoutManual';    } //From server's RouteParser_base::LOGOUT_REASONS_MANUAL
	static get REBOOT_REASONS_LOGOUT_DIRECTIVE() { return 'logoutDirective'; } //From server's RouteParser_base::LOGOUT_REASONS_DIRECTIVE
		//WARNING: If we rename, has impact in loc files. Check "app.booter.rebootReasons.x" + in RouteParser_base::LOGOUT_REASONS_x
	
	static get ROUTES_TRAVEL_DIR_UNRELATED() { return 'unrelated'; }
	static get ROUTES_TRAVEL_DIR_TO_CHILD()  { return 'toChild';   }
	static get ROUTES_TRAVEL_DIR_TO_PARENT() { return 'toParent';  }
	
	static get WIFI_CHECK_INTERVAL_SECS()  { return 5;    }
	static get REJECT_UNSUCCESSFUL_CALLS() { return true; }
	static get MAX_PARALLEL_CONNECTIONS()  { return 4;    }
	
	static get TWEAK_REQUEST_ASYNC_HANDLER_ADD_QSA_CORE_CALLS() { return [B_REST_App_base.API_CALL_BOOT,B_REST_App_base.API_CALL_LOGIN,B_REST_App_base.API_CALL_SUDO_IN,B_REST_App_base.API_CALL_SUDO_OUT]; }
	static get TWEAK_REQUEST_ASYNC_HANDLER_ADD_QSA_ALWAYS()     { return true; } //If we add app info QSA to all calls, or only core ones like boot, login/out, sudoIn/out
	
	static get LOC_PATH_MODELS()      { return 'models';      } //As in "models.<modelName>.fields.<fieldName>"
	static get LOC_PATH_FIELDS()      { return 'fields';      } //As in "models.<modelName>.fields.<fieldName>"
	static get LOC_PATH_VALIDATION()  { return 'validation';  } //General purpose
	static get LOC_PATH_PLACEHOLDER() { return 'placeholder'; } //General purpose
	static get LOC_KEY_LABEL()        { return 'label';       } //As in {label, shortName, enum:{a,b,c}, bool_null, bool_true, bool_false}
	static get LOC_KEY_SHORT_LABEL()  { return 'shortLabel';  } //As in {label, shortName, enum:{a,b,c}, bool_null, bool_true, bool_false}
	static get LOC_KEY_ENUM_TAGS()    { return 'enum';        } //As in {label, shortName, enum:{a,b,c}, bool_null, bool_true, bool_false}
	static get LOC_KEY_BOOL_NULL()    { return 'bool_null';   } //As in {label, shortName, enum:{a,b,c}, bool_null, bool_true, bool_false}
	static get LOC_KEY_BOOL_TRUE()    { return 'bool_true';   } //As in {label, shortName, enum:{a,b,c}, bool_null, bool_true, bool_false}
	static get LOC_KEY_BOOL_FALSE()   { return 'bool_false';  } //As in {label, shortName, enum:{a,b,c}, bool_null, bool_true, bool_false}
	
	static get LS_KEY_BOOT_CALL_CACHE() { return 'bootCallCache'; }
	static get LS_KEY_V_FRONTEND()      { return 'v_frontend';    }
	static get LS_KEY_V_BACKEND()       { return 'v_backend';     }
	static get LS_KEY_ACCESS_TOKEN()    { return 'accessToken';   }
	static get LS_KEY_USER()            { return 'user';          }
	static get LS_KEY_LOCALE_LANG()     { return 'locale_lang';   }
	static get LS_KEY_GDPR()            { return 'gdpr';          }
	static get LS_KEY_PERMS()           { return 'perms';         }
	static get LS_KEY_REBOOT_REASON()   { return 'rebootReason';  }
	
	static get API_CALL_BOOT()                         { return '/core/boot';                                              }
	static get API_CALL_LOGIN()                        { return '/core/login';                                             }
	static get API_CALL_VERIFY_X_SEND_CODE()           { return '/core/verifyX_sendCode/{fieldName}';                      }
	static get API_CALL_VERIFY_X_CONFIRM()             { return '/core/verifyX_confirm/{fieldName}';                       }
	static get API_CALL_CHECK_UNICITY()                { return '/core/checkUnicity';                                      }
	static get API_CALL_RESET_PWD_SEND_EMAIL()         { return '/core/resetPwd_sendEmail';                                }
	static get API_CALL_RESET_PWD_SAVE()               { return '/core/resetPwd_save';                                     }
	static get API_CALL_LOGOUT()                       { return '/core/logout';                                            }
	static get API_CALL_SUDO_IN()                      { return '/core/sudoIn';                                            }
	static get API_CALL_SUDO_OUT()                     { return '/core/sudoOut';                                           }
	static get API_CALL_HEARTBEAT()                    { return '/core/heartbeat';                                         }
	static get API_CALL_MODEL_FILES_PENDING_UPLOADS()  { return '/core/modelFiles/pendingUploads/{modelName}/{fieldName}'; }
	static get API_CALL_CUSTOM_FILES_PENDING_UPLOADS() { return '/core/customFiles/pendingUploads/{customType}';           }
	static get API_CALL_MODEL_TO_LABEL_CACHE()         { return '/core/toLabelCache';                                      }
		static _BAD_AUTH_CALLS_TO_IGNORE = [B_REST_App_base.API_CALL_LOGIN, B_REST_App_base.API_CALL_SUDO_IN, B_REST_App_base.API_CALL_SUDO_OUT, B_REST_App_base.API_CALL_RESET_PWD_SEND_EMAIL, B_REST_App_base.API_CALL_RESET_PWD_SAVE]; //For tweakResponse_async_handler()
	
	//Must be identical w server. Check bREST_base & RouteParser_base
	static get PENDING_UPLOADS_FIELDNAME_SINGLE()   { return "file";    }
	static get PENDING_UPLOADS_FIELDNAME_MULTIPLE() { return "files[]"; }
	
	//Must be identical w server. Check bREST_base & RouteParser_base
	static get REQUEST_QSA_FRONTEND_ROUTE_INFO() { return "_routeInfo"; }
	static get REQUEST_QSA_REFERRER_URL()        { return "_referrer";  }
	static get REQUEST_QSA_SUDO_HASH()           { return "_sh";        }
	static get FRONTEND_QSA_SUDO_HASH()          { return "_sh";        } //Accessible via B_REST_App_base::boot_sudoHash()
	static get ROUTES_QSA_INTENDED_FULL_PATH()   { return "_i";         } //Ex "/404?_i=%2FsomeOtherRoute%2F". Accessible via B_REST_App_base::routes_current_qsa_intendedFullPath()
	static get REQUEST_QSA_REDIRECT_FULL_PATH()  { return "_redirect";  } //For _login_sudoX(). Ex "/core/login?_redirect=%2FsomeOtherRoute%2F"
	
	static get ROUTES_PATH_VARS_PK_TAG() { return "pkTag"; } //Ex for "/mesClients/:pkTag/mesFactures" or "/citizens/:citizen/animals/:pkTag" //WARNING: Lots of impacts if we change the following, ex routes_go_moduleForm_pkTag()
	
	static get MODELS_TO_LABEL_CACHE_THROTTLE_MSECS() { return 100; } //Check models_toLabelCache_x() docs
	
	static _instance = null; //Global instance of a B_REST_App_base derived class
	
	
	_businessConfig                  = null;                                         //Check @/bREST/core/implementations/vue/nodeScripts/vue.config.cjs for what this is about + possible props. WARNING: For now, this only makes sense if used w Vue, so w B_REST_VueApp_base
	_v_frontend                      = null;                                         //Frontend version as "<custom app version>@<bREST core version>", ex "1234@1.2". Has same scheme in server (check bREST_base::version()). Ignoring package.json's version
	_api                             = null;                                         //Instance of B_REST_API
	_appLangs                        = [];                                           //Supported langs for -this- app. CORE_LANGS could contain ["fr","en","es"] while here we could only specify ["fr","en"]
	_routeDefs                       = {};                                           //Map of routeName => B_REST_App_RouteDef_base der instances
	_routes_current_info             = null;                                         //Der instance of B_REST_App_RouteInfo_base, giving info about possibly matching B_REST_App_RouteDef_base, path vars, QSA, etc
	_routes_current_travelDir        = B_REST_App_base.ROUTES_TRAVEL_DIR_UNRELATED;  //One of ROUTES_TRAVEL_DIR_x. Updated in _routes_updateCurrentTravelDirection(), ex to help with UI horizontal transitions between screens
	_boot_status                     = B_REST_App_base.BOOT_STATUS_IDLE;             //One of BOOT_STATUS_x. Tells if boot_await() has yet to start / loading or done
	_boot_isUnbooting                = false;                                        //Flag to know when we ask for a reboot or reload app to a new URL, to help stop async stuff from happening + skip _abstract_beforeUnload_generalHook()
	_boot_promiseInfo                = null;                                         //As {promise, resolve, reject} For boot_await()
	_boot_sudoHash                   = null;                                         //If we got a ?_sh=as98df0a8s in the URL, to try to quick connect or for other purposes
	_boot_langMismatch_notYetUsed    = null;                                         //If route's lang doesn't match local storage lang on boot. Check boot_await() docs
	_sharedLists                     = null;                                         //Map of sharedListTag -> B_REST_App_SharedList instances
	_locale_lang                     = null;                                         //Current lang in _appLangs
	_t_dicts                         = {                                             //Map of {core:{<lang>:<jsonFile>}, custom:{<lang>:<jsonFile>}}. NOTE: Initialize here because we need this for throwing err msgs as early as possible
										core: {
											fr: locMsgs_core_fr,
											en: locMsgs_core_en,
											es: locMsgs_core_es,
										},
										custom: {},
									};
	_t_cache                         = {core:{}, custom:{}};                         //For the current lang, map of {core, custom}, where each is also a map of locPath -> msg (before details tags are replaced), or false if not found. NOTE: Initialize here because we need this for throwing err msgs as early as possible
	_heartbeatInfo                   = null;                                         //For contacting server from time to time, ex to get notifs. Either NULL or {freq, authOnly, setInterval_ptr:null, accessTokenSnapshot:null, isOngoing:false}
	_user_isLogoutOrSudoXCallLoading = false;                                        //So frameworks know that they should get kicked off the current route components they are, otherwise components reactivity could make unexpected things with half the old & new user type data, perms etc. Ex in Vue implementation, is used in BrAppBooter.vue. Not to be confused w user_isSudoing, indicating that we have a parent user to come back to
	_user_isSudoing                  = false;                                        //If the current access token indicates that the user is sudoing as someone else and can revert to himself later. Not to be confused w the user_isLogoutOrSudoXCallLoading spinner
	_user                            = null;                                         //B_REST_Model instance of the user
	_perms_tags                      = {};                                           //Check server's RouteParser_base::perms_x() docs
	_perms_extraData                 = {};                                           //Check server's RouteParser_base::perms_x() docs
	_gdpr                            = null;                                         //Info about which cookies etc we accept (for communication w server & analytics, not for local storage)
	_isMaintenance                   = false;
	//Flags
	_defaultPagingSize    = 10;     //For B_REST_Model_Load_SearchOptions
	_debug_locPaths       = false;  //Flag to help testing - at the beginning of all successful translations, if we should display the translation's loc path. Check _t_x(). Displays like "<some.locPath>: Some translation"
	_debug_fieldNamePaths = false;  //Flag to help testing - for B_REST_FieldDescriptor_X etc, needing to display labels for model fields. Displays like "Some field [user.name]"
	_debug_responses      = false;  //Flag to help testing - dumps all received B_REST_Response. WARNING: Requires flag console_warn=true
	_debug_beforeReload   = false;  //Flag to help testing - sometimes it's hard to debug after an API call when page reloads right after; this adds a debugger JIT
	_debug_ignorePerms    = false;  //Flag to help testing - for now, just for routes_hasPerms()
	_debug_authReloadErrs = false;  //Flag to help testing - for tweakResponse_async_handler(), to put a breakpoint and stop app from reloading
	//Other cache related stuff
	_boot_cache                                   = false;  //If we want to cache the boot API call (will cause probs if modelDefs or sharedLists change on the server)
	_models_toLabelCache_cache                    = {};     //Map of <modelName-pkTag>:{promiseOrLabel,resolver}. Check models_toLabelCache_x() docs
	_models_toLabelCache_pendingAPICall_cacheKeys = [];     //Arr of <modelName-pkTag>. Check models_toLabelCache_x() docs
	
	
	
	//NOTE: For route defs, define using _routes_define() during der constructor
	constructor(options={})
	{
		B_REST_App_base._instance_assertNotCreatedYet();
		B_REST_App_base._instance = this;
		
		//Setup global err handlers ASAP, in case anything happens
		this._setupGlobalErrorListeners();
		
		/*
		Check @/bREST/core/implementations/vue/nodeScripts/vue.config.cjs for what this is about + possible props
		WARNING: For now, this only makes sense if used w Vue, so w B_REST_VueApp_base
		*/
		{
			const businessConfigObj = B_REST_BUSINESS_CONFIG;
			B_REST_Utils.console_info("Reading business config", businessConfigObj);
			this._businessConfig = new Proxy(businessConfigObj,
			{
				get(obj,propName)
				{
					if (propName in obj) { return obj[propName]; }
					
					/*
					Prob w Vue:
						In B_REST_VueApp_base::_constructor_mountVue_install_$bREST(), we call Vue.use({install()}),
							and it'll call vue.runtime.esm.js::observe() + isPlainObject(), and also businessConfig.toJSON() when we want to {{ businessConfig }},
							so we must add props wrappers or everything will break
						WARNING: For now, this only makes sense if used w Vue, so w B_REST_VueApp_base
					*/
						switch (propName)
						{
							case "__v_skip":  return true;
							case "__v_isRef": return false;
							case "toJSON":    return B_REST_Utils.json_encode(this._businessConfig,true);
							default:          if (typeof(propName)==="symbol") {return null;}
						}
					
					B_REST_Utils.throwEx(`Business config prop "${propName}" not found`);
				},
			});
		}
		
		options = B_REST_Utils.object_hasValidStruct_assert(options, {
			api:      {accept:[Object], default:{}},    //Check below for docs
			flags:    {accept:[Object], required:true}, //Check below for docs
			appLangs: {accept:[Object], required:true}, //Map of <lang> -> <jsonFile> of traductions
		}, "App");
			const options_flags = B_REST_Utils.object_hasValidStruct_assert(options.flags, {
				heartbeat_freq_secs:   {accept:[Number,false], required:true},                //For _heartbeatInfo - in secs, or false, if we don't want to have that. Used ex to check for new notifs, etc
				heartbeat_authOnly:    {accept:[Boolean],      required:true, default:true},  //For _heartbeatInfo - if heart beat should only happen if user is logged in
				defaultPagingSize:     {accept:[Number],       required:true, default:10},    //For B_REST_Model_Load_SearchOptions
				debug_locPaths:        {accept:[Boolean],      required:true, default:false}, //Flag to help testing - at the beginning of all successful translations, if we should display the translation's loc path. Check _t_x(). Displays like "<some.locPath>: Some translation"
				debug_fieldNamePaths:  {accept:[Boolean],      required:true, default:false}, //Flag to help testing - for B_REST_FieldDescriptor_X etc, needing to display labels for model fields. Displays like "Some field [user.name]"
				debug_responses:       {accept:[Boolean],      required:true, default:false}, //Flag to help testing - dumps all received B_REST_Response. WARNING: Requires flag console_warn=true
				debug_beforeReload:    {accept:[Boolean],      required:true, default:false}, //Flag to help testing - sometimes it's hard to debug after an API call when page reloads right after; this adds a debugger JIT
				debug_ignorePerms:     {accept:[Boolean],      required:true, default:false}, //To allow going to any route & have all perms request indicate we can, to help testing
				debug_authReloadErrs:  {accept:[Boolean],      required:true, default:false}, //For tweakResponse_async_handler(), to put a breakpoint and stop app from reloading
				boot_cache:            {accept:[Boolean],      required:true, default:false}, //If we want to cache the boot API call (will cause probs if modelDefs or sharedLists change on the server)
				onErr_breakpoint:      {accept:[Boolean],      required:true, default:false}, //For B_REST_Utils::throwEx()
			}, "Flags");
			const options_api = B_REST_Utils.object_hasValidStruct_assert(options.api, {
				mockCalls_async_handler: {accept:[Function], required:false}, //Ex async(request) { ... },
				mockCalls_enabled:       {accept:[Boolean],  default:false},
				wifi_checkInterval_secs: {accept:[Number],   default:B_REST_App_base.WIFI_CHECK_INTERVAL_SECS},
				rejectUnsuccessfulCalls: {accept:[Boolean],  default:B_REST_App_base.REJECT_UNSUCCESSFUL_CALLS},
				timeout_msecs:           {accept:[Number],   required:false},
				maxParallelConnections:  {accept:[Number],   default:B_REST_App_base.MAX_PARALLEL_CONNECTIONS},
			}, "API");
		
		this._defaultPagingSize    = options_flags.defaultPagingSize;
		this._debug_locPaths       = options_flags.debug_locPaths;
		this._debug_fieldNamePaths = options_flags.debug_fieldNamePaths;
		this._debug_responses      = options_flags.debug_responses;
		this._debug_beforeReload   = options_flags.debug_beforeReload;
		this._debug_ignorePerms    = options_flags.debug_ignorePerms;
		this._debug_authReloadErrs = options_flags.debug_authReloadErrs;
		this._boot_cache           = options_flags.boot_cache;
		
		//Stuff for B_REST_Utils errs & logs management
		{
			B_REST_Utils.flags_onErr_breakpoint      = options_flags.onErr_breakpoint;
			B_REST_Utils.flags_onErr_showNativeAlert = this._businessConfig.debug_errors ? B_REST_Utils.FLAGS_ON_ERR_SHOW_NATIVE_ALERT_ONCE : B_REST_Utils.FLAGS_ON_ERR_SHOW_NATIVE_ALERT_NEVER;
			B_REST_Utils.flags_onErr_overlayDomTree  = this._businessConfig.debug_errors;
			B_REST_Utils.flags_console_todo          = false;
			B_REST_Utils.flags_console_info          = false;
			B_REST_Utils.flags_console_warn          = this._businessConfig.debug_errors;
			B_REST_Utils.flags_console_error         = true;
		}
		
		//Create custom translation dicts for all supported langs
		{
			for (const loop_appLang in options.appLangs)
			{
				this._appLangs.push(loop_appLang);
				
				const loop_appLangDict = options.appLangs[loop_appLang];
				this._t_dicts.custom[loop_appLang] = loop_appLangDict;
			}
		}
		
		//Check if we'll need heartbeat to query server from time to time, ex to get new info like notifs etc
		if (options_flags.heartbeat_freq_secs)
		{
			this._heartbeatInfo = {
				freq:                options_flags.heartbeat_freq_secs,
				authOnly:            options_flags.heartbeat_authOnly,
				setInterval_ptr:     null,
				accessTokenSnapshot: null,
				isOngoing:           false,
			};
		}
		
		//Prep a promise but don't fulfill it now - we'll do so in boot_await()
		{
			this._boot_promiseInfo = {promise:null, resolve:null, reject:null};
			
			this._boot_promiseInfo.promise = new Promise((s,f) =>
			{
				this._boot_promiseInfo.resolve = s;
				this._boot_promiseInfo.reject  = f;
			});
		}
		
		/*
		Check if frontend version is OK, otherwise clear LS
		Further backend version checks will be done in all call's tweakResponse_async_handler()
		*/
		{
			this._v_frontend = `${version_custom}@${version_core}`;
			
			const ls_version = B_REST_Utils.localStorage_get(B_REST_App_base.LS_KEY_V_FRONTEND,/*throwIfNull*/false);
			if (ls_version)
			{
				if (ls_version!==this._v_frontend) { this.appData_clear(/*isWrongVersion*/true); } //NOTE: Will restore some keys like locale stuff
			}
			else { B_REST_Utils.localStorage_set(B_REST_App_base.LS_KEY_V_FRONTEND,this._v_frontend,/*isPersistent*/false); }
		}
		
		//Setup API (must do before we call locale_lang setter for the 1st time
		{
			this._api = new B_REST_API({
				baseURL:                 this._businessConfig.apiBaseUrl,
				mockCalls_async_handler: options_api.mockCalls_async_handler,
				mockCalls_enabled:       options_api.mockCalls_enabled,
				wifi_checkInterval_secs: options_api.wifi_checkInterval_secs,
				rejectUnsuccessfulCalls: options_api.rejectUnsuccessfulCalls,
				timeout_msecs:           options_api.timeout_msecs,
				maxParallelConnections:  options_api.maxParallelConnections,
				wifi_onChange_handler: (has) =>
				{
					B_REST_Utils.console_todo([
						`Change icon + display toaster if it flips to false`
					]);
				},
				log_handler: (msg, isError, details=null) =>
				{
					if (isError)
					{
						if (details instanceof B_REST_Response && details.isBadAuth) { return; }
						
						B_REST_Utils.console_error(`B_REST_Custom::log_handler(): ${msg}`, details);
						
						this.notifs_error_locPath("app.log_handler.error");
					}
					else
					{
						B_REST_Utils.console_info(`B_REST_Custom::log_handler(): ${msg}`, details);
					}
				},
				/*
				Before certain calls, could add QSA and such, ex for analytics
				For now, we'll only do that for boot & login
				*/
				tweakRequest_async_handler: async(request) =>
				{
					const shouldAddQSA = B_REST_App_base.TWEAK_REQUEST_ASYNC_HANDLER_ADD_QSA_ALWAYS || B_REST_App_base.TWEAK_REQUEST_ASYNC_HANDLER_ADD_QSA_CORE_CALLS.includes(request.path_raw);
					
					if (shouldAddQSA)
					{
						const routeInfoJSON = B_REST_Utils.json_encode(this._routes_current_info.toObj());
						
						request.qsa[B_REST_App_base.REQUEST_QSA_FRONTEND_ROUTE_INFO] = routeInfoJSON;
						request.qsa[B_REST_App_base.REQUEST_QSA_REFERRER_URL]        = B_REST_Utils.url_referrer||"";
					}
				},
				/*
				Happens before call resolves / rejects
				IMPORTANT:
					When we're trying to reboot, to prevent async stuff from happening for the wrong user etc, we'll force the async handler to never end
				*/
				tweakResponse_async_handler: async(response) =>
				{
					return new Promise(async(s,f) =>
					{
						if (this._debug_responses) { B_REST_Utils.console_warn(`Debugging response`,response); }
						
						//If we're trying to reboot, stop here wo resolving nor rejecting, to prevent async stuff from happening when it shouldn't. Check _boot_setUnbooting() docs
						if (this._boot_isUnbooting) { return; }
						
						let isInterceptingPostBootMaintenance = false;
						if (response.isMaintenance)
						{
							this._isMaintenance = true;
							if (this.boot_isBooted) { isInterceptingPostBootMaintenance=true; }
							else                    { s(); return;                            } //NOTE: We do it that way, so we can handle correctly in boot_await()
						}
						
						//If response was in err
						if (!response.isSuccess)
						{
							//Maybe we should clear all and reboot
							{
								let mustReboot_reason = false; //False or B_REST_App_base.REBOOT_REASONS_x
								
								if (isInterceptingPostBootMaintenance)
								{
									B_REST_Utils.console_warn("Server under maintenance");
									mustReboot_reason = B_REST_App_base.REBOOT_REASONS_MAINTENANCE;
								}
								//Bad / expired token
								else if (response.errorType_isAuth_tokenNotFound || response.errorType_isAuth_tokenExpired)
								{
									B_REST_Utils.console_warn("Access token was deleted / not found, therefore rebooting");
									mustReboot_reason = B_REST_App_base.REBOOT_REASONS_TOKEN;
								}
								/*
								Other auth probs, ex errorType_isAuth_disabledUser or errorType_isAuth_resettingPwd
								Only do so if we're not in login type calls, as we'd like to show it to the user instead of kicking it out
								NOTE:
									We mustn't reboot if we try to sudo in/out, so we stay connected as the current user
									If it happens during a heartbeat call, we must reboot
								*/
								else if (response.isBadAuth)
								{
									const handlesBadAuthCallsAnotherWay = B_REST_App_base._BAD_AUTH_CALLS_TO_IGNORE.includes(response.request?.path_raw);
									
									if (!handlesBadAuthCallsAnotherWay)
									{
										B_REST_Utils.console_warn("User disabled / resetting pwd, therefore rebooting");
										mustReboot_reason = B_REST_App_base.REBOOT_REASONS_USER;
									}
								}
								else if (response.isBadPerms)
								{
									B_REST_Utils.console_warn("Trying to go somewhere we shouldn't, therefore rebooting. Possible that a HttpUtils::die_res_perms() should just be HttpUtils::die_request_body(), so it's less evil");
									mustReboot_reason = B_REST_App_base.REBOOT_REASONS_PERMS;
								}
								
								if (mustReboot_reason)
								{
									if (this._debug_authReloadErrs || this._debug_beforeReload || B_REST_Utils.flags_onErr_breakpoint)
									{
										if (!confirm(`API call ret #${response.code} (prolly a auth prob). App data will be cleared and app will reboot. OK=reboot, CANCEL=don't reboot and inspect err w dev tools`))
										{
											debugger;
											return;
										}
									}
									
									this.reboot(mustReboot_reason);
									return; //IMPORTANT: Don't resolve nor reject, to prevent async stuff from happening when it shouldn't
								}
							}
							
							//All other cases - we don't have to do anything more w it here; let usage decide. IMPORTANT: Don't ret as string, otherwise we can't do nothing w the response
							f(response); return;
						}
						
						/*
						If we're in a heartbeat call and the access token state changed from what we expected to have,
						then drop the call before we run into _calls_interceptCoreProps() & _abstract_calls_tweakResponse_hook(), to avoid hell
						*/
						if (response.request?.path_raw===B_REST_App_base.API_CALL_HEARTBEAT)
						{
							const snapshotAccessToken = this._heartbeatInfo.accessTokenSnapshot;
							const apiAccessToken      = this._api.accessToken_public;
							
							if (snapshotAccessToken!==apiAccessToken || (response.coreProps_has_accessToken && snapshotAccessToken!==response.coreProps_accessToken.public))
							{
								B_REST_Utils.console_warn(`Ignoring an heartbeat call response, since access token don't match`, {snapshotAccessToken,apiAccessToken,responseAccessTokenInfo:response.coreProps_accessToken});
								s(); return;
							}
						}
						
						try
						{
							/*
							Before doing anything, check if backend app version matches. If set and not OK:
								-On boot call, clear session, but no need to reboot app
								-On all next calls (while navigating), means we might not have the right modelDefs anymore etc, so we should clear session and reboot app
							WARNING:
								-If we cache boot calls and version is now diff on server, we'll only know when we do another call later
							NOTE:
								-Frontend version check are done in constructor before boot
							*/
							{
								const v_backend = response.v_backend;
								
								const ls_version = B_REST_Utils.localStorage_get(B_REST_App_base.LS_KEY_V_BACKEND,/*throwIfNull*/false);
								if (ls_version)
								{
									if (ls_version!==v_backend)
									{
										this.appData_clear(/*isWrongVersion*/true); //NOTE: Will restore some keys like locale stuff & frontend version
										
										//If it's a call that happens later while using the app (not at boot)
										if (this.boot_isBooted)
										{
											this.reboot(B_REST_App_base.REBOOT_REASONS_VERSION);
											return; //IMPORTANT: Don't resolve nor reject, to prevent async stuff from happening when it shouldn't
										}
									}
								}
								else { B_REST_Utils.localStorage_set(B_REST_App_base.LS_KEY_V_BACKEND,v_backend,/*isPersistent*/false); }
							}
							
							//Check for changes to modelDefs, sharedLists, etc, but WARNING; only if already booted, otherwise will be taken care of in boot_await()
							const corePropsThenDirectives = this.boot_isBooted ? await this._calls_interceptCoreProps(response) : {}; //Could throw
								/*
								IMPORTANT:
									If we realized that we have to reboot the app ex because we changed user, the above will have fired _calls_handleRedirection(),
									so we should also stop before we call _abstract_calls_tweakResponse_hook()
								*/
								if (this._boot_isUnbooting) { return; }
							
							//Let custom code tweak response. NOTE: When booting, we defer _calls_interceptCoreProps() & _abstract_calls_afterCall_general_handler() to later; maybe we should for this too...
							await this._abstract_calls_tweakResponse_hook(response,corePropsThenDirectives); //Could throw
							
							//If we're trying to reboot, stop here wo resolving nor rejecting, to prevent async stuff from happening when it shouldn't. Check _boot_setUnbooting() docs
							if (this._boot_isUnbooting) { return; }
							
							/*
							If we must redirect; here, happens only if app is already booted, otherwise redirectInfo handled in boot_await()
							Check server's RouteParser_base::_output_json_injectCore_redirect_x() docs
							IMPORTANT:
								-Contrary to in boot_await(), should happen before resolving here
							*/
							if (corePropsThenDirectives?.redirectInfo)
							{
								this._calls_handleRedirection(corePropsThenDirectives.redirectInfo);
								
								//Re-check again
								if (this._boot_isUnbooting) { return; }
							}
							
							//Resolve
							s();
						}
						catch (e) { f(e); }
					});
				},
				afterCall_general_handler: (response) =>
				{
					this._calls_checkDebugProps(response);
					
					//Be consistent w the fact that the above tweakResponse_async_handler() won't call  right away _calls_interceptCoreProps() when we're booting
					if (this.boot_isBooted) { this._abstract_calls_afterCall_general_handler(response); }
				},
			});
			
			//Check if we can get info about GDPR cookies etc, access token & perms (not requiring to get model defs yet)
			{
				this._gdpr_checkLoadFromLS();
				this._accessToken_checkLoadFromLS();
				this._perms_checkLoadFromLS();
			}
			
			//Check if we've got something like ?_sh=89sf908ad in URL, and quickly hide it
			{
				this._boot_sudoHash = B_REST_Utils.url_current_getQSA(B_REST_App_base.FRONTEND_QSA_SUDO_HASH);
				
				if (this._boot_sudoHash) { B_REST_Utils.url_current_removeQSA(B_REST_App_base.FRONTEND_QSA_SUDO_HASH); }
			}
		}
		
		//Hook here to define routeDefs
		this._abstract_routes_defineRoutes();
		
		//Figure out current route's info - note that we could be on a URL that doesn't make sense / should yield a 404
		{
			const fullPath = B_REST_Utils.url_current_getAbsPath(/*wQSA*/true);
			
			this._routes_current_info = this.routes_getRouteInfo_fromPath(fullPath);
		}
		
		/*
		Figure out which lang to use and detect local storage vs URL lang mismatches
		NOTE:
			-Here this._user is still not allocated yet
			-Upon receiving boot call's response, we could have to change the lang against user / received link etc. Check boot_await() docs
		*/
		{
			const currentRoute_lang = this._routes_current_info.lang;                                                          //NULL if URL is the same in multiple langs
			const ls_lang           = B_REST_Utils.localStorage_get(B_REST_App_base.LS_KEY_LOCALE_LANG,/*throwIfNull*/false);  //Could be NULL
			
			//If we start clean with no local storage (so not logged / first time going in app), then just use whatever lang we get from the URL
			if (!ls_lang)
			{
				this._boot_langMismatch_notYetUsed = false;
				this.locale_lang                   = currentRoute_lang || this._businessConfig.defaultLang; //Use setter, to update LS & recalc loc cache
			}
			//If route's lang matches what we had so far, or we've landed on a lang-neutral URL (all langs having the same URL), then all is OK
			else if (currentRoute_lang===ls_lang || !currentRoute_lang)
			{
				this._boot_langMismatch_notYetUsed = false;
				this.locale_lang                   = ls_lang;  //Use setter, to update LS & recalc loc cache
			}
			//Else we have a mismatch between LS & route langs. Check boot_await() docs
			else
			{
				this._boot_langMismatch_notYetUsed = true;     //NOTE: For now, we don't do anything w that var, but could help future algo
				this.locale_lang                   = ls_lang;  //Use setter, to update LS & recalc loc cache
				
				B_REST_Utils.console_warn(`Detected that route "${this._routes_current_info.fullPath}"' of lang "${currentRoute_lang}" doesn't match LS's one "${ls_lang}". Will likely redirect`);
			}
		}
		
		//Then der has to do its constructor, then later call boot_await() to get the modelDefs etc
	}
		static throwEx(msg, details=null) { B_REST_Utils.throwEx(`B_REST_App_base: ${msg}`,details); }
		       throwEx(msg, details=null) { B_REST_Utils.throwEx(`B_REST_App_base: ${msg}`,details); }
		static _throwEx_abstractMissing() { B_REST_App_base.throwEx(`Must override base method`); }
		       _throwEx_abstractMissing() { B_REST_App_base.throwEx(`Must override base method`); }
		_setupGlobalErrorListeners()
		{
			//NOTE: Even if we "catch" the errs w these listeners, they'll still show up in the console https://developer.mozilla.org/en-US/docs/Web/API/Window/error_event
			
			window.addEventListener("error", (e) => //ErrorEvent instance
			{
				const final_e = e.error ? e.error : e; //Gets the exception behind the ErrorEvent (ex a B_REST_Error)
				this._setupGlobalErrorListeners_parseOne(e, final_e, "general");
			});
			window.addEventListener("unhandledrejection", (e) => //PromiseRejectionEvent instance
			{
				if (this._isMaintenance) { return; }     //This is because for some reason, even if we try catch boot_await(), it says "uncaught (in promise)"
				const final_e = e.reason ? e.reason : e; //Gets the Error derived instance (ex a B_REST_Error), or param passed to the promise's reject(), if any
				this._setupGlobalErrorListeners_parseOne(e, final_e, "Promise(?)");
			});
		}
			_setupGlobalErrorListeners_parseOne(raw_e, final_e, type)
			{
				this.notifs_error_generic();
				
				//Assume that if we went in B_REST_Utils::throwEx(), it means we fired all req handlers and such, so this handler should have nothing more to do
				if (B_REST_Error.isBRESTError(final_e)) { return; }
				
				//WARNING: The following must never throw, or we'll get an infinite loop
				B_REST_Utils.console_error(`Got a globally unhandled ${type} err: ${final_e.toString()}`, raw_e);
			}
	
	
	
	
	
	
	//DERIVED SINGLETON RELATED & HELPERS
		static get instance()
		{
			return B_REST_App_base._instance || this.throwEx(`Global instance of B_REST_App_base derived not yet set up`);
		}
			static _instance_assertNotCreatedYet() { if(!!B_REST_App_base._instance){B_REST_App_base.throwEx("Singleton derived already instantiated");} }
		/*
		Instantiate a derived instance and sets it as the singleton. We must then call boot_await(), to fetch model defs, logged user etc
		Ex:
			<DerivedClass>.instance_init({...});
			await <DerivedClass>.instance.boot_await();
		*/
		static instance_init(options={})
		{
			const DerivedClass = B_REST_Utils.class_ptr_fromBaseStaticFunc(this);
			new DerivedClass(options); //Throws if we already have an instance. NOTE: Constructor takes care of doing B_REST_App_base._instance=this
			
			//Make it accessible in the console too
			window[B_REST_App_base.WINDOW_SINGLETON_PROP_NAME] = B_REST_App_base._instance;
			
			//NOTE: Wrap in try catch here, otherwise we'll be stuck doing it in App.js in all projs
			try       { B_REST_App_base._instance.boot_await(); }
			catch (e) { B_REST_Utils.console_error(e);          }
		}
	
	
	
	//MISC ACCESSORS
		get businessConfig()       { return this._businessConfig;       }
		get isMaintenance()        { return this._isMaintenance;        }
		get v_frontend()           { return this._v_frontend;           }
		get defaultPagingSize()    { return this._defaultPagingSize;    }
		get debug_locPaths()       { return this._debug_locPaths;       }
		get debug_fieldNamePaths() { return this._debug_fieldNamePaths; }
		get debug_responses()      { return this._debug_responses;      }
		get debug_beforeReload()   { return this._debug_beforeReload;   }
		get debug_ignorePerms()    { return this._debug_ignorePerms;    }
		get debug_authReloadErrs() { return this._debug_authReloadErrs; }
		//Prevents having to import B_REST_Utils everywhere
		get utils() { return B_REST_Utils; }
	
	
	
	//BOOT RELATED
		get boot_isBooting()   { return this._boot_status!==B_REST_App_base.BOOT_STATUS_DONE; }
		get boot_isBooted()    { return this._boot_status===B_REST_App_base.BOOT_STATUS_DONE; }
		get boot_isUnbooting() { return this._boot_isUnbooting;                               }
		get boot_sudoHash()    { return this._boot_sudoHash;                                  }
		/*
		For when we must reboot app after user login/out, lang change etc, or when we want to go to a new URL while reloading the app
		Allows to:
			-Leave wo triggering _abstract_beforeUnload_generalHook()
			-Stop ongoing API calls from returning, to prevent async code from continuing, so they hang in call's tweakResponse_async_handler()
		*/
		_boot_setUnbooting()
		{
			this._boot_isUnbooting = true;
				//WARNING: Ex in Vue, if we ever get probs when unbooting or setting user to NULL, could add an abstract func to call B_REST_VueApp_base::_vue_destroy()
			
			if (this._debug_beforeReload) { debugger; }
		}
		/*
		Fetchs model defs, shared lists, logged user etc
		Before this, constructor has taken care of loading what we could from local storage (lang, timeZone, accessToken)
		Call this at least once to start booting, and then multiple places can await this, to either wait until booted, or die when it failed
		Might abruptly end in a reboot, ex if LS' server version doesn't match with server's current version
		NOTE:
			-Additionnal QSA will get added via tweakRequest_async_handler(), like for analytics, referrer URL, current URL, etc
			-Lang mismatch:
				If we're logged w a FR user but landed on an EN page and accept-language header would try to ret the /boot/call in EN,
				server's RouterParser_base will start script in EN, but then we'll do RouterParser_base::_setUser() which will flip to FR,
				since if we run a call that would send emails etc it should stay in that user's lang + help when we sudo in/out
				Therefore, when already logged in, a lang mismatch will automatically be discarded by the server, then by frontend's user_createFromObj()
				We also have logic in _routes_beforeNavigationChange()
				If we wouldn't do all this, then we'd get probs with frontend's loc (components) matching the new lang, and server's loc (models, enums) still pointing to the initial lang
		*/
		async boot_await()
		{
			//If already booting / done / failed
			if (this._boot_status!==B_REST_App_base.BOOT_STATUS_IDLE) { return this._boot_promiseInfo.promise; }
			
			this._boot_status = B_REST_App_base.BOOT_STATUS_LOADING;
			
			let response = null; //Instance of B_REST_Response
			
			try
			{
				await this._abstract_boot_await_framework_beforeAPICall();
				
				//IMPORTANT: Check method's docs about lang mismatch
				
				const request = new this.GET(B_REST_App_base.API_CALL_BOOT); //IMPORTANT: Check tweakRequest_async_handler(), tweakResponse_async_handler() & _calls_interceptCoreProps() in constructor
				request.needsAccessToken = false; //IMPORTANT: Must be false, otherwise won't work for public cases
				
				/*
				Check if we've got sudo info to auto log user at the same time, but don't do so if it's for the resetPwd form
				NOTE: Don't check to ignore when user isn't enabled because we might not have the user yet so it'll never work
				*/
				if (this._boot_sudoHash)
				{
					if (this.routes_current_def?.type_isPublicResetPwd) { B_REST_Utils.console_warn(`Ignoring received hash because we're on the resetPwd page`); }
					else
					{
						request.qsa_add(B_REST_App_base.REQUEST_QSA_SUDO_HASH,this._boot_sudoHash);
					}
				}
				
				const requestOptions = {};
				
				//Check if we want to cache/reuse a previous boot call's response
				if (this._boot_cache) { requestOptions.cache={key:B_REST_App_base.LS_KEY_BOOT_CALL_CACHE}; }
				
				response = await this.call(request, requestOptions); //Might throw
				
				//Here we'll parse modelDefs, sharedList etc
				const corePropsThenDirectives = await this._calls_interceptCoreProps(response);
				
				//If response didn't contain a user, then check to fetch from LS or create a new one
				if (!this._user)
				{
					if (this.user_ls_has()) { this.user_ls_retrieve();                       }
					else                    { this.user_createFromObj({}, /*updateLS*/true); }
				}
				
				/*
				NOTE:
					Should be the same timing as in cases after boot (at least after _calls_interceptCoreProps()). Check tweakResponse_async_handler() & afterCall_general_handler() code.
					For now, we place it between user's LS code above and _abstract_boot_await(), in case we want to do something that should affect the user that is already been prepared.
					If not right, then we could add 1 more hook and reposition them...
				*/
				this._abstract_calls_afterCall_general_handler(response);
				
				//Finalize booting custom code
				await this._abstract_boot_await_framework_afterAPICall(response, corePropsThenDirectives);
				await this._abstract_boot_await(response, corePropsThenDirectives);
				
				/*
				Now that all is fully loaded and we know we don't need to go to another route, setup a general before unload event
				Will allow usage to call for ex user_ls_update() JIT if req
				Skipped if rebooting
				*/
				window.addEventListener('beforeunload', (event) =>
				{
					//If we should skip the check, to prevent hell. Check _boot_setUnbooting() docs
					if (this._boot_isUnbooting) { return true; }
					
					const trueOrConfirmMsg = this._abstract_beforeUnload_generalHook();
					
					if (trueOrConfirmMsg===true) { return; }
					else if (B_REST_Utils.string_is(trueOrConfirmMsg))
					{
						event.preventDefault();
						event.returnValue = trueOrConfirmMsg;
						return trueOrConfirmMsg;
					}
					else { this.throwEx(`_abstract_beforeUnload_generalHook() must ret true or confirm msg`); }
				});
				
				this._boot_status = B_REST_App_base.BOOT_STATUS_DONE;
				B_REST_Utils.console_info("Booted");
				this._boot_promiseInfo.resolve();
				
				/*
				Check to setup an interval to poll server from time to time, ex to get new notifs
				Since we want to handle maybe only having this when we have a user, and that it could be complicated to figure out when user went from NEW to EXISTING,
				to KISS we'll just let the interval go on wo stopping it, no matter if we login/out or sudoIn/Out, and we'll just check when we receive a response,
				if the access token's val stayed the same before and after calls
				NOTE:
					-First execution is not done at time of creation of the interval
					-Relevant code for handling notifs etc happens later in _calls_interceptCoreProps()
				WARNING:
					-For now, we never stop the interval once started, no matter what (read the above for why)
				*/
				if (this._heartbeatInfo)
				{
					this._heartbeatInfo.setInterval_ptr = setInterval(async() =>
					{
						//Ignore if we're already waiting for a response, or unbooting
						if (this._heartbeatInfo.isOngoing || this._boot_isUnbooting) { return; }
						//Ignore if we've got a pub user and we only want to do that when we're auth
						if (this._heartbeatInfo.authOnly && this.user_isPublic) { return; }
						
						//Note current state
						this._heartbeatInfo.isOngoing          = true;
						this._heartbeatInfo.accessTokenSnapshot = this._api.accessToken_public;  //Can be NULL
						
						const request = new this.POST(B_REST_App_base.API_CALL_HEARTBEAT); //IMPORTANT: Check tweakRequest_async_handler() & tweakResponse_async_handler() in constructor
						request.needsAccessToken = false; //IMPORTANT: Must be false, otherwise won't work for public cases
						try
						{
							await this.call(request); //NOTE: If successful, likely to reboot and hang before finishing awaiting, to prevent hell
						}
						catch (response)
						{
							B_REST_Utils.console_error(`Caught err while doing heartbeat`,response); //WARNING: Could cause prob to switch to throwEx() - check code below
						}
						this._heartbeatInfo.isOngoing = false;
					}, this._heartbeatInfo.freq*1000);
				}
				
				/*
				We might have wanted to start somewhere but server ret that we should go away (by reloading app or continuing)
				IMPORTANT:
					-Check server's RouteParser_base::_output_json_injectCore_redirect_x() docs
					-Don't move this before resolving, as if redirect mentions we mustn't reload the app, then app must be ready in order for route change to work
				*/
				if (corePropsThenDirectives?.redirectInfo) { this._calls_handleRedirection(corePropsThenDirectives.redirectInfo); }
				
				//Check if we had a reboot reason to explain to the user
				{
					const rebootReason = B_REST_Utils.localStorage_get(B_REST_App_base.LS_KEY_REBOOT_REASON, /*throwIfNull*/false);
					
					if (rebootReason)
					{
						B_REST_Utils.localStorage_remove(B_REST_App_base.LS_KEY_REBOOT_REASON);
						
						//Just don't warn for the obvious
						if (rebootReason!==B_REST_App_base.REBOOT_REASONS_LOGOUT_MANUAL)
						{
							const locPath = `app.booter.rebootReasons.${rebootReason}`;
							this.notifs_tmp({msg:this.t_custom_alt(locPath,locPath), color:"info"});
						}
					}
				}
			}
			catch (e)
			{
				//NOTE: For some reason, even if we try catch boot_await(), it says "uncaught (in promise)" when we reject here, and it does so even if we wrap this in a setTimeout...
				
				if (this._isMaintenance) { this.appData_clear(/*isWrongVersion*/true); }
				else
				{
					const errorMsg = e instanceof B_REST_Response ? e.errorMsg : (e.message||e);
					this.utils.console_error("boot_await failed", errorMsg);
				}
				this._boot_promiseInfo.reject();
				if (!this._isMaintenance && response) { this._abstract_calls_afterCall_general_handler(response); }
			}
			
			return this._boot_promiseInfo.promise;
		}
			//Stuff to define ex in B_REST_VueApp_base
			async _abstract_boot_await_framework_beforeAPICall() { this._throwEx_abstractMissing(); }
			async _abstract_boot_await_framework_afterAPICall()  { this._throwEx_abstractMissing(); }
			//Stuff to do in boot in our framework's final derived class, after user etc are all set up. Can specify extra directives to do next like redirect
			async _abstract_boot_await(response, corePropsThenDirectives) { this._throwEx_abstractMissing(); }
			//Must ret true or a confirm msg. IMPORTANT: Can't ret a promise to wait for async stuff to complete
			_abstract_beforeUnload_generalHook() { this._throwEx_abstractMissing(); }
			//Allow configuring global hooks on models, like for B_REST_Descriptor::validation_custom_fastFuncs() & B_REST_Descriptor::validation_custom_asyncFuncs()
			_commonDefs_setupDescriptorHooks()
			{
				//Setup things for Model_User, like unicity & email verification
				{
					const userDescriptor = B_REST_Descriptor.commonDefs_get("User");
					
					//Do API call to figure out if userName, recoveryEmail and such are unique
					userDescriptor.validation_custom_asyncFuncs_add(async(model) =>
					{
						const fieldsWithUnicityRules = ["userName", "recoveryEmail"];
						const fieldsToValidate       = {};
						
						//Only care about fields that are non-empty w unsaved changes
						for (const loop_fieldName of fieldsWithUnicityRules)
						{
							const loop_field = model.select(loop_fieldName);
							
							if (loop_field.unsavedChanges_has && !loop_field.isEmpty) { fieldsToValidate[loop_fieldName] = loop_field.val; }
						}
						
						if (B_REST_Utils.object_isEmpty(fieldsToValidate)) { return; }
						
						const request = new this.POST(B_REST_App_base.API_CALL_CHECK_UNICITY);
						request.needsAccessToken = false; //IMPORTANT: Must be false, otherwise won't work for public cases / users creating their account
						request.data             = {
							idUser: model.pk, //Could be NULL
							fieldsToValidate,
						};
						
						try
						{
							const response = await this.call(request);
							
							for (const loop_fieldName of fieldsWithUnicityRules)
							{
								if (!B_REST_Utils.object_hasPropName(response.data,loop_fieldName)) { continue; }
								const loop_isUnique = response.data[loop_fieldName]===true;
								
								model.select(loop_fieldName).validation_custom_errorList.async_if(!loop_isUnique, "notUnique");
							}
						}
						catch (response) { this.throwEx(`Got err validating unicity`,response); }
					});
				}
				
				this._abstract_commonDefs_setupDescriptorHooks();
			}
				//Allow configuring global hooks on models, like for B_REST_Descriptor::validation_custom_fastFuncs() & B_REST_Descriptor::validation_custom_asyncFuncs()
				_abstract_commonDefs_setupDescriptorHooks() { this._throwEx_abstractMissing(); }
		/*
		To use ex to:
			-After user login, now that we know correct lang to refetch modelDefs etc
			-After changing lang, so we stay on the same route but in the new lang
		Alias to routes_reload(), except that we can specify a reason, ex because user got kicked out (one of REBOOT_REASONS_x)
			then we can after reboot explain what happened (at the end of boot_await())
		IMPORTANT:
			-Won't trigger _abstract_beforeUnload_generalHook()
			-Ongoing API calls won't end and get stuck in call's tweakResponse_async_handler()
			-Must use that when we must login/out, sudo in/out or get kicked out, to prevent async stuff from resuming code that would affect the wrong user
			-Check _boot_setUnbooting() docs
			-If we define custom reboot reasons, we'll have to define their translation in custom loc, under path "app.booter.rebootReasons"
		NOTE: B_REST_App_base::reboot() & Vue implementation's BrErrorPageBase::leave() has similar code to go back to login page and then redirect to prev page
		*/
		reboot(reason=null)
		{
			if (reason)
			{
				//Set this aside for next page reload in boot_await()
				B_REST_Utils.localStorage_set(B_REST_App_base.LS_KEY_REBOOT_REASON, reason, /*isPersistent*/false);
				
				let redirectToLoginOrLandpageEtc = false;
				let redirectTo403                = false;
				let keepPrevRoute                = null;
				let appData_clear                = false;
				switch (reason)
				{
					case B_REST_App_base.REBOOT_REASONS_MAINTENANCE:      appData_clear=true; keepPrevRoute=true;  redirectToLoginOrLandpageEtc=true; break;
					case B_REST_App_base.REBOOT_REASONS_VERSION:          appData_clear=true; keepPrevRoute=true;  redirectToLoginOrLandpageEtc=true; break;
					case B_REST_App_base.REBOOT_REASONS_TOKEN:            appData_clear=true; keepPrevRoute=true;  redirectToLoginOrLandpageEtc=true; break;
					case B_REST_App_base.REBOOT_REASONS_USER:             appData_clear=true; keepPrevRoute=true;  redirectToLoginOrLandpageEtc=true; break;
					case B_REST_App_base.REBOOT_REASONS_PERMS:            appData_clear=true; keepPrevRoute=false; redirectToLoginOrLandpageEtc=!this.routeDefs_403; redirectTo403=!!this.routeDefs_403; break;
					case B_REST_App_base.REBOOT_REASONS_LOGOUT_MANUAL:    appData_clear=true; keepPrevRoute=false; redirectToLoginOrLandpageEtc=true; break;
					case B_REST_App_base.REBOOT_REASONS_LOGOUT_DIRECTIVE: appData_clear=true; keepPrevRoute=false; redirectToLoginOrLandpageEtc=true; break;
					default: break; //Ignore
						/*
						For REBOOT_REASONS_PERMS:
							NOTE:
								We have in frontend's B_REST_App_base REBOOT_REASONS_PERMS, routes_go_x_403() & _routes_beforeNavigationChange(), and RouteParser_base::output_json_injectCore_redirect_403() in server
								If in server we HttpUtils::die_res_perms() for an API call we're not the right user type etc, we'll correctly catch ex when go in a list or existing form, but not for new form.
									Ex:
										/someConfigMenu/    -> dies while doing "GET /someStuff/"
										/someConfigMenu/123 -> dies while doing "GET /someStuff/123"
										/someConfigMenu/*   -> doesn't die because no API call is made
									To make sure we die, must handle manually here in _abstract_routes_hasPerms()
							IMPORTANT: Don't keep prev route, otherwise we'll get an infinite loop
							WARNING: Logic must be consistent between B_REST_App_base::reboot(), B_REST_App_base::_routes_beforeNavigationChange() & BrErrorPage403::leave-track-intended-route
						*/
				}
				
				if (appData_clear) { this.appData_clear(/*isWrongVersion*/true); } //NOTE: Will restore some keys like locale stuff & frontend version
				
				if (redirectToLoginOrLandpageEtc)
				{
					B_REST_Utils.console_todo([
						`Not 100% sure we should redirect to X page instead of doing routes_reload() when we get such an err, but it's for when a PUBLIC page does an API call that reqs to be auth, and so far it happened that we've only tested it while being logged in, and then we put to prod and people complain it reboots forever because they weren't logged yet`,
						`If it makes sense to go else where, should maybe keep current qsa`,
					]);
					
					const pathVars  = {};
					const qsa       = {};
					const reloadApp = true;
					
					//NOTE: B_REST_App_base::reboot() & Vue implementation's BrErrorPageBase::leave() has similar code to go back to login page and then redirect to prev page
					if (keepPrevRoute) { qsa[B_REST_App_base.ROUTES_QSA_INTENDED_FULL_PATH]=this.routes_current_path; }
					
					if      (this.routeDefs_login)    { this.routes_go_login(   pathVars,qsa,reloadApp); }
					else if (this.routeDefs_landpage) { this.routes_go_landpage(pathVars,qsa,reloadApp); }
					else                              { this.routes_go_root(             qsa,reloadApp); }
					
					return;
				}
				else if (redirectTo403)
				{
					const qsa = {};
					qsa[B_REST_App_base.ROUTES_QSA_INTENDED_FULL_PATH] = this.routes_current_path;
					this.routes_go_403(/*pathVars*/{}, qsa, /*reloadApp*/true);
					return;
				}
			}
			
			this.routes_reload();
		}
	
	
	
	//USER RELATED
		/*
		Cookies (and such) related obj
		Can be NULL
		WARNING: Don't edit obj from outside, otherwise LS won't be notified of its changes. Use in RO here and for changes, use gdpr_apply()
		*/
		get gdpr() { return this._gdpr; }
		//Use this to update cookies related stuff
		gdpr_apply(gdprObjOrNULL)
		{
			this._gdpr_updateLS(gdprObjOrNULL);
			
			this._gdpr = gdprObjOrNULL;
			
			//Changing GDPR stuff should maybe trigger something
		}
			//Saves to LS wo doing nothing more
			_gdpr_updateLS(gdprObjOrNULL)
			{
				if (gdprObjOrNULL)
				{
					const gdprJSON = B_REST_Utils.json_encode(gdprObjOrNULL);
					B_REST_Utils.localStorage_set(B_REST_App_base.LS_KEY_GDPR, gdprJSON, /*isPersistent*/true);
				}
				else { B_REST_Utils.localStorage_remove(B_REST_App_base.LS_KEY_GDPR); }	
			}
			_gdpr_checkLoadFromLS()
			{
				const gdprJSON = B_REST_Utils.localStorage_get(B_REST_App_base.LS_KEY_GDPR,/*throwIfNull*/false);
				
				if (gdprJSON) { this._gdpr=B_REST_Utils.json_decode(gdprJSON); }
			}
		
		//B_REST_API doesn't check if call rets access tokens, so it must be done in user code
		_accessToken_set(accessToken_public, accessToken_private, isSudoing)
		{
			const accessTokenJSON = B_REST_Utils.json_encode({public:accessToken_public, private:accessToken_private, isSudoing});
			B_REST_Utils.localStorage_set(B_REST_App_base.LS_KEY_ACCESS_TOKEN, accessTokenJSON, /*isPersistent*/false);
			
			this._api.accessToken_set(accessToken_public, accessToken_private);
			this._user_isSudoing = isSudoing;
		}
			_accessToken_checkLoadFromLS()
			{
				const accessTokenJSON = B_REST_Utils.localStorage_get(B_REST_App_base.LS_KEY_ACCESS_TOKEN,/*throwIfNull*/false);
				
				if (accessTokenJSON)
				{
					const accessTokenInfo = B_REST_Utils.json_decode(accessTokenJSON);
					this._accessToken_set(accessTokenInfo.public, accessTokenInfo.private, accessTokenInfo.isSudoing);
				}
			}
		_accessToken_clear()
		{
			B_REST_Utils.localStorage_remove(B_REST_App_base.LS_KEY_ACCESS_TOKEN);
			
			this._api.accessToken_clear();
			this._user_isSudoing = false;
		}
		
		get user()                              { return this._user;                                                       }
		get user_has_avoidUsing()               { return !!this._user;                                                     } //WARNING: Avoid using; Even if a new user, we always instantiate one, so likely to be always NULL except before boot ends
		get user_pk()                           { return   this._user?.pk??null;                                           }
		get user_isNew()                        { return !(this._user?.pk??null);                                          } //NOTE: We could do !this.user_pk but slower
		get user_isPublic()                     { return this._user && !this._api.accessToken_isValid;                     }
		get user_isAuth()                       { return this._user && this._api.accessToken_isValid;                      }
		get user_isLogoutOrSudoXCallLoading()   { return this._user_isLogoutOrSudoXCallLoading;                            } //Spinner - check docs
		get user_isSudoing()                    { return this._user && this._user_isSudoing;                               } //If we have a parent user to come back to - check docs
		get user_type()                         { return this._user?.select("type")?.val ?? null;                          }
		get user_isEnabled()                    { return  this._user?.select("isEnabled")?.val ?? false;                   }
		get user_isDisabled()                   { return !this._user?.select("isEnabled")?.val ?? true;                    }
		get user_displayName()                  { return this._abstract_user_displayName;                                  }
		    user_type_is(type)                  { return this.user_type===type;                                            }
		    user_type_isNot(type)               { return this.user_type!==type;                                            }
		get user_extraData_ui()                 { return this._user?.extraData_ui  ?? null;                                }
		get user_extraData_api()                { return this._user?.extraData_api ?? null;                                }
			/*
			Must handle case when we don't have a user yet. Could ex ret only first name, last name, etc
			Ex:
				return this._user?.select_firstNonEmptyVal("firstName+lastName|firstName|lastName|userName|recoveryEmail") || "???";
			*/
			get _abstract_user_displayName() { return this._throwEx_abstractMissing(); }
		
		
		/*
		Check server's RouteParser_base::perms_x() docs
		WARNING: Don't edit obj from outside, otherwise LS won't be notified of its changes + frameworks reactivity too. Use in RO here and for changes, use this
		*/
		perms_change(tags, extraData)
		{
			B_REST_Utils.object_assert(tags);
			B_REST_Utils.object_assert(extraData);
			
			this._perms_tags      = tags;
			this._perms_extraData = extraData;
			
			this._perms_updateLS();
		}
			_perms_updateLS()
			{
				const permsJSON = B_REST_Utils.json_encode({tags:this._perms_tags, extraData:this._perms_extraData});
				B_REST_Utils.localStorage_set(B_REST_App_base.LS_KEY_PERMS, permsJSON, /*isPersistent*/false);
			}
			_perms_checkLoadFromLS()
			{
				const permsJSON = B_REST_Utils.localStorage_get(B_REST_App_base.LS_KEY_PERMS,/*throwIfNull*/false);
				
				if (permsJSON)
				{
					const decoded = B_REST_Utils.json_decode(permsJSON);
					this._perms_tags      = decoded.tags;
					this._perms_extraData = decoded.extraData;
				}
				else
				{
					this._perms_tags      = {};
					this._perms_extraData = {};
				}
			}
		perms_tags_get(tagName)
		{
			if (!B_REST_Utils.object_hasPropName(this._perms_tags,tagName)) { this.throwEx(`Unknown perm tag "${tagName}"`); }
			return this._perms_tags[tagName];
		}
		perms_extraData_get(key)
		{
			if (!B_REST_Utils.object_hasPropName(this._perms_extraData,key)) { this.throwEx(`Unknown perms extraData key "${key}". Maybe it's not being returned for that user type ?`); }
			return this._perms_extraData[key];
		}
		perms_can(tagNameOrComplexPermName, ifComplexPerm_details=null)
		{
			if (B_REST_Utils.object_hasPropName(this._perms_tags,tagNameOrComplexPermName)) { return this._perms_tags[tagNameOrComplexPermName]; }
			
			//Else, must be a custom case
			const can = this._abstract_perms_evalComplexPerm(tagNameOrComplexPermName, ifComplexPerm_details);
			if (can!==true && can!==false) { this.throwEx(`Expected complex perm name "${tagNameOrComplexPermName}" to ret bool in _abstract_perms_evalComplexPerm()`); }
			return can;
		}
		perms_can_assert(tagNameOrComplexPermName, ifComplexPerm_details=null)
		{
			if (!this.perms_can(tagNameOrComplexPermName,ifComplexPerm_details)) { this.throwEx(`Can't do this - no "${tagNameOrComplexPermName}" perms`); }
		}
			//Must ret bool, using perms_x_get(). Check server's RouteParser_base::perms_x() docs
			_abstract_perms_evalComplexPerm(complexPermName, details=null) { this._throwEx_abstractMissing(); }
		
		/*
		Re-create user model from passed fields data
		Optionnally puts the new data to LS
		Fires a custom hook to further manipulate the user, before it's actually "set" in the class
			Could also use that custom hook to update perms via perms_change()
		NOTE:
			-Will trigger to change locale (lang & timezone), however it won't cause refetching of modelDefs from server in new lang.
				In theory we should avoid that prob by forcing to reload app when user changes, so _calls_interceptCoreProps() receive both modelDefs & user for the same lang
		*/	
		user_createFromObj(userObj, updateLS)
		{
			/*
			Ex if we were user #123 and now we receive #456, then we should create a new instance (and access token would be diff)
			But if we had no PK yet and now we've got one, we -could- reuse the instance,
			it's just that for now it's hard to tell what happened:
				1) was creating own profile and now we've got a PK
				2) was not logged and now just logged
			So, for now, we'll never reuse the current user instance
			*/
			const reuseCurrentUserModel = false;
			
			const user = reuseCurrentUserModel ? this._user : B_REST_Model.commonDefs_make("User");
			
			user.fromObj(userObj, /*skipIllegalChanges*/false);
			this._abstract_user_createFromObj(user, userObj);
			
			//Check to change locale. NOTE: For now, we don't care about Model_User::timeZone. Check its docs. We have funcs in B_REST_Utils that prefers using actual browser's time zone anyways
			{
				const user_lang = user.select("lang").val;
				if (user_lang) { this.locale_lang=user_lang; }
			}
			
			this._user = user;
			
			if (updateLS) { this.user_ls_update(); }
		}
			//NOTE: newUser is an instance of B_REST_Model, not yet put back to app's _user prop
			_abstract_user_createFromObj(userModel, userObj) { this._throwEx_abstractMissing(); }
		//Checks if we have a "copy" of the user's data in local storage
		user_ls_has() { return B_REST_Utils.localStorage_has(B_REST_App_base.LS_KEY_USER); }
		//Saves a snapshot of the user's data to local storage
		user_ls_update()
		{
			if (!this._user) { this.throwEx(`Can't update user to LS; user not set`); }
			
			const userObj  = this._user.toObj({onlyWithUnsavedChanges:false,forAPICall:false}); //IMPORTANT: forAPICall must be false so we ret _extraData_ui_
			const userJSON = B_REST_Utils.json_encode(userObj);
			B_REST_Utils.localStorage_set(B_REST_App_base.LS_KEY_USER, userJSON, /*isPersistent*/false);
		}
		//Recreate user from local storage. Throws if not in LS
		user_ls_retrieve()
		{
			const userJSON = B_REST_Utils.localStorage_get(B_REST_App_base.LS_KEY_USER,/*throwIfNull*/true);
			const userObj  = B_REST_Utils.json_decode(userJSON);
			this.user_createFromObj(userObj, /*updateLS*/false);
		}
		
		/*
		NOTE:
			-Preserves some keys like frontend version and locale, but we can't preserve LS_KEY_GDPR since struct could change over time
			-Doesn't reload app; use reboot() after if needed
		IMPORTANT:
			-Don't change code so this calls user_createFromObj(), as it can be fired before boot_await()
		*/
		appData_clear(isWrongVersion)
		{
			this._user           = null; //WARNING: Ex in Vue, if we ever get probs when unbooting or setting user to NULL, could add an abstract func to call B_REST_VueApp_base::_vue_destroy()
			this._user_isSudoing = false;
			this._perms          = null;
			B_REST_Utils.localStorage_clear(/*clearPersistentOnes*/false);
			
			if (this._api) { this._api.accessToken_clear(); }
			
			//Some stuff should remain anyways
			{
				B_REST_Utils.localStorage_set(B_REST_App_base.LS_KEY_V_FRONTEND,  this._v_frontend,  /*isPersistent*/false);
				B_REST_Utils.localStorage_set(B_REST_App_base.LS_KEY_LOCALE_LANG, this._locale_lang, /*isPersistent*/false);
				
				if (!isWrongVersion) { this._gdpr_updateLS(this._gdpr); }
			}
		}
		
		
		
	//CORE CALLS
		/*
		For the password, send it raw, so if using a B_REST_FieldDescriptor_DB, don't do pwdVal_toFrontendHash()
		Extra data for things like where we came from, redirect link (via REQUEST_QSA_REDIRECT_FULL_PATH), etc
		Check server's RouteParser_Core::_coreCalls_login() docs
		App will then redirect by itself after, via call's tweakResponse_async_handler()
		Always resolve w the B_REST_Response instance, no matter:
			-Successful login (check response.isSuccess; however if it triggers a reboot, func will never end)
			-Bad credentials, disabled user or user in pwd resetting status (check ex if response.isBadAuth, response.errorType_isAuth_disabledUser, ...)
				-> Code in tweakResponse_async_handler() will kickout any user in those status, except if we're in login / sudo
			-Other probs (check if !response.isSuccess)
		IMPORTANT:
			For now, a successful login should cause the app to reboot and this func to never end
		NOTE:
			Check boot_await() for how referrer URL, current URL etc are passed
		*/
		async login(userName, pwd_raw, extraData=null)
		{
			if (!userName || !pwd_raw) { return B_REST_Response.from_err(B_REST_Response.CODES_BAD_REQUEST,`Empty info`); }
			
			const request = new this.POST(B_REST_App_base.API_CALL_LOGIN); //IMPORTANT: Check tweakRequest_async_handler() & tweakResponse_async_handler() in constructor
			request.needsAccessToken = B_REST_Request_base.NEEDS_ACCESS_TOKEN_DONT;
			request.data = {
				userName,
				pwd_frontendHash: this.pwd_raw_toFrontendHash(pwd_raw),
			};
			
			return this._login_sudoX(request,extraData); //Rets response, whether or not it succeeded
		}
		//Variant where we do like sudoIn(), except we don't need to have an access token in advance
		async login_wSudoHash(sudoHash, extraData=null)
		{
			if (!sudoHash) { return B_REST_Response.from_err(B_REST_Response.CODES_BAD_REQUEST,`Empty info`); }
			
			const request = new this.POST(B_REST_App_base.API_CALL_LOGIN); //IMPORTANT: Check tweakRequest_async_handler() & tweakResponse_async_handler() in constructor
			request.needsAccessToken = false; //IMPORTANT: Must be false, otherwise won't work for public cases
			request.data = {sudoHash, extraData};
			
			return this._login_sudoX(request,extraData); //Rets response, whether or not it succeeded
		}
			/*
			Common code for login(), sudoIn() & sudoOut()
			Changing of user, token, lang etc will happen via tweakResponse_async_handler() parsing of core props injections
			*/
			async _login_sudoX(request, extraData=null)
			{
				const shouldSetLoadingState = this._user && !this._user.isNew; //So if we're in the login page, this shouldn't apply
				
				if (shouldSetLoadingState) { this._user_isLogoutOrSudoXCallLoading=true; }
				
				if (extraData!==null)
				{
					const redirect_fullPath = extraData[B_REST_App_base.REQUEST_QSA_REDIRECT_FULL_PATH];
					if (redirect_fullPath)
					{
						request.qsa_add(B_REST_App_base.REQUEST_QSA_REDIRECT_FULL_PATH, redirect_fullPath);
						delete extraData[B_REST_App_base.REQUEST_QSA_REDIRECT_FULL_PATH];
					}
					
					if (!B_REST_Utils.object_isEmpty(extraData)) { request.data_set("extraData",extraData); }
				}
				
				let ret_response = null;
				try
				{
					this._abstract_login_sudoX_beforeCall(request,extraData);
					ret_response = await this.call(request); //NOTE: If successful, likely to reboot and hang before finishing awaiting, to prevent hell
				}
				catch (response) { ret_response=response; }
				
				if (shouldSetLoadingState) { this._user_isLogoutOrSudoXCallLoading=false; }
				
				return ret_response;
			}
				/*
				Use this to do tweaks on the B_REST_Request instance. Possible extra data will be already under request.data.extraData.
				Note that for an "after call", we can use _abstract_calls_afterCall_general_handler()
				*/
				async _abstract_login_sudoX_beforeCall(request,extraData=null) { this._throwEx_abstractMissing(); }
		/*
		To verify a user's email
		Can be done by someone else, so not against own access token
		Will send an email w a code in it like "123 456", then we must call verifyRecoveryEmail_confirm() w that code
		Like other core calls, always resolve, no matter what
		*/
		async verifyRecoveryEmail_sendCode(recoveryEmail) { return this._verifyX_sendCode("recoveryEmail",recoveryEmail); }
		/*
		Use after calling verifyRecoveryEmail_sendEmail()
		If it's for a user that already exist, it'll update its verified status in DB, otherwise if it's not created yet it'll do nothing
		Can be done by someone else, so not against own access token
		Rets whether the code matches the address or not (by ret a 200 or a failure)
		*/
		async verifyRecoveryEmail_confirm(recoveryEmail,verificationCode) { return this._verifyX_confirm("recoveryEmail",recoveryEmail,verificationCode); }
		//NOTE: Not yet used, but same logic as for recoveryEmail, except it's to confirm via SMS
		async verifyPhone_sendCode(phone) { return this._verifyX_sendCode("phone",phone); }
		//NOTE: Not yet used, but same logic as for recoveryEmail, except it's to confirm via SMS
		async verifyPhone_confirm(phone,verificationCode) { return this._verifyX_confirm("phone",phone,verificationCode); }
			//To use w verifyRecoveryEmail_x() & verifyPhone_x()
			async _verifyX_sendCode(fieldName, fieldVal)
			{
				if (!fieldVal) { return B_REST_Response.from_err(B_REST_Response.CODES_BAD_REQUEST,`Empty info`); }
				
				const request = new this.POST(B_REST_App_base.API_CALL_VERIFY_X_SEND_CODE, {fieldName}); //IMPORTANT: Check tweakRequest_async_handler() & tweakResponse_async_handler() in constructor
				request.needsAccessToken = false; //IMPORTANT: Must be false, otherwise won't work for public cases / users creating their account
				request.data             = {fieldVal};  //Either the val of the recoveryEmail or phone
				
				try
				{
					return await this.call(request);
				}
				catch (response) { return response; }
			}
			async _verifyX_confirm(fieldName, fieldVal, verificationCode)
			{
				if (!fieldVal || !verificationCode) { return B_REST_Response.from_err(B_REST_Response.CODES_BAD_REQUEST,`Empty info`); }
				
				const request = new this.POST(B_REST_App_base.API_CALL_VERIFY_X_CONFIRM, {fieldName}); //IMPORTANT: Check tweakRequest_async_handler() & tweakResponse_async_handler() in constructor
				request.needsAccessToken = false; //IMPORTANT: Must be false, otherwise won't work for public cases / users creating their account
				request.data             = {fieldVal, verificationCode};  //Either the val of the recoveryEmail or phone
				
				try
				{
					return await this.call(request);
				}
				catch (response) { return response; }
			}
		/*
		Check server's RouteParser_Core::_coreCalls_resetPwd_sendEmail() docs
		Always resolve w the B_REST_Response instance, no matter:
			-Successful email sending (check response.isSuccess)
			-Unknown recovery email (check if response.isBadAuth)
			-Other probs, ex can't because user is disabled (check if !response.isSuccess)
		*/
		async resetPwd_sendEmail(recoveryEmail)
		{
			if (!recoveryEmail) { return B_REST_Response.from_err(B_REST_Response.CODES_BAD_REQUEST,`Empty info`); }
			
			const request = new this.POST(B_REST_App_base.API_CALL_RESET_PWD_SEND_EMAIL); //IMPORTANT: Check tweakRequest_async_handler() & tweakResponse_async_handler() in constructor
			request.needsAccessToken = false; //IMPORTANT: Must be false, otherwise won't work for public cases
			request.data = {recoveryEmail};
			
			try
			{
				return await this.call(request);
			}
			catch (response) { return response; }
		}
		/*
		Check server's RouteParser_Core::_coreCalls_resetPwd_save() docs
		Always resolve w the B_REST_Response instance, no matter:
			-Successful updating (check response.isSuccess)
			-Unknown idUser / hash (check if response.isBadAuth)
			-Other probs, ex can't because user is disabled (check if !response.isSuccess)
		*/
		async resetPwd_save(hash, newPwd_raw)
		{
			if (!hash || !newPwd_raw) { return B_REST_Response.from_err(B_REST_Response.CODES_BAD_REQUEST,`Empty info`); }
			
			const request = new this.POST(B_REST_App_base.API_CALL_RESET_PWD_SAVE);
			request.needsAccessToken = false; //IMPORTANT: Must be false, otherwise won't work for public cases
			request.data = {
				hash,
				newPwd_frontendHash: this.pwd_raw_toFrontendHash(newPwd_raw),
			};
			
			try
			{
				return await this.call(request);
			}
			catch (response) { return response; }
		}
		/*
		Extra data like where to go next (via REQUEST_QSA_REDIRECT_FULL_PATH)
		Check server's RouteParser_Core::_coreCalls_logout() docs
		App will then redirect by itself after, via call's tweakResponse_async_handler()
		Rets if it could logout, or throw if we weren't even logged in
		NOTE:
			Check boot_await() for how referrer URL, current URL etc are passed
		*/
		async logout(extraData={})
		{
			if (!this.user_isAuth) { return B_REST_Response.from_err(B_REST_Response.CODES_BAD_REQUEST,`Not logged`); }
			
			const request = new this.POST(B_REST_App_base.API_CALL_LOGOUT); //IMPORTANT: Check tweakRequest_async_handler() & tweakResponse_async_handler() in constructor
			request.data = {extraData};
			
			this._user_isLogoutOrSudoXCallLoading = true;
			
			let could = null;
			try
			{
				await this.call(request); //NOTE: If successful, likely to reboot and hang before finishing awaiting, to prevent hell
				could=true;
			}
			catch (response) { could=false; }
			
			this._user_isLogoutOrSudoXCallLoading = false;
			
			return could;
		}
		/*
		Expects a sudo hash giving info on which user to become next
		Check login() docs, as both work and ret the same way
		Check server's RouteParser_Core::_coreCalls_sudoIn() docs
		The user_isSudoing flag will flip to true
		*/
		async sudoIn(sudoHash, extraData=null)
		{
			B_REST_Utils.console_todo([`When doing sudoIn especially, should have an overlay over all the app, otherwise we click a btn and nothing happens for 300ms. When we fix, check usage for things like quickConnect_isLoading=true`]);
			
			if (!this.user_isAuth) { return B_REST_Response.from_err(B_REST_Response.CODES_BAD_REQUEST,`Must be logged to switch to another user`); }
			if (!sudoHash)         { return B_REST_Response.from_err(B_REST_Response.CODES_BAD_REQUEST,`Empty info`);                               }
			
			const request = new this.POST(B_REST_App_base.API_CALL_SUDO_IN); //IMPORTANT: Check tweakRequest_async_handler() & tweakResponse_async_handler() in constructor
			request.data = {sudoHash, extraData};
			
			return this._login_sudoX(request,extraData); //Rets response, whether or not it succeeded
		}
		/*
		The user_isSudoing flag will likely flip to false, unless we nested multiple sudo
		Check login() docs, as both work and ret the same way
		Check server's RouteParser_Core::_coreCalls_sudoOut() docs
		App will indicate where to go after
		App will then redirect by itself after, via call's tweakResponse_async_handler()
		*/
		async sudoOut(extraData=null)
		{
			if (!this._user_isSudoing) { return B_REST_Response.from_err(B_REST_Response.CODES_BAD_REQUEST,`Not sudoing`); }
			
			const request = new this.POST(B_REST_App_base.API_CALL_SUDO_OUT); //IMPORTANT: Check tweakRequest_async_handler() & tweakResponse_async_handler() in constructor
			
			return this._login_sudoX(request,extraData); //Rets response, whether or not it succeeded
		}
		
		/*
		Helpers to post files to /custom/data_secure/<businessTag>/_pendingAPIUploads/ and returning its hash / arr of hashes in that folder (via a B_REST_Response instance)
		Has 2 endpoints for model field files & custom files:
			/core/modelFiles/pendingUploads/{modelName}/{modelField}
			/core/customFiles/pendingUploads/{customType}
		Usage ex:
			const response = await pendingUploads_modelFiles_single("Lead","cv", new B_REST_DOMFilePtr(...));
			const response = await pendingUploads_modelFiles_single("Lead","cv", B_REST_DOMFilePtr.from_fileInput(<input>));
			const response = await pendingUploads_modelFiles_multiple("Brand","docs", [new B_REST_DOMFilePtr(...),new B_REST_DOMFilePtr(...),new B_REST_DOMFilePtr(...)]);
			const response = await pendingUploads_modelFiles_multiple("Brand","docs", B_REST_DOMFilePtr.from_fileInput(<input multiple>))
			const response = await pendingUploads_customFiles_single("some-ex", ...)
			const response = await pendingUploads_customFiles_single("some-ex", ...)
			const response = await pendingUploads_customFiles_multiple("some-multiple-ex", ...)
			const response = await pendingUploads_customFiles_multiple("some-multiple-ex", ...)
		NOTES:
			-Sets shortName to something like "pendingUploads-model-<modelName>-<fieldName>" or "pendingUploads-custom-<customType>"
			-If we need more control over the request, maybe it's better to implement on our own (ex adding QSA...)
		*/
			async pendingUploads_modelFiles_single(   modelName,fieldName, file,  uploadProgressCallback=null,downloadProgressCallback=null) { return this._pendingUploads_x("model", {modelName,fieldName}, false,file,  uploadProgressCallback,downloadProgressCallback); }
			async pendingUploads_modelFiles_multiple( modelName,fieldName, files, uploadProgressCallback=null,downloadProgressCallback=null) { return this._pendingUploads_x("model", {modelName,fieldName}, true, files, uploadProgressCallback,downloadProgressCallback); }
			async pendingUploads_customFiles_single(  customType,          file,  uploadProgressCallback=null,downloadProgressCallback=null) { return this._pendingUploads_x("custom",{customType},          false,file,  uploadProgressCallback,downloadProgressCallback); }
			async pendingUploads_customFiles_multiple(customType,          files, uploadProgressCallback=null,downloadProgressCallback=null) { return this._pendingUploads_x("custom",{customType},          true, files, uploadProgressCallback,downloadProgressCallback); }
				async _pendingUploads_x(type,path_vars, isMultiple,fileOrFiles, uploadProgressCallback=null,downloadProgressCallback=null)
				{
					if (B_REST_Utils.array_is(fileOrFiles)!==isMultiple) { this.throwEx(`Expected isMultiple vs received doesn't fit, for pendingUploads_x()`,{expected_isMultiple:isMultiple,fileOrFiles}); }
					
					const request = new this.POST_Multipart(B_REST_App_base.API_CALL_MODEL_FILES_PENDING_UPLOADS, path_vars);
					request.shortName        = type==="model" ? `pendingUploads-model-${path_vars.modelName}-${path_vars.fieldName}` : `pendingUploads-custom-${path_vars.customType}`;
					request.needsAccessToken = false; //IMPORTANT: Must be false, otherwise won't work for public cases
					
					const methodName    = isMultiple ? "data_add_file_multiple"                           : "data_add_file_single";
					const postFieldName = isMultiple ? B_REST_App_base.PENDING_UPLOADS_FIELDNAME_MULTIPLE : B_REST_App_base.PENDING_UPLOADS_FIELDNAME_SINGLE;
					await request[methodName](postFieldName, fileOrFiles); //Will crash if params aren't OK
					
					const requestOptions = {};
						if (uploadProgressCallback)   { requestOptions.uploadProgressCallback   = uploadProgressCallback;   }
						if (downloadProgressCallback) { requestOptions.downloadProgressCallback = downloadProgressCallback; }
					
					return this.call(request, requestOptions);
				}
	
	
	
	//API RELATED
		get api() { return this._api; }
		//Check B_REST_API funcs docs
			get GET()             { return this._api.GET;             }
			get GET_File()        { return this._api.GET_File;        } //Check call_download() & call_download_inlineNewWindow() below
			get POST()            { return this._api.POST;            }
			get POST_File()       { return this._api.POST_File;       }
			get POST_Multipart()  { return this._api.POST_Multipart;  }
			get PUT()             { return this._api.PUT;             }
			get PUT_Multipart()   { return this._api.PUT_Multipart;   }
			get PATCH()           { return this._api.PATCH;           }
			get PATCH_Multipart() { return this._api.PATCH_Multipart; }
			get DELETE()          { return this._api.DELETE;          }
			async call(request, requestOptions=null)                                            { return this._api.call(request,requestOptions);                                     }
			async call_getObjectUrl(request)                                                    { return this._api.call_getObjectUrl(request);                                       }
			async call_download(request, baseNameWExt=null, domContainer=null)                  { return this._api.call_download(request,baseNameWExt,domContainer);                 }
			async call_download_inlineNewWindow(request, baseNameWExtOrWindowTitle=null)        { return this._api.call_download_inlineNewWindow(request,baseNameWExtOrWindowTitle); }
			static async call_external(method, url, data=null, headers={}, resolveErrors=false) { return this._api.call_external(method,url,data,headers,resolveErrors);             }
		/*
		We do [raw pwd] > [frontend to backend encryption] > [db encryption]
		So API calls never show raw pwd
		Ex "pwd" -> "<intermediate>6a4b49f07b599056dc1dc08d2c68afd8c2dd49af1c346fb51c7d8d56576354a6e2608e8e161151cb92886e4fbde45ac9e4c1a69bbcf0566cce108abc0200e60a"
		For more info, check server's CryptoUtils
		NOTE:
			There's also a new native API, but the prob is that it's async: https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest#basic_example
		*/
		pwd_raw_toFrontendHash(pwd) { return B_REST_Utils.pwd_raw_toFrontendHash(pwd,this._businessConfig.crypto_pwd_frontend_salt); }
		/*
		Check for changes to modelDefs, sharedLists, etc
		Rets an obj of directives to do next, like {redirectInfo}
		WARNING:
			For now, will break if we receive model defs twice in same boot, because it'll complain they're already defined
		*/
		async _calls_interceptCoreProps(response)
		{
			const thenDirectives = {}; //Might contain something like {redirectInfo}
			
			if (response.coreProps_hasAny)
			{
				//Check server's RouteParser_base::_output_json_injectCore_redirect_x() docs
				let redirect_reloadApp = false;
				if (response.coreProps_has_redirect)
				{
					thenDirectives.redirectInfo = response.coreProps_redirect;
					redirect_reloadApp = response.coreProps_redirect.reloadApp || false;
				}
				
				if (response.coreProps_appDataClear)
				{
					this.appData_clear(/*isWrongVersion*/false);
					
					if (response.coreProps_has_rebootReasonTag) { B_REST_Utils.localStorage_set(B_REST_App_base.LS_KEY_REBOOT_REASON,response.coreProps_rebootReasonTag,/*isPersistent*/false); } //Check to display something after a reboot to the user. WARNING: Must be done after appData_clear(), otherwise it'll get cleared
					
					//Check if we wanted to reload the app AND also clear user. If so, we should instead not bother w user and just reboot earlier to prevent hell w frameworks reactivity
					if (redirect_reloadApp && response.coreProps_accessToken===false && response.coreProps_user===false)
					{
						B_REST_Utils.console_warn(`Terminating the app quickly because we lost our token and we have to reload`);
						this._calls_handleRedirection(response.coreProps_redirect);
						return;
					}
				}
				else if (response.coreProps_has_rebootReasonTag) { B_REST_Utils.localStorage_set(B_REST_App_base.LS_KEY_REBOOT_REASON,response.coreProps_rebootReasonTag,/*isPersistent*/false); } //Check to display something after a reboot to the user
				
				if (response.coreProps_has_modelDefs)
				{
					B_REST_Descriptor.commonDefs_fetch_fromServerBootResponse(response.coreProps_modelDefs);
					
					//Allow configuring global hooks on models, like for B_REST_Descriptor::validation_custom_fastFuncs() & B_REST_Descriptor::validation_custom_asyncFuncs()
					if (this.boot_isBooting) { this._commonDefs_setupDescriptorHooks(); }
				}
				
				//WARNING: Check warning in method docs
				if (response.coreProps_has_sharedListsDefs) { this._sharedLists_defineFromAPICall(response.coreProps_sharedListsDefs); }
				
				if (response.coreProps_has_sharedListsItems) { this._sharedLists_updateFromAPICall(response.coreProps_sharedListsItems); }
				
				if (response.coreProps_has_timeZone) { B_REST_Utils.dt_server_timeZone=response.coreProps_timeZone; }
				
				if      (response.coreProps_accessToken)         { this._accessToken_set(response.coreProps_accessToken.public, response.coreProps_accessToken.private, response.coreProps_accessToken.isSudoing); }
				else if (response.coreProps_accessToken===false) { this._accessToken_clear(); }
				
				if (response.coreProps_user)              { this.user_createFromObj(response.coreProps_user, /*updateLS*/true); }
				else if (response.coreProps_user===false) { this.user_createFromObj({},                      /*updateLS*/true); }
				
				if (response.coreProps_perms) { this.perms_change(response.coreProps_perms.tags,response.coreProps_perms.extraData); }
				
				if (response.coreProps_has_notifs) { this._notifs_appendFromAPICall(response.coreProps_notifs); }
				
				if (response.coreProps_has_customData) { await this._abstract_calls_interceptCoreProps_customDataNode(response.coreProps_customData,thenDirectives); }
				
				//Debug stuff
				{
					if (response.coreProps_has_lastQueryLogs)  { B_REST_Utils.console_info("Got query logs from server API call",          response.coreProps_lastQueryLogs);  }
					if (response.coreProps_has_todos)          { B_REST_Utils.console_info("Got todos from server API call",               response.coreProps_todos);          }
					if (response.coreProps_has_scriptDuration) { B_REST_Utils.console_info("Got script duration info from server API call",response.coreProps_scriptDuration); }
				}
			}
			
			return thenDirectives;
		}
			//Happens before API call ends
			async _abstract_calls_interceptCoreProps_customDataNode(customProps,thenDirectives) { this._throwEx_abstractMissing(); }
		//Checks if an API call ret some debug stuff like err msgs and dumps
		_calls_checkDebugProps(response)
		{
			if (response.debug_errorMsg) { B_REST_Utils.console_info(`Server response debug errorMsg: ${response.logMsg_debug_errorMsg}`); }
			
			//NOTE: response.debug_isDump calling response.debug_isDump_output() is done in B_REST_API::call()'s finalize()
		}
		/*
		Check server's RouteParser_base::_output_json_injectCore_redirect_x() docs
		NOTE:
			If we reloadApp=true, check _boot_setUnbooting() docs
		WARNING:
			Will throw, if app isn't done booting and we don't want to reload
		*/
		_calls_handleRedirection(redirectInfo)
		{
			switch (redirectInfo.type)
			{
				case "routeName":
					this.routes_go_name(redirectInfo.routeName, redirectInfo.pathVars, redirectInfo.qsa, redirectInfo.reloadApp);
				break;
				case "path":
					this.routes_go_path(redirectInfo.path, redirectInfo.reloadApp);
				break;
				case "external":
					this.routes_go_external(redirectInfo.url, redirectInfo.newWindow);
				break;
				default:
					this.throwEx(`Unknown redirect type "${redirectInfo.type}"`);
				break;
			}
		}
		//Called on each call, no matter successful or not, before it actually resolves/rejects, so we can change the response's data or next directives to run
		async _abstract_calls_tweakResponse_hook(response, corePropsThenDirectives) { this._throwEx_abstractMissing(); }
		//Same as _abstract_calls_tweakResponse_hook(), but after. Could intercept errs here like if errorType is a bad login or access token expired...
		_abstract_calls_afterCall_general_handler(response) { this._throwEx_abstractMissing(); }
	
	
	
	//LOCALE & TRANSLATION (t_) RELATED
		get appLangs() { return this._appLangs; }
		
		get locale_lang() { return this._locale_lang; }
		//IMPORTANT: Doesn't force app reload on change, so we must then manually call reboot(), to end up on the same route but w the url in the right lang + modelDefs etc in the right lang
		set locale_lang(newLang)
		{
			if (this._locale_lang===newLang) { return; }
			
			const oldLang = this._locale_lang;
			
			this._locale_lang = newLang;
			this._api.lang    = newLang;
			
			B_REST_Utils.localStorage_set(B_REST_App_base.LS_KEY_LOCALE_LANG, newLang, /*isPersistent*/false);
			
			this._t_cache = {
				core:   {},
				custom: {},
			};
			
			this._abstract_onLangChange(oldLang,newLang);
		}
			_abstract_onLangChange(oldLang, newLang) { this._throwEx_abstractMissing(); }
			
		//To use directly on an img's :src. Note that these apply to langs and not countries
		static locale_lang_getIcon(lang)
		{
			switch (lang)
			{
				case "fr": return require("../../assets/countries/fr.svg");
				case "en": return require("../../assets/countries/us.svg");
				case "es": return require("../../assets/countries/es.svg");
				case "ru": return require("../../assets/countries/ru.svg");
				case "jp": return require("../../assets/countries/jp.svg");
				default:   this.throwEx(`Unknown lang "${lang}"`);
			}
		}
		
		/*
		Translates something in @/bREST/core/<lang>.json
		Usage ex:
			{
				"some": {
					"path": "Field {fieldName} must have max {maxLength} chars"
				}
			}
			t_core("some.path", {fieldName:"firstName",maxLength:20})
				-> "Field firstName must have max 20 chars"
		If path doesn't exist, would yield "%some.path@core%" + a warning
		NOTE: To make sure we have proper loc in all langs, use t_x_debug_compareLangs()
		*/
		t_core(locPath, details=null, lang=null) { return this._t_x("core",locPath,details,/*onNotFoundRetAsPercent*/true,/*retSubTree*/false,lang); }
		//Helper for things like "models.fields.validation.db.maxLength"
		t_core_field_validation(tag, details=null, lang=null)
		{
			return this._t_x("core", `${B_REST_App_base.LOC_PATH_MODELS}.${B_REST_App_base.LOC_PATH_FIELDS}.${B_REST_App_base.LOC_PATH_VALIDATION}.${tag}`, details, /*onNotFoundRetAsPercent*/true, /*retSubTree*/false, lang);
		}
		//Helper for things like "models.fields.placeholder.db.number.between"
		t_core_field_placeholder(tag, details=null, lang=null)
		{
			return this._t_x("core", `${B_REST_App_base.LOC_PATH_MODELS}.${B_REST_App_base.LOC_PATH_FIELDS}.${B_REST_App_base.LOC_PATH_PLACEHOLDER}.${tag}`, details, /*onNotFoundRetAsPercent*/true, /*retSubTree*/false, lang);
		}
		/*
		Variation to ret a sub tree / NULL
		NOTE: To make sure we have proper loc in all langs, use t_x_debug_compareLangs()
		*/
		t_core_subTree(locPath, details=null, lang=null) { return this._t_x("core",locPath,details,/*onNotFoundRetAsPercent*/false,/*retSubTree*/true,lang); }
			//Inner func used in all methods
			_t_x(coreOrCustom, locPath, detailsOrNULL, onNotFoundRetAsPercent, retSubTree, lang=null)
			{
				if (!locPath) { this.throwEx("Got no locPath"); }
				
				if (lang===null) { lang = this._locale_lang || this._businessConfig.defaultLang; }
				
				const canUseCache  = lang===this._locale_lang;
				const langCache    = canUseCache ? this._t_cache[coreOrCustom] : null;
				const debugLocPath = `%${locPath}@${coreOrCustom}-${lang}%`;
				
				//Check if we have it in cache, and that it's not valid
				if (canUseCache && langCache[locPath]===false) { return onNotFoundRetAsPercent ? debugLocPath : null; }
				
				let translation = null;
				
				if (canUseCache && langCache[locPath]!==undefined) { translation=langCache[locPath]; }
				else
				{
					let currentNode = this._t_dicts[coreOrCustom][lang]; //Root node of a lang, either in core.json or custom.json
					if (!currentNode) { this.throwEx(`Lang ${lang} not defined in ${coreOrCustom}`); }
					
					for (const loop_part of locPath.split("."))
					{
						if (currentNode[loop_part]===undefined || currentNode[loop_part]===null)
						{
							//Indicate that it doesn't exist
							translation = false;
							if (canUseCache) { langCache[locPath]=translation; }
							
							if (onNotFoundRetAsPercent)
							{
								this._t_x_warnNotFound(locPath, coreOrCustom, "not found", lang);
								return debugLocPath;
							}
							else { return null; }
						}
						currentNode = currentNode[loop_part];
					}
					
					if (retSubTree)
					{
						//NOTE: Here, no need to put to cache back a whole sub tree
						
						return currentNode;
					}
					
					if (!B_REST_Utils.string_is(currentNode))
					{
						translation = false; //Indicate that it doesn't exist
						if (canUseCache) { langCache[locPath]=translation; }
						
						if (onNotFoundRetAsPercent)
						{
							this._t_x_warnNotFound(locPath, coreOrCustom, "found, but not ending on a string", lang);
							return debugLocPath;
						}
						else { return null; }
					}
					
					translation = currentNode;
					//Put in cache
					if (canUseCache) { langCache[locPath]=translation; }
				}
				
				//Now check to replace every occurrence of details stuff
				if (detailsOrNULL)
				{
					B_REST_Utils.object_assert(detailsOrNULL);
					
					for (const loop_detailKey in detailsOrNULL)
					{
						translation = translation.replaceAll(`{${loop_detailKey}}`,detailsOrNULL[loop_detailKey]);
					}
				}
				
				//If we get here, we always have a string, so no subTree / NULL / false
				return this.debug_locPaths ? `<${debugLocPath}>: ${translation}` : translation;
			}
				_t_x_warnNotFound(locPath, coreOrCustom, msg, lang=null)
				{
					if (lang===null) {lang=this._locale_lang;}
					B_REST_Utils.console_warn(`Translation path "${locPath}" @ ${coreOrCustom}-${lang} ${msg}`);
				}
		
		/*
		Same as the core one above, but for custom things in json files behind _appLangs
		NOTE: To make sure we have proper loc in all langs, use t_x_debug_compareLangs()
		*/
		t_custom(locPath, details=null, lang=null) { return this._t_x("custom",locPath,details,/*onNotFoundRetAsPercent*/true,/*retSubTree*/false,lang); }
		t_custom_field_label(modelName, fieldName, details=null, lang=null)
		{
			this.throwEx(`Shouldn't use this method, because it's not gonna work if the field loc comes from the server and isn't injected in the local json file. Check B_REST_Descriptor::field_loc_label()`);
			
			const baseLocPath = B_REST_App_base.t_custom_field_baseLocPath(modelName, fieldName);
			return this._t_x("custom", `${baseLocPath}.${B_REST_App_base.LOC_KEY_LABEL}`, details, /*onNotFoundRetAsPercent*/true, /*retSubTree*/false);
		}
		//Variation where we ret NULL when not found
		t_custom_orNULL(locPath, details=null, lang=null) { return this._t_x("custom",locPath,details,/*onNotFoundRetAsPercent*/false,/*retSubTree*/false,lang); }
		/*
		Variation to ret a sub tree / NULL
		NOTE: To make sure we have proper loc in all langs, use t_x_debug_compareLangs()
		*/
		t_custom_subTree(locPath, details=null, lang=null) { return this._t_x("custom",locPath,details,/*onNotFoundRetAsPercent*/false,/*retSubTree*/true,lang); }
		//Helper to indicate that a loc path wasn't found
		t_custom_warnNotFound(locPath, lang=null) { return this._t_x_warnNotFound(locPath,"custom","not found",lang); }
		//Ex "models.User.fields.firstName"
		static t_custom_field_baseLocPath(modelName, fieldName) { return `${B_REST_App_base.LOC_PATH_MODELS}.${modelName}.${B_REST_App_base.LOC_PATH_FIELDS}.${fieldName}`; }
		//Tries to use our custom loc, and if not available, fallbacks to a core one. If even core one isn't found, we'll get its %%
		t_custom_alt(customLocPath,coreFallbackLocPath, details=null, lang=null)
		{
			//Where false/true,false is for onNotFoundRetAsPercent,retSubTree
			return this._t_x("custom",customLocPath,details,false,false,lang) ?? this._t_x("core",coreFallbackLocPath,details,true,false,lang);
		}
		/*
		Helps finding missing keys between lang files
		Crawls multiple lang files and yields a map of <dotted path name>:{<lang>:NULL|<string>}, where objs only contain langs that are defined for a given path
		Either rets as arr, or as HTML table
		skipAllNulls: If all langs are present for a given path, but all contain NULL, do we want them or not ?
		*/
		t_core_debug_compareLangs(  langs,skipAllNulls,sortKeys,retAsHTML) { return this._t_x_debug_compareLangs("core",  langs,skipAllNulls,sortKeys,retAsHTML); }
		t_custom_debug_compareLangs(langs,skipAllNulls,sortKeys,retAsHTML) { return this._t_x_debug_compareLangs("custom",langs,skipAllNulls,sortKeys,retAsHTML); }
			_t_x_debug_compareLangs(coreOrCustom, langs, skipAllNulls,sortKeys,retAsHTML)
			{
				let comparison = {};
				
				for (const loop_lang of langs)
				{
					const loop_data = this._t_dicts[coreOrCustom][loop_lang];
					if (!loop_data) { this.throwEx(`Lang ${loop_lang} not defined in ${coreOrCustom}`); }
					B_REST_App_base._t_x_debug_compareLangs_mergeOneLang_recurse(comparison,loop_lang, null, loop_data);
				}
				
				if (skipAllNulls)
				{
					for (const loop_path in comparison)
					{
						const loop_translations = comparison[loop_path];
						let loop_allNull = true;
						
						for (const loop_lang of langs)
						{
							if (!B_REST_Utils.object_hasPropName(loop_translations,loop_lang) || loop_translations[loop_lang]!==null) { loop_allNull=false; break; }
						}
						if (loop_allNull) { B_REST_Utils.object_removeProp(comparison,loop_path); }
					}
				}
				
				//NOTE: If we check in browser's dev tool, sort might get ignored
				if (sortKeys)
				{
					const sortedKeys = Object.keys(comparison).sort();
					const tmp_sortedComparison = {};
					for (const loop_key of sortedKeys) { tmp_sortedComparison[loop_key]=comparison[loop_key]; }
					comparison = tmp_sortedComparison;
				}
				
				if (!retAsHTML) { return comparison; }
				
				let html = `<style>
								table         { border-collapse:collapse; background-color:white; }
								table, th, td { padding:2px; border:1px solid #CCC; }
								th            { background-color:#AAA; }
								.missing      { background-color:#D81B60; color:white; text-align:center; }
								.null         { background-color:#81D4FA; color:black; text-align:center; }
								.emptyString  { background-color:#FFCC80; color:black; text-align:center; }
							</style>
							<table>
								<thead>
									<tr>
										<th>Path</th>
										<th>${langs.join("</th><th>")}</th>
									</tr>
								</thead>
								<tbody>`;
				for (const loop_path in comparison)
				{
					const loop_translations = comparison[loop_path];
					
					html += `<tr>
								<td>${loop_path}</td>`;
					for (const loop_lang of langs)
					{
						let loop_class       = "";
						let loop_translation = null;
						
						if (!B_REST_Utils.object_hasPropName(loop_translations,loop_lang))
						{
							loop_class       = "class='missing'";
							loop_translation = "<MISSING>";
						}
						else
						{
							loop_translation = loop_translations[loop_lang];
							
							if (loop_translation===null)
							{
								loop_class       = "class='null'";
								loop_translation = "<NULL>";
							}
							else if (loop_translation==='')
							{
								loop_class       = "class='emptyString'";
								loop_translation = "<EMPTY STRING>";
							}
							else { loop_translation=loop_translation; }
						}
						
						loop_translation = loop_translation.replaceAll("\n","\\n");
						loop_translation = loop_translation.replaceAll("\t","\\t");
						loop_translation = B_REST_Utils.string_toHTML_entities(loop_translation);
						
						html += `<td ${loop_class}>${loop_translation}</td>`;
					}
					html += `</tr>`;
				}
				html +=   `</tbody>
						</table>`;
				return html;
			}
				static _t_x_debug_compareLangs_mergeOneLang_recurse(comparison,lang, parentKeyNameOrRoot, subData)
				{
					for (const loop_key in subData)
					{
						if (loop_key.indexOf("🚀↑")!==-1) { continue; } //Skip generator related
						
						const loop_mapOrTranslation = subData[loop_key];
						const loop_path             = parentKeyNameOrRoot ? `${parentKeyNameOrRoot}.${loop_key}` : loop_key;
						
						//Node case
						if (B_REST_Utils.object_is(loop_mapOrTranslation)) { B_REST_App_base._t_x_debug_compareLangs_mergeOneLang_recurse(comparison,lang,loop_path,loop_mapOrTranslation); }
						//Sometimes we get arrs instead of objs, ex {weekdays:[{label:"Sunday",shortLabel:"Sun"}, {label:"Monday",shortLabel:"Mon"}, ...]}, so convert to obj for simplicity
						else if (B_REST_Utils.array_is(loop_mapOrTranslation))
						{	
							const loop_mapOrTranslation_asObj = {};
							for (let i=0; i<loop_mapOrTranslation.length; i++) { loop_mapOrTranslation_asObj[i]=loop_mapOrTranslation[i]; }
							B_REST_App_base._t_x_debug_compareLangs_mergeOneLang_recurse(comparison,lang,loop_path,loop_mapOrTranslation_asObj);
						}
						//Leaf case
						else
						{
							if (!B_REST_Utils.object_hasPropName(comparison,loop_path)) { comparison[loop_path]={}; }
							comparison[loop_path][lang] = loop_mapOrTranslation;
						}
					}
				}
	
	
	
	//MODELS RELATED
		//Helper to ret an instance of X model's B_REST_Descriptor, ex to then do B_REST_Descriptor::field_loc_shortLabel()
		models_getDescriptor(modelName) { return B_REST_Descriptor.commonDefs_get(modelName); }
		//Helper to ret a derived of B_REST_FieldDescriptor_base, to then do ex ::loc_bool_true, or ::enum_members
		models_field_getDescriptor(modelName, fieldNamePath) { return B_REST_Descriptor.commonDefs_get(modelName).allFields_find_byFieldNamePath(fieldNamePath); }
		//Helper to get a field's long label. NOTE: If we want to get both label & shortLabel at the same time, use models_field_getDescriptor() instead and manually do .label / .shortLabel
		models_field_getLabel(modelName, fieldNamePath) { return B_REST_Descriptor.commonDefs_get(modelName).field_loc_label(fieldNamePath); }
		//Helper to get a field's short label. NOTE: If we want to get both label & shortLabel at the same time, use models_field_getDescriptor() instead and manually do .label / .shortLabel
		models_field_getShortLabel(modelName, fieldNamePath) { return B_REST_Descriptor.commonDefs_get(modelName).field_loc_shortLabel(fieldNamePath); }
		//Helper to get a field's arr of B_REST_FieldDescriptor_DB_EnumMember (or throw, if it's not a TYPE_ENUM B_REST_FieldDescriptor_DB field)
		models_field_getEnumMembers(modelName, fieldNamePath) { return B_REST_Descriptor.commonDefs_get(modelName).field_enumMembers(fieldNamePath); }
		//Helper to create an instance of X. Check B_REST_Model::commonDefs_make() docs
		models_make(modelName,obj=null) { return B_REST_Model.commonDefs_make(modelName,obj); }
		//Helpers to create a list of instances of X model. Check B_REST_ModelList::commonDefs_make_x() docs
			modelLists_make_static(modelName)             { return B_REST_ModelList.commonDefs_make_static(modelName);             }
			modelLists_make_forLoading(modelName,options) { return B_REST_ModelList.commonDefs_make_forLoading(modelName,options); }
		/*
		Sometimes we want to get a toLabel() for a model, but we only have its FK, so we'd like to do an API call just for that
		To prevent doing 100x API calls at the same time, call will be throttled so we group multiple queries done in a short time (MODELS_TO_LABEL_CACHE_THROTTLE_MSECS)
			A val of even 0 is good enough, for when it's because !async stuff are doing things in loop, but JIC, could put a bit higher
		NOTE:
			Sometimes it's just because we loaded some model w ex a lookup, but we didn't say we wanted the lookups fields and we're trying to do a toLabel() on it
			Ex for Vue implementation:
				Ex in CPA we're in PresentialEventForm.vue and have a:
					<br-field-db :model="model" field="staff_fk" picker="staffList" />
				Prob is, the default "requiredFields:<all>" isn't enough to load db fields in sub tables, so we need to change it like
						requiredFields: "<all>|staff.user(firstName|lastName)|staff.<dbOnly>",
					or
						requiredFields: "<all>|staff.<toLabel>",
			So we might just check if the thing that loaded the model in the 1st place could just have loaded that extra info, so we don't need to do sep API calls after
			Also, Vue implementation also has pickers_x(), like B_REST_VueApp_base::pickers_prompt_x(), to open fully working standalone pickers that don't need a BrFieldDb instance
		WARNING:
			API call will throw if we try to get data for models and/or pk that user shouldn't be allowed to get
			B_REST_Model_base::toLabel() has a reason prop, but we don't handle it here. If it's a prob, could one day add reason to the cacheKey
		*/
		async models_toLabelCache_get(modelName, pkTag)
		{
			if (B_REST_Utils.object_is(pkTag)) { this.throwEx(`Not supporting multi-field PKs for now`); }
			
			const cacheKey = `${modelName}|${pkTag}`;
			
			//Check if we need to prep a promise to get the val and do an API call to get it. However, throttle API call, in case multiple fields ask for things at the same time
			if (!B_REST_Utils.object_hasPropName(this._models_toLabelCache_cache,cacheKey))
			{
				this._models_toLabelCache_cache[cacheKey] = {promiseOrLabel:null,resolver:null};
				this._models_toLabelCache_pendingAPICall_cacheKeys.push(cacheKey);
				
				this._models_toLabelCache_cache[cacheKey].promiseOrLabel = new Promise((s,f) => this._models_toLabelCache_cache[cacheKey].resolver=s);
				
				//Throttle API call, and maybe some other call to models_toLabelCache_get() will have taken care of everything already
				await this._models_toLabelCache_get_throttleAPICall();
			}
			
			return this._models_toLabelCache_cache[cacheKey].promiseOrLabel;
		}
			async _models_toLabelCache_get_throttleAPICall()
			{
				await B_REST_Utils.sleep(B_REST_App_base.MODELS_TO_LABEL_CACHE_THROTTLE_MSECS);
				if (this._models_toLabelCache_pendingAPICall_cacheKeys.length===0) { return; }
				
				B_REST_Utils.console_info(`Doing a models_toLabelCache_get_throttleAPICall() for having waited ${B_REST_App_base.MODELS_TO_LABEL_CACHE_THROTTLE_MSECS}ms`, this._models_toLabelCache_pendingAPICall_cacheKeys);
				
				const currentAPICall_cacheKeys = this._models_toLabelCache_pendingAPICall_cacheKeys;
				this._models_toLabelCache_pendingAPICall_cacheKeys = []; //IMPORTANT: This must happen before we do the call
				
				const request = new this.POST(B_REST_App_base.API_CALL_MODEL_TO_LABEL_CACHE);
				request.data = {cacheKeys:currentAPICall_cacheKeys};
				let response_cacheKeyLabels = null; //If successful, a map of cacheKey => label
				
				try
				{
					const response = await this.call(request);
					response_cacheKeyLabels = response.data.cacheKeyLabels;
				}
				catch (response) { /*Ignore; we'll just nullify everything below */ }
				
				for (const loop_cacheKey of currentAPICall_cacheKeys)
				{
					//Skip cases where in the meanwhile, something else called models_toLabelCache_set() directly
					if (!this._models_toLabelCache_cache[loop_cacheKey].resolver) { continue; }
					
					const loop_label = response_cacheKeyLabels?.[loop_cacheKey] ?? null; //In case of failure, put all null
					
					this._models_toLabelCache_cache[loop_cacheKey].promiseOrLabel = loop_label; //Change the promise into a string or NULL
					
					this._models_toLabelCache_cache[loop_cacheKey].resolver(loop_label);
					delete this._models_toLabelCache_cache[loop_cacheKey].resolver; //No need for resolver prop anymore
				}
			}
		models_toLabelCache_set(modelName, pkTag, label)
		{
			const cacheKey = `${modelName}|${pkTag}`;
			
			if (!B_REST_Utils.object_hasPropName(this._models_toLabelCache_cache,cacheKey))
			{
				this._models_toLabelCache_cache[cacheKey] = {promiseOrLabel:label};
			}
		}
	
	
	
	//SHARED LISTS RELATED
		/*
		From a call that yields something like:
			{
				'campaigns':       {type:'modelList', items:[], 'modelClassName'=>'Model_Campaign'},
				'activitySectors': {type:'modelList', items:[], 'modelClassName'=>'Model_ActivitySector'},
				'currencies':      {type:'custom',    items:[]},
			}
		*/
		_sharedLists_defineFromAPICall(sharedListDefs)
		{
			this._sharedLists = {};
			
			for (const loop_tag in sharedListDefs)
			{
				const loop_sharedListDef = sharedListDefs[loop_tag];
				
				this._sharedLists[loop_tag] = new B_REST_App_SharedList(loop_tag, loop_sharedListDef.type, loop_sharedListDef.items, loop_sharedListDef.modelClassName);
			}
		}
		/*
		From a call that yields something like the following:
			{
				'campaigns':       <items>,
				'activitySectors': <items>,
				'currencies':      <items>,
			]
		NOTE:
			We might only receive lists that got altered
		*/
		_sharedLists_updateFromAPICall(sharedLists)
		{
			for (const loop_tag in sharedLists)
			{
				const loop_items = sharedLists[loop_tag]; //NOTE: Initially, we were doing "sharedLists[loop_tag].items", but it was fucking when it came from server's bREST_base::sharedList_getAll()
				
				this._sharedLists[loop_tag].updateData(loop_items);
			}
		}
		//Just rets the tags
		get sharedLists_tags() { return Object.keys(this._sharedLists); }
		//Rets a B_REST_ModelList instance, or custom arr
		sharedLists_getSrc(tag) { return this._sharedLists_get(tag).src; }
		//Rets the arr of B_REST_Model instances in a B_REST_ModelList, or custom arr
		sharedLists_getItems(tag) { return this._sharedLists_get(tag).items; }
			_sharedLists_get(tag)
			{
				if (!this._sharedLists[tag]) { this.throwEx(`Unknown shared list "${tag}"`); }
				return this._sharedLists[tag];
			}
	
	
	
	//ROUTES RELATED
		get routeDefs() { return this._routeDefs; }
		//Must call _routes_define() in there. Called in constructor
		_abstract_routes_defineRoutes() { this._throwEx_abstractMissing(); }
		//Can only be used while constructing
		_routes_define(routeDef)
		{
			B_REST_Utils.instance_isOfClass_assert(B_REST_App_RouteDef_base, routeDef);
			this._routes_define_x_assertCan();
			
			//Do some validations
			{
				if (this._routeDefs[routeDef.name]) { this.throwEx(`Already defined route with name "${routeDef.name}"`,routeDef); }
				
				for (const loop_lang of this._appLangs)
				{
					if (!B_REST_Utils.object_hasPropName(routeDef.langUrls,loop_lang)) { this.throwEx(`Route def must def URLs in all supported langs`,routeDef); }
				}
			}
			
			this._routeDefs[routeDef.name] = routeDef;
		}
			_routes_define_x_assertCan() { if(this._boot_status!==B_REST_App_base.BOOT_STATUS_IDLE){this.throwEx(`Can only define routes before booting app`);} }
		/*
		Rets the instance of B_REST_App_RouteDef_base, or throws if not found
		If we instead want to infer from a multilingual URL and return "more" than just the instance, use routes_getRouteInfo_fromPath_x()
		*/
		routeDefs_get(name)
		{
			return this._routeDefs[name] || this.throwEx(`Unknown routeDef "${name}"`);
		}
		routeDefs_get_moduleList(moduleName) { return this.routeDefs_get(`${moduleName}-list`); }
		routeDefs_get_moduleForm(moduleName) { return this.routeDefs_get(`${moduleName}-form`); }
			//Helpers; yield NULL if not supported / defined for this app
			get routeDefs_landpage() { return this._routeDefs[B_REST_App_RouteDef_base.NAME_LANDPAGE] || null; }
			get routeDefs_login()    { return this._routeDefs[B_REST_App_RouteDef_base.NAME_LOGIN]    || null; }
			get routeDefs_resetPwd() { return this._routeDefs[B_REST_App_RouteDef_base.NAME_RESET_PWD]|| null; } //WARNING: URL must match server's bREST_Custom::_abstract_uiRoutes_resetPwd_paths() URLs
			get routeDefs_profile()  { return this._routeDefs[B_REST_App_RouteDef_base.NAME_PROFILE]  || null; }
			get routeDefs_404()      { return this._routeDefs[B_REST_App_RouteDef_base.NAME_404]      || null; }
			get routeDefs_403()      { return this._routeDefs[B_REST_App_RouteDef_base.NAME_403]      || null; }
		/*
		Funcs giving info about the current route, some w instances of B_REST_App_RouteDef_base / B_REST_App_RouteInfo_base der
		Some can yield NULL if current route doesn't match anything
		*/
			get routes_current_info()                  { return this._routes_current_info;                                                              }
			get routes_current_def()                   { return this._routes_current_info.routeDef       ?? null;                                       }
			get routes_current_name()                  { return this._routes_current_info.routeDef?.name ?? null;                                       }
			get routes_current_path()                  { return this._routes_current_info.fullPath;                                                     }
			get routes_current_pathVars()              { return this._routes_current_info.pathVars;                                                     }
			get routes_current_pathVars_pkTag()        { return this._routes_current_pathVars_x_pkTag_get("pathVarNames_pkTag");                        } //For ex when route is like /citizens/:pkTag. If using Vue, check B_REST_VueApp_base::_routes_define_genericListFormModule()
			get routes_current_pathVars_parent_pkTag() { return this._routes_current_pathVars_x_pkTag_get("pathVarNames_parent_pkTag");                 } //For ex when route is like /citizens/:citizen/animals/:pkTag, would yield "citizen". Check B_REST_App_RouteDef_base docs
			get routes_current_pathVars_sub_pkTag()    { return this._routes_current_pathVars_x_pkTag_get("pathVarNames_sub_pkTag");                    } //For ex when route is like /citizens/:citizen/animals/:pkTag, would yield "pkTag". Check B_REST_App_RouteDef_base docs
			get routes_current_qsa()                   { return this._routes_current_info.qsa;                                                          }
			get routes_current_qsa_intendedFullPath()  { return this._routes_current_info.qsa?.[B_REST_App_base.ROUTES_QSA_INTENDED_FULL_PATH] ?? null; }
			get routes_current_hashTag()               { return this._routes_current_info.hashTag;                                                      }
			get routes_current_travelDir()             { return this._routes_current_travelDir;                                                         }
			get routes_current_travelDir_isUnrelated() { return this._routes_current_travelDir===B_REST_App_base.ROUTES_TRAVEL_DIR_UNRELATED;           }
			get routes_current_travelDir_isToChild()   { return this._routes_current_travelDir===B_REST_App_base.ROUTES_TRAVEL_DIR_TO_CHILD;            }
			get routes_current_travelDir_isToParent()  { return this._routes_current_travelDir===B_REST_App_base.ROUTES_TRAVEL_DIR_TO_PARENT;           }
				_routes_current_pathVars_x_pkTag_get(which)
				{
					const pathVarName = this._routes_current_info.routeDef?.[which];
					if (!pathVarName) { return null; }
					return this._routes_current_info.pathVars?.[pathVarName] ?? null;
				}
		/*
		Converts a full URL into an instance of B_REST_App_RouteInfo_base, with a routeDef prop (if URL is valid)
		URL may contain ?QSA & #hashTag
		Always ret an instance of B_REST_App_RouteInfo_base
		IMPORTANT:
			-If it doesn't point to a known route, we'll still ret a B_REST_App_RouteInfo_base, but leave the routeDef prop NULL,
				even if we have a catch-all routeDef (B_REST_App_RouteDef_base::NAME_404)
				We can use B_REST_App_RouteInfo_base::isUn/Known() to know
			-If multiple langs have the same URL, then lang will equal NULL
		WARNING: If we have a base path rel to domain's root, ex "//<domainName>/myApp/" instead of "//<domainName>/", we should trim "/myApp/"
		*/
		routes_getRouteInfo_fromPath(fullPath)
		{
			//Drop prefixed base URL, if any. Ex "/pwa/some/thing" -> "/some/thing"
			fullPath = fullPath.replace(new RegExp(`^${B_REST_Utils.string_escapeRegex(process.env.BASE_URL)}`), "/");
			
			const urlInfo      = B_REST_Utils.url_getInfo(fullPath);
			const splittedPath = B_REST_App_RouteDef_base.splitPath(urlInfo.path);
			
			for (const loop_routeDefName in this._routeDefs)
			{
				const loop_routeDef = this._routeDefs[loop_routeDefName];
				const loop_match    = loop_routeDef.checkPathMatch(splittedPath);
				
				if (loop_match) { return this._abstract_routes_getRouteInfo_fromPath_retFound(fullPath,loop_routeDef,loop_match.pathVars,urlInfo.qsa,urlInfo.hashTag,loop_match.lang); }
			}
			
			return this._abstract_routes_getRouteInfo_fromPath_retFound(fullPath,/*routeDef*/null,/*pathVars*/null,urlInfo.qsa,urlInfo.hashTag,/*lang*/null);
		}
			//Must just ret a der instance of B_REST_App_RouteInfo_base from the received data
			_abstract_routes_getRouteInfo_fromPath_retFound(fullPath,routeDef=null,pathVars=null,qsa=null,hashTag=null,lang=null) { this._throwEx_abstractMissing(); }
		//Alias to reboot(). Check its docs
		routes_reload()
		{
			this._boot_setUnbooting();
			window.location.reload();
		}
		/*
		Might trigger a _abstract_beforeUnload_generalHook()
		If we reloadApp=true, check _boot_setUnbooting() docs
		WARNING:
			Will throw, if app isn't done booting and we don't want to reload
		*/
		routes_go_back(reloadApp=false)
		{
			if (reloadApp)
			{
				this._boot_setUnbooting();
				window.history.back();
			}
			else
			{
				//If we don't want to reload app, then we need the app to be fully loaded before we can navigate
				if (this.boot_isBooting) { this.throwEx(`Can't navigate wo reloading app, if app isn't fully booted yet`); }
				
				//Then we can proceed with the framework's route handler
				this._abstract_routes_go_x_replace_back();
			}
		}
		/*
		Expects an instance of B_REST_App_routeInfo_base der
		If target lang is diff than the actual one, will get changed if reloadApp=true, otherwise we must change it in advance
		Might trigger a _abstract_beforeUnload_generalHook()
		If we reloadApp=true, check _boot_setUnbooting() docs
		Rets the same B_REST_App_RouteInfo_base der instance (before any navigation guard - see _abstract_routes_beforeNavigationChange())
		WARNING:
			Will throw, if app isn't done booting and we don't want to reload
		*/
		routes_go_routeInfo(routeInfo_target, reloadApp=false)
		{
			//WARNING: If we alter code here, check to maybe also alter routes_goBlank_x() funcs
			
			if (reloadApp)
			{
				this._boot_setUnbooting();
				window.location.href = routeInfo_target.fullPath_wBaseUrl;
			}
			else
			{
				//If we don't want to reload app, then we need the app to be fully loaded before we can navigate
				if (this.boot_isBooting) { this.throwEx(`Can't navigate wo reloading app, if app isn't fully booted yet`); }
				
				//Ignore if path is the same. Don't just compare window.location.href to "path", in case trailing "/", QSA etc are arranged diff
				if (this._routes_current_info.fullPath===routeInfo_target.fullPath)
				{
					B_REST_Utils.console_warn(`Navigation skipped, because it's the exact same URL and we don't want to reload the page`,{routeInfo_current:this._routes_current_info,routeInfo_target});
				}
				else
				{
					//Then we can proceed with the framework's route handler
					this._abstract_routes_go_x_replace_path(routeInfo_target.fullPath);
				}
			}
			
			return routeInfo_target;
		}
			_abstract_routes_go_x_replace_back()     { this._throwEx_abstractMissing(); }
			_abstract_routes_go_x_replace_path(path) { this._throwEx_abstractMissing(); }
		/*
		Expects a url relative to app's base dir, optionnally w QSA
		If target lang is diff than the actual one, will get changed if reloadApp=true, otherwise we must change it in advance
		Might trigger a _abstract_beforeUnload_generalHook()
		If we reloadApp=true, check _boot_setUnbooting() docs
		Rets an instance of B_REST_App_RouteInfo_base der where we end up (before any navigation guard - see _abstract_routes_beforeNavigationChange())
		WARNING:
			Will throw, if app isn't done booting and we don't want to reload
		*/
		routes_go_path(path, reloadApp=false)
		{
			//WARNING: If we alter code here, check to maybe also alter routes_goBlank_x() funcs
			
			const routeInfo = this.routes_getRouteInfo_fromPath(path);
			
			return this.routes_go_routeInfo(routeInfo, reloadApp);
		}
		/*
		Expects a known route name (within the _routeDefs)
		Will use the URL in the actual lang, so change in advance if needed
		Might trigger a _abstract_beforeUnload_generalHook()
		If we reloadApp=true, check _boot_setUnbooting() docs
		Rets an instance of B_REST_App_RouteInfo_base der where we end up (before any navigation guard - see _abstract_routes_beforeNavigationChange())
		WARNING:
			-Will throw, if app isn't done booting and we don't want to reload
			-If implementing for Vue (B_REST_VueApp_base), <v-btn> and such "to" prop matches a path by default but not a route name, so we should do:
				<v-btn :to="{name:'someRouteName'}" />
		*/
		routes_go_name(routeName, pathVars={}, qsa={}, reloadApp=false)
		{
			//WARNING: If we alter code here, check to maybe also alter routes_goBlank_x() funcs
			
			const routeDef  = this.routeDefs_get(routeName);
			const routeInfo = routeDef.toRouteInfo(pathVars,qsa,/*hashTag*/null,/*lang*/null);
			
			return this.routes_go_routeInfo(routeInfo, reloadApp);
		}
			//Helpers. WARNING: If we add new here, also alter the routes_goBlank_x() funcs
			routes_go_root(                 qsa={}, reloadApp=false) { return this.routes_go_path(           B_REST_Utils.url_addQSAAndHashTag("/",qsa),reloadApp); }
			routes_go_landpage(pathVars={}, qsa={}, reloadApp=false) { return this.routes_go_name(B_REST_App_RouteDef_base.NAME_LANDPAGE, pathVars,qsa, reloadApp); }
			routes_go_login(   pathVars={}, qsa={}, reloadApp=false) { return this.routes_go_name(B_REST_App_RouteDef_base.NAME_LOGIN,    pathVars,qsa, reloadApp); }
			routes_go_resetPwd(pathVars={}, qsa={}, reloadApp=false) { return this.routes_go_name(B_REST_App_RouteDef_base.NAME_RESET_PWD,pathVars,qsa, reloadApp); }
			routes_go_profile( pathVars={}, qsa={}, reloadApp=false) { return this.routes_go_name(B_REST_App_RouteDef_base.NAME_PROFILE,  pathVars,qsa, reloadApp); }
			routes_go_404(     pathVars={}, qsa={}, reloadApp=false) { return this.routes_go_name(B_REST_App_RouteDef_base.NAME_404,      pathVars,qsa, reloadApp); }
			routes_go_403(     pathVars={}, qsa={}, reloadApp=false) { return this.routes_go_name(B_REST_App_RouteDef_base.NAME_403,      pathVars,qsa, reloadApp); } //NOTE: We have in frontend's B_REST_App_base REBOOT_REASONS_PERMS, routes_go_x_403() & _routes_beforeNavigationChange(), and RouteParser_base::output_json_injectCore_redirect_403() in server
			/*
			For now, especially if we implement Vue & use B_REST_VueApp_base::_routes_helper_makeRouteDefs_authenticatedModules()
			WARNING: If we add new here, also alter the routes_goBlank_x() funcs
			*/
				routes_go_moduleList(      moduleName,       qsa={}, reloadApp=false) { return this._routes_goX_module_x(moduleName,"list",null,  qsa,reloadApp,/*isBlank*/false); }
				routes_go_moduleForm_new(  moduleName,       qsa={}, reloadApp=false) { return this._routes_goX_module_x(moduleName,"form","*",   qsa,reloadApp,/*isBlank*/false); }
				routes_go_moduleForm_pkTag(moduleName,pkTag, qsa={}, reloadApp=false) { return this._routes_goX_module_x(moduleName,"form",pkTag, qsa,reloadApp,/*isBlank*/false); }
					_routes_goX_module_x(moduleName,suffix,pkTagOrNULL=null, qsa={}, reloadApp=false, isBlank=false)
					{
						const routeNavMethodName = isBlank ? "routes_goBlank_name" : "routes_go_name";
						const routeName          = `${moduleName}-${suffix}`; //Ex "citizen-list" or "citizen-form"
						const pathVars           = {};
						
						if (pkTagOrNULL!==null)
						{
							const pathVarNames_pkTag = B_REST_App_base.ROUTES_PATH_VARS_PK_TAG; //NOTE: We should instead find out the B_REST_RouteDef_base behind it and use its pathVarNames_pkTag prop
							
							pathVars[pathVarNames_pkTag] = pkTagOrNULL;
						}
						
						return this[routeNavMethodName](routeName, pathVars, qsa, reloadApp);
					}
						//WARNING: If we change B_REST_App_base::ROUTES_PATH_VARS_PK_TAG, then we also have to change {pkTag} to something else
				//Variants where we nest like "/citizens/123/animals/", "/citizens/123/animals/*" & "/citizens/123/animals/456"
					routes_go_subModuleList(      parent_moduleName,parent_pkTag, sub_moduleName,           qsa={}, reloadApp=false) { return this._routes_goX_subModule_x(parent_moduleName,parent_pkTag,sub_moduleName,"list",null,      qsa,reloadApp,/*isBlank*/false); }
					routes_go_subModuleForm_new(  parent_moduleName,parent_pkTag, sub_moduleName,           qsa={}, reloadApp=false) { return this._routes_goX_subModule_x(parent_moduleName,parent_pkTag,sub_moduleName,"form","*",       qsa,reloadApp,/*isBlank*/false); }
					routes_go_subModuleForm_pkTag(parent_moduleName,parent_pkTag, sub_moduleName,sub_pkTag, qsa={}, reloadApp=false) { return this._routes_goX_subModule_x(parent_moduleName,parent_pkTag,sub_moduleName,"form",sub_pkTag, qsa,reloadApp,/*isBlank*/false); }
						_routes_goX_subModule_x(parent_moduleName,parent_pkTag,sub_moduleName,suffix, sub_pkTagOrNULL=null, qsa={}, reloadApp=false, isBlank=false)
						{
							const routeNavMethodName = isBlank ? "routes_goBlank_name" : "routes_go_name";
							const routeName          = `${parent_moduleName}-form-sub-${sub_moduleName}-${suffix}`; //Ex "citizen-form-sub-animal-list" or "citizen-form-sub-animal-form"
							const pathVars           = {};
							
							//Ex here we want to end with something like {citizen:123} or {citizen:123, pkTag:456} for either "citizens/123/animals" or "citizens/123/animals/456"
							{
								pathVars[parent_moduleName] = parent_pkTag;
								
								if (sub_pkTagOrNULL!==null)
								{
									const pathVarNames_sub_pkTag = B_REST_App_base.ROUTES_PATH_VARS_PK_TAG; //NOTE: We should instead find out the B_REST_RouteDef_base behind it and use its pathVarNames_sub_pkTag prop
									
									pathVars[pathVarNames_sub_pkTag] = sub_pkTagOrNULL;
								}		
							}
							
							return this[routeNavMethodName](routeName, pathVars, qsa, reloadApp);
						}
		/*
		Might trigger a _abstract_beforeUnload_generalHook()
		Check _boot_setUnbooting() docs
		*/
		routes_go_external(url)
		{
			//WARNING: If we alter code here, check to maybe also alter routes_goBlank_x() funcs
			
			this._boot_setUnbooting();
			window.location.href = url;
		}
		//Same go funcs, but in a new window. Check their !newWindow equivalent for docs
			routes_goBlank_external(url) { window.open(url,"_blank"); }
			routes_goBlank_routeInfo(routeInfo_target)
			{
				B_REST_Utils.instance_isOfClass_assert(B_REST_App_RouteInfo_base, routeInfo_target);
				window.open(routeInfo_target.fullPath_wBaseUrl, "_blank");
			}
			routes_goBlank_path(path)
			{
				const routeInfo = this.routes_getRouteInfo_fromPath(path);
				
				return this.routes_goBlank_routeInfo(routeInfo);
			}
			routes_goBlank_name(routeName, pathVars={}, qsa={})
			{
				const routeDef  = this.routeDefs_get(routeName);
				const routeInfo = routeDef.toRouteInfo(pathVars,qsa,/*hashTag*/null,/*lang*/null);
				
				return this.routes_goBlank_routeInfo(routeInfo);
			}
			routes_goBlank_root(                 qsa={}) { return this.routes_goBlank_path(           B_REST_Utils.url_addQSAAndHashTag("/",qsa)); }
			routes_goBlank_landpage(pathVars={}, qsa={}) { return this.routes_goBlank_name(B_REST_App_RouteDef_base.NAME_LANDPAGE, pathVars,qsa);  }
			routes_goBlank_login(   pathVars={}, qsa={}) { return this.routes_goBlank_name(B_REST_App_RouteDef_base.NAME_LOGIN,    pathVars,qsa);  }
			routes_goBlank_resetPwd(pathVars={}, qsa={}) { return this.routes_goBlank_name(B_REST_App_RouteDef_base.NAME_RESET_PWD,pathVars,qsa);  }
			routes_goBlank_profile( pathVars={}, qsa={}) { return this.routes_goBlank_name(B_REST_App_RouteDef_base.NAME_PROFILE,  pathVars,qsa);  }
			routes_goBlank_404(     pathVars={}, qsa={}) { return this.routes_goBlank_name(B_REST_App_RouteDef_base.NAME_404,      pathVars,qsa);  }
			routes_goBlank_403(     pathVars={}, qsa={}) { return this.routes_goBlank_name(B_REST_App_RouteDef_base.NAME_403,      pathVars,qsa);  } //NOTE: We have in frontend's B_REST_App_base REBOOT_REASONS_PERMS, routes_go_x_403() & _routes_beforeNavigationChange(), and RouteParser_base::output_json_injectCore_redirect_403() in server
			routes_goBlank_moduleList(      moduleName,       qsa={}, reloadApp=false) { return this._routes_goX_module_x(moduleName,"list",null,  qsa,reloadApp,/*isBlank*/true); }
			routes_goBlank_moduleForm_new(  moduleName,       qsa={}, reloadApp=false) { return this._routes_goX_module_x(moduleName,"form","*",   qsa,reloadApp,/*isBlank*/true); }
			routes_goBlank_moduleForm_pkTag(moduleName,pkTag, qsa={}, reloadApp=false) { return this._routes_goX_module_x(moduleName,"form",pkTag, qsa,reloadApp,/*isBlank*/true); }
			//Variants where we nest like "/citizens/123/animals/", "/citizens/123/animals/*" & "/citizens/123/animals/456"
				routes_goBlank_subModuleList(      parent_moduleName,parent_pkTag, sub_moduleName,           qsa={}, reloadApp=false) { return this._routes_goX_subModule_x(parent_moduleName,parent_pkTag,sub_moduleName,"list",null,      qsa,reloadApp,/*isBlank*/true); }
				routes_goBlank_subModuleForm_new(  parent_moduleName,parent_pkTag, sub_moduleName,           qsa={}, reloadApp=false) { return this._routes_goX_subModule_x(parent_moduleName,parent_pkTag,sub_moduleName,"form","*",       qsa,reloadApp,/*isBlank*/true); }
				routes_goBlank_subModuleForm_pkTag(parent_moduleName,parent_pkTag, sub_moduleName,sub_pkTag, qsa={}, reloadApp=false) { return this._routes_goX_subModule_x(parent_moduleName,parent_pkTag,sub_moduleName,"form",sub_pkTag, qsa,reloadApp,/*isBlank*/true); }
		/*
		Tells if we have perms for that route, unless we want to ignore checks
		Receives an instance of B_REST_App_RouteInfo_base der (containing a B_REST_App_RouteDef_base der - or NULL)
		*/
		async routes_hasPerms(routeInfo)
		{
			B_REST_Utils.instance_isOfClass_assert(B_REST_App_RouteInfo_base, routeInfo);
			
			if (this._debug_ignorePerms) { return true; }
			
			if (routeInfo.needsAuth && !this.user_isAuth) { return false; }
			
			const hasPerms = await this._abstract_routes_hasPerms(routeInfo);
			if (hasPerms!==true && hasPerms!==false) { this.throwEx(`_abstract_routes_hasPerms() must ret bool`,routeInfo); }
			return hasPerms;
		}
			//Check routes_hasPerms() docs. Only called if necessary, (not ignoring perms check + either are at least logged / pub user for a pub route)
			async _abstract_routes_hasPerms(routeInfo) { this._throwEx_abstractMissing(); }
		/*
		Ex for when we try to go back to login page when we're already auth / for where to go after being auth
		Could be something like:
			B_REST_App_RouteDef_base.NAME_PROFILE
			B_REST_App_RouteDef_base.NAME_LANDPAGE
			"user-list"
			"someOtherModule-list"
		NOTE: Should match w backend's redirect logic in:
				RouteParser_base::_overridable_setUser_redirectableActions_injectRedirectionDirective()
				bREST_Custom::_abstract_uiRoutes_defaultRouteName_auth()
		*/
		_abstract_routes_authDefaultRouteName() { this._throwEx_abstractMissing(); }
		/*
		Rets either:
			false:                           Don't navigate
			true:                            Keep going on intended route
			<B_REST_App_RouteInfo_base der>: Redirect to somewhere else (checks that its fullPath !== intended route's fullPath, otherwise rets the above true)
		*/
		async _routes_beforeNavigationChange(routeInfo_to_intended, routeInfo_from=null)
		{
			B_REST_Utils.instance_isOfClass_assert(B_REST_App_RouteInfo_base, routeInfo_to_intended);
			if (routeInfo_from!==null) { B_REST_Utils.instance_isOfClass_assert(B_REST_App_RouteInfo_base,routeInfo_from); }
			
			if (routeInfo_from?.isUnknown) { routeInfo_from=null; } //KISS by putting from to NULL on boot
			
			const fullPath_to_intended = routeInfo_to_intended.fullPath;
			
			let routeDefName_to_proposedRedirection = null;
			let routeInfo_to_proposedAction         = null;  //Bool or other route
			let leaveTrackIntendedRoute             = false; //To tell if we should add B_REST_App_base::ROUTES_QSA_INTENDED_FULL_PATH to QSA
			
			//Check to propose a redirection when route is unknown. Note that in all cases here, we'll assume we'll have perms for such redirection (otherwise throw)
			if (routeInfo_to_intended.isUnknown)
			{
				//If we went to "/" and we must redirect to something like "/login/"
				if (routeInfo_to_intended.isRoot)
				{
					B_REST_Utils.console_warn(`Tried to go to route "/", but we have no route w that path, so checking to go to auth users's default route, otherwise landpage / login`);
					
					if      (this.user_isAuth)        { routeDefName_to_proposedRedirection=this._abstract_routes_authDefaultRouteName(); }
					else if (this.routeDefs_landpage) { routeDefName_to_proposedRedirection=B_REST_App_RouteDef_base.NAME_LANDPAGE;       }
					else if (this.routeDefs_login)    { routeDefName_to_proposedRedirection=B_REST_App_RouteDef_base.NAME_LOGIN;          }
				}
				else
				{
					B_REST_Utils.console_warn(`Tried to go to a route not defined in the current lang (${this._locale_lang}): "${fullPath_to_intended}". Will check if it matches a route in another lang, otherwise try a 404 page`);
					
					/*
					Check if it could match something in another lang, and if so, redirect to that route, but in our current lang and not that route's actual lang
					Check boot_await() docs
					*/
					const otherLangAttempt_routeInfo = this.routes_getRouteInfo_fromPath(fullPath_to_intended);
					if (otherLangAttempt_routeInfo.isKnown)
					{
						const otherLangAttempt_lang = otherLangAttempt_routeInfo.lang; //NOTE: NULL if URL is the same in multiple langs, which shouldn't happen; otherwise, why would it says routeInfo_to_intended.isUnknown in the first place ?
						if (!otherLangAttempt_lang || otherLangAttempt_lang===this._locale_lang || !otherLangAttempt_routeInfo.routeDef) { this.throwEx(`Went to an unknown route, but found that it does match a route we should have been able to detect wo any prob`,{otherLangAttempt_routeInfo,routeInfo_to_intended}); }
						
						/*
						NOTE:
							It'd be optimized to just keep the routeInfo and use it later, instead of doing:
								this.routeDefs_get(routeDefName_to_proposedRedirection).toRouteInfo()
							However, we need to play w QSA for redirections, so it's best to KISS and have only 1 code branch doing something like:
								const qsa                                            = {};
								const intendedFullPath_cleaned                       = B_REST_Utils.url_removeQSA(fullPath_to_intended, B_REST_App_base.ROUTES_QSA_INTENDED_FULL_PATH)
								qsa[B_REST_App_base.ROUTES_QSA_INTENDED_FULL_PATH]   = intendedFullPath_cleaned;
								this.routeDefs_get(routeDefName_to_proposedRedirection).toRouteInfo(pathVars, qsa);
						*/
						routeDefName_to_proposedRedirection = otherLangAttempt_routeInfo.routeDef.name;
						
						B_REST_Utils.console_warn(`Tried to go to a route in another lang "${fullPath_to_intended}" @ ${otherLangAttempt_lang}, so redirecting to that same route, but in the current lang (${this._locale_lang}). Note: if we've just changed our user's lang and we're trying to do a back nav, it's possible that no navigation happens now`);
					}
					//If we have a 404, redirect there and add a ?_i=... to know where we came from, accessible via B_REST_App_base::routes_current_qsa_intendedFullPath()
					else if (this.routeDefs_404) { routeDefName_to_proposedRedirection=B_REST_App_RouteDef_base.NAME_404; }
					else
					{
						//Do nothing; will fall in next if checking for |routeDefName_to_proposedRedirection and throw saying we don't know what to do next
					}
				}
				
				if (!routeDefName_to_proposedRedirection) { this.throwEx(`Went on unknown route and can't even go back to a landpage/login, nor have a 404 page`,{routeInfo_to_intended,routeInfo_from}); }
			}
			//If we're auth and trying to go to login
			else if (this.user_isAuth && routeInfo_to_intended.routeDef.type_isPublicLogin)
			{
				B_REST_Utils.console_warn(`Already logged and trying to go back to login page, so redirecting to auth users default route`);
				
				routeDefName_to_proposedRedirection = this._abstract_routes_authDefaultRouteName();
			}
			//If routeInfo_to_intended.isKnown and we've got perms
			else if (await this.routes_hasPerms(routeInfo_to_intended)) { routeInfo_to_proposedAction=true; }
			else
			{
				B_REST_Utils.console_warn(`Got no perms for "${fullPath_to_intended}", so redirecting to a 403 page or just prevent nav`);
				
				/*
				NOTE:
					If in server we HttpUtils::die_res_perms() for an API call we're not the right user type etc, we'll correctly catch ex when go in a list or existing form, but not for new form.
						Ex:
							/someConfigMenu/    -> dies while doing "GET /someStuff/"
							/someConfigMenu/123 -> dies while doing "GET /someStuff/123"
							/someConfigMenu/*   -> doesn't die because no API call is made
						To make sure we die, must handle manually here in _abstract_routes_hasPerms()
				WARNING: Logic must be consistent between B_REST_App_base::reboot(), B_REST_App_base::_routes_beforeNavigationChange() & BrErrorPage403::leave-track-intended-route
				*/
				if      (this.user_isPublic && this.routeDefs_login)    { routeDefName_to_proposedRedirection=B_REST_App_RouteDef_base.NAME_LOGIN;    leaveTrackIntendedRoute=true; }
				else if (this.user_isPublic && this.routeDefs_landpage) { routeDefName_to_proposedRedirection=B_REST_App_RouteDef_base.NAME_LANDPAGE; leaveTrackIntendedRoute=true; }
				else if (this.routeDefs_403)                            { routeDefName_to_proposedRedirection=B_REST_App_RouteDef_base.NAME_403; }
				else                                                    { routeInfo_to_proposedAction        =false;                             } //Likely to throw later
			}
			
			if (routeDefName_to_proposedRedirection)
			{
				const qsa = {};
				
				/*
				Previous intended full path is accessible via B_REST_App_base::routes_current_qsa_intendedFullPath()
				NOTE:
					-If ever we want to save that for more than just 404s, could add an abstract func to ret if we should append or not
					-If we care about tracing back where we intended to go, do something to avoid stacking up like "?_i=...%10_i%10...%10_i%10...%10_i%10"
				*/
				if (leaveTrackIntendedRoute || [B_REST_App_RouteDef_base.NAME_403,B_REST_App_RouteDef_base.NAME_404].includes(routeDefName_to_proposedRedirection))
				{
					const intendedFullPath_cleaned = B_REST_Utils.url_removeQSA(fullPath_to_intended, B_REST_App_base.ROUTES_QSA_INTENDED_FULL_PATH); //Change "/previous-route?_i=somethingElse&a=2#hash" to "/previous-route?a=2#hash"
					
					qsa[B_REST_App_base.ROUTES_QSA_INTENDED_FULL_PATH] = intendedFullPath_cleaned;
				}
				
				routeInfo_to_proposedAction = this.routeDefs_get(routeDefName_to_proposedRedirection).toRouteInfo(/*pathVars*/{}, qsa);
			}
			
			const proposedAction_isConfirming = routeInfo_to_proposedAction===true;
			const proposedAction_isRejecting  = routeInfo_to_proposedAction===false;
			
			//Important that this handles perms errs w valid redirections, or a core err will be triggered later
			const routeInfo_to_finalAction = await this._abstract_routes_beforeNavigationChange(routeInfo_to_proposedAction, routeInfo_to_intended, routeInfo_from); //Rets bool or B_REST_App_RouteInfo_base der
			const finalAction_isConfirming = routeInfo_to_finalAction===true;
			const finalAction_isRejecting  = routeInfo_to_finalAction===false;
			
			//User says to go nowhere, or confirms it's ok to go nowhere
			if (finalAction_isRejecting || (proposedAction_isRejecting&&finalAction_isConfirming))
			{
				if (!routeInfo_from)                             { this.throwEx(`Was prolly in boot call with no origin and we're indicating to not navigate nowhere`); }
				if (!await this.routes_hasPerms(routeInfo_from)) { this.throwEx(`Ending up staying somewhere we have no more perms; _abstract_routes_beforeNavigationChange() user code should handle that so it doesn't happen`,routeInfo_from); }
				
				return false;
			}
			
			let routeInfo_to_final = null; //Instance of B_REST_App_RouteInfo_base
			
			if      (finalAction_isConfirming)                                      { routeInfo_to_final = proposedAction_isConfirming?routeInfo_to_intended:routeInfo_to_proposedAction; } //User says it's ok to proceed w proposed action
			else if (routeInfo_to_finalAction instanceof B_REST_App_RouteInfo_base) { routeInfo_to_final = routeInfo_to_finalAction;                                                      } //User says to go somewhere else
			else { this.throwEx(`Expected bool / B_REST_App_RouteInfo_base der`,routeInfo_to_finalAction); }
			
			//Validate one last time that it makes sense to go there. Otherwise it's a core err that _abstract_routes_beforeNavigationChange() should have handled
			if (!await this.routes_hasPerms(routeInfo_to_final)) { this.throwEx(`Ending up somewhere we have no perms; _abstract_routes_beforeNavigationChange() user code should handle that so it doesn't happen`,routeInfo_to_final); }
			
			//NOTE: The following 2 could throw + we must run these even if !intendedRouteChanged, because we're still going to a new place when we do next(undefined -> confirm)
			this._routes_updateCurrentTravelDirection(  routeInfo_to_final, routeInfo_from);
			this._abstract_routes_afterNavigationChange(routeInfo_to_final, routeInfo_from);
			
			//Check this func's docs for why we ret true when proposed = intended
			return routeInfo_to_final.fullPath===fullPath_to_intended ? true : routeInfo_to_final;
		}
			/*
			Called between each navigation (even at boot), to allow:
				-Confirming we can proceed on that route (validating perms)
				-Redirecting to another route (using B_REST_App_RouteDef_dev::toRouteInfo())
				-Deciding to redirect to a login route instead of a landpage
				-Allocating / releasing data etc
			routeInfo_to_proposedAction:
				true                      -> Saying it's ok to go to routeInfo_to_intended, but that we could do otherwise
				false                     -> Saying it's not ok to go to routeInfo_to_intended, and it doesn't know what to do instead
				B_REST_App_RouteInfo_base -> Saying it's not ok to go to routeInfo_to_intended, and that we should go to that route instead (ex login, a 404, 403...)
			Must ret either of:
				true                      -> OK to continue w proposed action
				false                     -> Don't go nowhere
				B_REST_App_RouteInfo_base -> Redirect somewhere else
			Receives instances of B_REST_App_RouteInfo_base der
			Usage ex:
				if (routeInfo_to_proposedAction.isRoot)    { ... }
				if (routeInfo_to_proposedAction.isUnknown) { ... }
				return this.routeDefs_get("clients").toRouteInfo({pk:123})
				return this.routeDefs_login.toRouteInfo({pk:123})
			NOTE:
				-On boot, routeInfo_from is NULL
			IMPORTANT:
				-User must validate perms with "await routes_hasPerms()" and redirect appropriately, otherwise will gen a core err
					ex we could ret false, so we would stay on the prev route, but it's possible we no longer have perms for that route
				-Check routes_hasPerms() docs
			*/
			async _abstract_routes_beforeNavigationChange(routeInfo_to_proposedAction, routeInfo_to_intended, routeInfo_from=null) { this._throwEx_abstractMissing(); }
		//Call this at a successful route change. Ex to help figuring UI horizontal transitions between screens
		_routes_updateCurrentTravelDirection(routeInfo_to, routeInfo_from=null)
		{
			const name_from = routeInfo_from?.routeDef?.name ?? null;
			const name_to   = routeInfo_to.routeDef?.name    ?? null;
			let   travelDir = B_REST_App_base.ROUTES_TRAVEL_DIR_UNRELATED;
			
			if (name_from && name_to)
			{
				//If we nav between /a/b/c/d and /a/b, but not from /a/b to /a/c
				if (routeInfo_from.depth!==routeInfo_to.depth)
				{
					const path_from = routeInfo_from.path;
					const path_to   = routeInfo_to.path;
					
					if      (path_from.indexOf(path_to)===0) { travelDir=B_REST_App_base.ROUTES_TRAVEL_DIR_TO_PARENT; }
					else if (path_to.indexOf(path_from)===0) { travelDir=B_REST_App_base.ROUTES_TRAVEL_DIR_TO_CHILD;  }
				}
			}
			
			//NOTE: Before, we used to compare solely on "<moduleName>-list" vs "<moduleName>-form"
			
			//We've already decided what is the best thing to do, but allow changing that
			this._routes_current_travelDir = this._abstract_routes_evalTravelDirection(travelDir,routeInfo_to,routeInfo_from);
		}
			//Must either just ret travelDir as is, or override & ret another const of ROUTES_TRAVEL_DIR_x
			_abstract_routes_evalTravelDirection(travelDir, routeInfo_to, routeInfo_from=null) { this._throwEx_abstractMissing(); }
		//To allow doing stuff after a successful navigation change, ex if we want to deal with breadcrumbs, travel history, etc
		_abstract_routes_afterNavigationChange(routeInfo_to, routeInfo_from=null) { this._throwEx_abstractMissing(); }
	
	
	
	//NOTIFS RELATED - To eventually plug w the unused B_REST_App_Notif.js
		_notifs_setupListener()
		{
			//TODO - Call server to get new notifs each X secs. Hook to <br-toaster-manager>
		}
		_notifs_appendFromAPICall(notifs)
		{
			//TODO - Check B_REST_App_Notif.js. Hook to <br-toaster-manager>
		}
		_notifs_actionHook(notif)
		{
			//TODO - Maybe tell server we've done an action or just dismissed it
		}
		_notifs_ls_x()
		{
			//TODO - Check to work with LS, and when we user_createFromObj() or kick out, we should make sure we don't see someone else's stuff. Use LS or local DB ?
		}
		//Helpers
		notifs_tmp({msg,color})
		{
			//NOTE: Used in some places
			
			this._abstract_notifs_tmp(msg, color);
		}
		notifs_error_generic()
		{
			this._abstract_notifs_tmp(this.t_core("app.tmpGenericMsgs.errorMsg"), "error");
		}
		notifs_error_locPath(custom_locPath, details={})         { B_REST_Utils.console_warn(`TODO refactor notifs_error_locPath("${custom_locPath}")`);         } //Red "An error happened while doing XYZ"
		notifs_saved_success_generic()                           { B_REST_Utils.console_warn(`TODO refactor notifs_saved_success_generic()`);                    } //Green "record saved"
		notifs_saved_success_locPath(custom_locPath, details={}) { B_REST_Utils.console_warn(`TODO refactor notifs_saved_success_locPath("${custom_locPath}")`); } //Green "Client #3 saved"
		notifs_saved_failure_generic()                           { B_REST_Utils.console_warn(`TODO refactor notifs_saved_failure_generic()`);                    } //Red "record couldn't be saved"
		notifs_saved_failure_locPath(custom_locPath, details={}) { B_REST_Utils.console_warn(`TODO refactor notifs_saved_failure_locPath("${custom_locPath}")`); } //Red "Client #3 couldn't be saved"	
};
