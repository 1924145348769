
import B_REST_Utils                from "../../classes/B_REST_Utils.js";
import B_REST_App_base             from "../../classes/app/B_REST_App_base.js";
import B_REST_VueApp_RouteDef      from "./B_REST_VueApp_RouteDef.js";
import B_REST_VueApp_RouteInfo     from "./B_REST_VueApp_RouteInfo.js";
import B_REST_Vuetify_PickerDef    from "./vuetifyComponents/picker/B_REST_Vuetify_PickerDef.js";
import B_REST_Vuetify_PickerHandle from "./vuetifyComponents/picker/B_REST_Vuetify_PickerHandle.js";
import B_REST_Vuetify_RightDrawer  from "./vuetifyComponents/rightDrawer/B_REST_Vuetify_RightDrawer.js";
import { B_REST_Vuetify_Prompt, B_REST_Vuetify_Prompt_Action               } from "./vuetifyComponents/prompt/B_REST_Vuetify_Prompt.js";
import { B_REST_Vuetify_GenericListBase_isDerivedComponentImport           } from "./vuetifyComponents/genericModules/list/BrGenericListBase.vue";
import { B_REST_Vuetify_GenericFormBase_isDerivedComponentImport           } from "./vuetifyComponents/genericModules/form/BrGenericFormBase.vue";
import { B_REST_Vuetify_ToasterManager, B_REST_Vuetify_ToasterManager_Item } from "./vuetifyComponents/toasterManager/B_REST_Vuetify_ToasterManager.js";

import Vue from "vue";

import Router from "vue-router";
Vue.use(Router);

//Vuetify related; could remove all this if we don't want to use that framework anymore
	import WebFontLoader from "webfontloader";
	WebFontLoader.load({
		google: {families: ["Roboto:100,300,400,500,700,900&display=swap"]},
	});
	import Vuetify, {VBtn, VTextField } from "vuetify/lib";
	import vuetify_lang_fr from "vuetify/es5/locale/fr";
	import vuetify_lang_en from "vuetify/es5/locale/en";
	import vuetify_lang_es from "vuetify/es5/locale/es";
	Vue.use(Vuetify, {
		components: {VBtn,VTextField} // Global stuff, only required if we do <component :is="..."> and stuff (?)
	});
	
	import "@/bREST/core/implementations/vue/vuetifyComponents/_autoload.js"; //NOTE: Req in <br-app-booter>

//Portal-vue related - wouldn't be req if we would use Vue 3's <teleport> instead
	import PortalVue from 'portal-vue';
	Vue.use(PortalVue, {
		portalName:       'portal-vue-item',
		portalTargetName: 'portal-vue-dest',
	});






import node_modules_vueRouter_pathToRegexp_tmpHack from "./node_modules_vueRouter3.2_tmpHack.js"; //Check B_REST_VueApp_base::_routes_updateAll_forLang()






export default class B_REST_VueApp_base extends B_REST_App_base
{
	static get VUETIFY_ANIMATION_DURATION() { return 300; } //As per dev tools animation pane + Vuetify src code
	
	
	_appDOMSelector                         = null;   //Ex "#app"
	_appComponent                           = null;   //Ex ()=>import("@/App.vue"). IMPORTANT: Must contain a <br-app-booter>
	_vue                                    = null;   //Vue instance
	_router                                 = null;   //Vue Router instance
	_vuetify                                = null;   //Vuetify instance
	_routes_define_x_currentLayoutComponent = null;   //Used only in constructor, for _routes_define_x()
	_globalCSSVars                          = null;   //To be used at the topmost place we can put in our _appComponent / BrAppBooter.vue, in a style prop like "<div :style='$bREST.globalCSSVars'>", so we can do "var(--b-rest-xxx)" everywhere in CSS
	_brFieldDbAttrs                         = null;   //Extra obj of attrs to pass to <br-field-db>, ex {outlined:true, rounded:true, dense:true}
	_brFieldFileAttrs                       = null;   //Like brFieldDbAttrs, but for <br-field-file>
	_pickers_defs                           = {};     //Map of pickerName => B_REST_Vuetify_PickerDef
	_pickers_handles                        = [];     //Arr of B_REST_Vuetify_PickerHandle instances
	_prompts                                = [];     //Helper arr of B_REST_Vuetify_Prompt instances. Check prompt_helper_x() docs
	_toasterManager                         = null;   //Instance of B_REST_Vuetify_ToasterManager
	
	//Vars to prevent bugs in helper_router_silentRouteUpdate(), routes_transferComponentPropsToVueRouterObj_forRouteDef() & transferComponentProps_forRouteInfo(). Check their docs
	_routerHack_isIgnoringReplaceEvents                                = false;
	_routerHack_transferComponentPropsToVueRouterObj_last_vueRouterObj = null;
	_routerHack_transferComponentPropsToVueRouterObj_last_output       = null;
	_routerHack_isCurrentRouteMainComponent_watchHack                  = 0;
					
	
	
	constructor(options={})
	{
		super(options); //Throws if singleton already instantiated. Will also have loaded stuff from local storage via session_load_general()
		
		options = B_REST_Utils.object_hasValidStruct_assert(options, {
			appComponent:        {accept:[Object],  required:true},  //Ex ()=>import("@/App.vue")
			appDOMSelector:      {accept:[String],  default:"#app"}, //Ex "#app"
			globalCSSVars:       {accept:[Object],  required:true},  //Check docs below
			brFieldDbAttrs:      {accept:[Object],  required:true},  //Extra obj of attrs to pass to <br-field-db>, ex {outlined:true, rounded:true, dense:true}
			brFieldFileAttrs:    {accept:[Object],  required:true},  //Like brFieldDbAttrs, but for <br-field-file>
			vuetifyThemeOptions: {accept:[Object],  required:true},  //Ex {dark, themes:{light:{primary,secondary}, ...}}
			pickerDefs:          {accept:[Object],  required:true},  //Check docs below
		}, "Vue app");
		
		this._appComponent     = options.appComponent;
		this._appDOMSelector   = options.appDOMSelector;
		this._brFieldDbAttrs   = options.brFieldDbAttrs;
		this._brFieldFileAttrs = options.brFieldFileAttrs;
		
		//For CSS vars, we need to define core ones, but we can add more to reuse elsewhere. We could also define a global css file and define them there too
		this._globalCSSVars = B_REST_Utils.object_hasValidStruct_assert(options.globalCSSVars, {
			"--bREST-BrFieldDb_isDirty_color": {accept:[String], required:true}, //Ex "red"
		}, "Global CSS vars");
		
		//Setup picker defs
		for (const loop_pickerDefName in options.pickerDefs)
		{
			if (this._pickers_defs[loop_pickerDefName]) { this.throwEx(`Picker "${loop_pickerDefName}" already defined`); }
			
			const loop_pickerDefOptions = options.pickerDefs[loop_pickerDefName];
			
			this._pickers_defs[loop_pickerDefName] = new B_REST_Vuetify_PickerDef(loop_pickerDefName, loop_pickerDefOptions);
		}
		
		//Setup Vue router
		{
			this._router = new Router({
				mode: "history",
				base: process.env.BASE_URL,
				scrollBehavior: (to, from, savedPosition) =>
				{
					if (this._routerHack_isIgnoringReplaceEvents) { return false; }
					
					if (to.hash)       { return {selector:to.hash}; }
					if (savedPosition) { return savedPosition;      }
					
					return {x:0, y:0};
				},
				routes: [], //Start empty; check _routes_updateAll_forLang() for why
			});
			
			this._routes_setupBeforeAfterEachHook();
			
			this._routes_updateAll_forLang(this._locale_lang);
		}
		
		//Vuetify related; could remove all this if we don't want to use that framework anymore
		this._vuetify = new Vuetify({
			lang: {
				locales: {fr:vuetify_lang_fr, en:vuetify_lang_en, es:vuetify_lang_es},
				current: this._locale_lang,
			},
			breakpoint: {
				mobileBreakpoint: "sm", //By default it's "md" ?! https://vuetifyjs.com/en/features/breakpoints/#mobile-breakpoints
			},
			theme: options.vuetifyThemeOptions,
		});
		
		//Setup toaster manager
		{
			this._toasterManager = new B_REST_Vuetify_ToasterManager(/*alsoLogToConsole*/false);
		}
		
		//Start Vue
		{
			Vue.config.productionTip = false; //If we want to output a console.log() when we boot, about dev vs build mode. IMPORTANT: Leave false, for B_REST_Utils::console_log_disable()
			
			/*
			Catches wrong templates + unhandled exceptions. Trace is a component stack string and vm is the component's obj
			WARNING: It's possible that if we put to prod, the stack trace won't be able to tell the real component names anymore
			NOTE:
				We also have errorHandler(err,vm,info), but it doesn't tell from which component the err comes,
				so it's better to use the warnHandler alone, which catches the errs anyways
					Vue.config.errorHandler = (err,vm,info) => { this.throwEx(`Vue caught a component error:\n${err.toString()}`,{vm,info}); };
			*/
			Vue.config.warnHandler = (msg,vm,trace) =>
			{
				if (vm && B_REST_Utils.flags_onErr_overlayDomTree)
				{
					const domElem = vm.$el ?? vm.$parent.$el;
					
					//NOTE: Sometimes we get a "#comment" instead of a normal element, and it doesn't have get/setAttribute()
					if (domElem?.getAttribute)
					{
						let domElemStyle = domElem.getAttribute("style") ?? "";
						domElemStyle += `; box-shadow:0 0 0 99999px rgba(58,5,5,0.8) !important; border-width:10px !important; border-style:double !important; border-color:red !important;`;
						domElem.setAttribute("style", domElemStyle);
					}
				}
				
				/*
				WARNING:
					Don't put out of setTimeout, because of a glitch in Vue:
					If a component can't render AT ALL ex because of missing computed / methods / other undefined stuff,
					then its "vm._update(vm._render())" will throw and Vue.config.warnHandler() will catch it.
					However if before warnHandler finishes we take ANY arr in B_REST_App_base and do a push() on it,
					it'll cause "vm._update(vm._render())" to regen (?) the component and we'll get an infinite loop.
					Happens because we use toaster manager here, which does a push on an internal arr.
						-> Happens even if we comment out the above adding of box shadow when flags_onErr_overlayDomTree
				*/
					setTimeout(() =>
					{
						//WARNING: The following must never throw, or we'll get an infinite loop
						
						B_REST_Utils.console_error(`Vue caught a component warning:\n${msg}${trace}`, vm);
						this.notifs_error_generic();
					}, 0);
			};
			
			this._vue = new Vue({
				router:  this._router,
				vuetify: this._vuetify,
				render:  h => h(this._appComponent),
			});
		}
		
		/*
		Vue will be accessible now, but app won't be officially ready until we boot_await() it (to fetch model defs, logged user etc)
		But first, derived must call _constructor_mountVue_install_$bREST() at the end of its constructor to prevent hell
		*/
	}
		/*
		Prepares a shortcut to be able to use it in Vue components this way:
			this.$bREST.xxx
		Instead of having to do:
			import { B_REST_App_base } from "@/bREST/core/classes";
			B_REST_App_base.instance.xxx
		Note that we can also refer to it globally via window.bRESTApp
		IMPORTANT:
			Must do so, if we want to use singleton in computed stuff etc, as by default, Vue doesn't support reactivity for:
				-Static accessors dealing with static class vars
				-External stuff used in computed / methods, that's not directly set to a props or in data()
			Once plugin is installed, we can then use the static funcs just fine too (don't need to refer via singleton by ourselves)
			Must be called at the end of derived constructor. Don't put this in B_REST_VueApp_base::constructor, or any prop we define in derived class won't be reactive
		*/
		_constructor_mountVue_install_$bREST()
		{
			Vue.use({
				install: (Vue,options) => { Vue.prototype.$bREST=Vue.observable(this); },
			}, {}); //NOTE: If we add stuff in the {}, we can access "options.xxx" in the global Vue.$bREST
			
			this._vue.$mount(this._appDOMSelector); //Only do this once $bREST plugin is installed
		}
			async _abstract_boot_await_framework_beforeAPICall() { }
			async _abstract_boot_await_framework_afterAPICall()  { }
	
	
	
	get appComponent()                       { return this._appComponent;                       }
	get appDOMSelector()                     { return this._appDOMSelector;                     }
	get vue()                                { return this._vue;                                }
	get router()                             { return this._router;                             }
	get vuetify_instance()                   { return this._vuetify?.framework ?? null;         }
	get globalCSSVars()                      { return this._globalCSSVars;                      }
	get brFieldDbAttrs()                     { return this._brFieldDbAttrs;                     }
	get brFieldFileAttrs()                   { return this._brFieldFileAttrs;                   }
	get routerHack_isIgnoringReplaceEvents() { return this._routerHack_isIgnoringReplaceEvents; }
	
	
	
	_abstract_onLangChange(oldLang, newLang)
	{
		if (this.vuetify_instance) { this.vuetify_instance.lang.current=newLang; }
		
		if (this._router)
		{
			this._routes_updateAll_forLang(newLang, oldLang);
			B_REST_Utils.console_warn(`Changing Vue Router route defs from ${oldLang} -> ${newLang}`);
			
			/*
			Check if the current route's lang doesn't match the new lang, and if so, redirect to its matching URL in the other lang, for the same route def
			NOTE:
				We could also do special things against a combination of boot_isBooting & _boot_langMismatch_notYetUsed
			*/
			{
				const routeLang = this.routes_current_info.lang; //NOTE: Yields NULL when multiple langs have the same URL
				
				if (routeLang && routeLang!==this._locale_lang)
				{
					const newLangFullPath = this.routes_current_info.toOtherLang_fullPath(this._locale_lang);
					B_REST_Utils.console_warn(`Was on "${this._routes_current_info.fullPath}" and not matching new lang "${this._locale_lang}", so redirecting to "${newLangFullPath}"`);
					
					/*
					WARNING:
						Don't do _abstract_routes_go_x_replace_path(newLangFullPath), or if we were in a UserForm<BrGenericFormBase> for the current user,
						we'd get parallel code prob in BrGenericFormBase::_awaitUnsavedChangesSaved() where we do the following:
							const savedSomething = await this.model.awaitUnsavedChangesSaved(options); //Throws on err
							this.model.userTouch_toggleAllFields(false);
								-> Prob: this.model is now temporarily NULL
						because here _abstract_routes_go_x_replace_path() would cause the route to change and BrGenericFormBase's XXX->_fromX_setLoadModel watches to re-fire,
						and at the beginning we do this.model=null & await this.model = <load model>
						So while that func is awaiting, _awaitUnsavedChangesSaved() would continue w a temporarily NULL this.model
					*/
					this.routes_silentRouteUpdate(newLangFullPath);
				}
			}
		}
	}
	
	
	
	//VUE & VUETIFY RELATED
		get uiBreakpoint()    { return this.vuetify_instance.breakpoint;               } //Gives access to {width,height, smAndUp,smAndDown, lgAndUp,lgAndDown, xsOnly,xlOnly, xs,sm,md,lg,xl, scrollBarWidth, thresholds, ...}
		get uiTheme()         { return this.vuetify_instance.light ? "light" : "dark"; }
		get uiTheme_isLight() { return this.vuetify_instance.light;                    }
		get uiTheme_isDark()  { return this.vuetify_instance.dark;                     }
		set uiTheme(val)      { this.vuetify_instance.theme.dark = val==="dark";       }
		
		/*
		Usage ex:
			uiBreakpoint_isXAndDown("sm")
				-> Rets true if is xs or sm
		*/
		uiBreakpoint_isXAndDown(which)
		{
			switch (which)
			{
				case "xs": return this.uiBreakpoint.xs;
				case "sm": return this.uiBreakpoint.smAndDown;
				case "md": return this.uiBreakpoint.mdAndDown;
				case "lg": return this.uiBreakpoint.lgAndDown;
				case "xl": return true;
				default:   this.throwEx(`Unknown breakpoint name "${which}"`);
			}
		}
		/*
		Usage ex:
			uiBreakpoint_isXAndUp("lg")
				-> Rets true if is lg or xl
		*/
		uiBreakpoint_isXAndUp(which)
		{
			switch (which)
			{
				case "xs": return true;
				case "sm": return this.uiBreakpoint.smAndUp;
				case "md": return this.uiBreakpoint.mdAndUp;
				case "lg": return this.uiBreakpoint.lgAndUp;
				case "xl": return this.uiBreakpoint.xl;
				default:   this.throwEx(`Unknown breakpoint name "${which}"`);
			}
		}
		
		//NOTE: We also have native DOMElem.scrollTo(), DOMElem.scrollBy() etc
		scrollTo_offset(offset,duration=400,easing="easeInOutCubic") { this.vuetify_instance.goTo(offset,                    {duration,easing}); }
		scrollTo_top(          duration=400,easing="easeInOutCubic") { this.vuetify_instance.goTo(0,                         {duration,easing}); }
		scrollTo_bottom(       duration=400,easing="easeInOutCubic") { this.vuetify_instance.goTo(document.body.scrollHeight,{duration,easing}); }
		//Can be a Vue component or DOM elem. WARNING: Doesn't work if it's to scroll in a v-dialog; use scrollTo_withinVDialog()
		scrollTo_selector(selector, offset=0, duration=400, easing="easeInOutCubic") { this.vuetify_instance.goTo(selector,{duration,easing,offset}); }
		//Like scrollTo_selector(), but works to bring stuff visible in a v-dialog, so even if it's not on the main flow of the page
		scrollTo_native(vueComponentOrDOMElem, isAnimated=true, verticalAlign="start", horizontalAlign="nearest")
		{
			if (!B_REST_Utils.dom_is(vueComponentOrDOMElem))
			{
				vueComponentOrDOMElem = vueComponentOrDOMElem?.$el ?? B_REST_Utils.throwEx(`Expected DOM elem or Vue component`,vueComponentOrDOMElem);
			}
			
			vueComponentOrDOMElem.scrollIntoView({
				behavior: isAnimated?"smooth":"instant",
				block:    verticalAlign,   //One of {start|center|end|nearest}
				inline:   horizontalAlign, //One of {start|center|end|nearest}
			});
		}
			//Alias to make it clearer
			scrollTo_withinVDialog(vueComponentOrDOMElem, isAnimated=true, verticalAlign="start", horizontalAlign="nearest") { this.scrollTo_native(vueComponentOrDOMElem,isAnimated,verticalAlign,horizontalAlign); }
		
		//Leaves the DOM tree as-is and stops reactivity
		_vue_destroy()
		{
			this._vue.$destroy(); //NOTE: In Vue3, it's $unmount() instead of $destroy()
		}
	
	
	
	//ROUTES RELATED
		/*
		WARNING:
			Unsure code:
				-We're using an old V of Vue Router which doesn't have .removeRoute() yet
				-If we boot as FR from LS but we wrote a EN route, if app only loads URLs in 1 lang at a time, we should remove all routes & put the new ones right after
				-An alt to removeRoute() is to just change routes' {path, regex, matchAs} props to match the new lang
					+ 404 routes need to define a "*" alias, so 99% routes have matchAs=undefined, otherwise we have to change "/someRoute/*" into "/someRoute/"
				-We mustn't fuck when we start on a EN route w a FR user, then ex do routes_go_moduleForm_pkTag(), end on a FR page, then do history.back(), and throw because the EN route no longer exists
				-If we start by just loading all in all langs:
					-Will get no prob when navigating forward / backward
					-Will get tons of warning about routes w duplicate names
				-If we always use B_REST_App_base::routes_go_x() funcs and never implement <router-link to="routeName" /> or other Vuetify variations,
					then we don't really need route names, or we could prefix them like "<lang>-<moduleName>-form". Just be careful if path is the same in all 3 langs (ex "/")
		NOTE:
			For now, we include a tmp node_modules_vueRouter3.2_tmpHack.js for node_modules_vueRouter_pathToRegexp_tmpHack()
		*/
		static get TMP_ROUTES_BOOT_LOAD_ALL_LANGS() { return false;    }
		static get TMP_ROUTES_ON_SWITCH_BEHAVIOR()  { return "update"; } //Either update | add | remove
		_routes_updateAll_forLang(lang, hint_oldLang=null)
		{
			const isBoot = hint_oldLang===null;
			
			//For now, at load we only add for 1 lang and not all
			if (isBoot)
			{
				const langsToLoad = B_REST_VueApp_base.TMP_ROUTES_BOOT_LOAD_ALL_LANGS ? this._appLangs : [lang];
				
				for (const loop_lang of langsToLoad) { this._routes_updateAll_forLang_addOneLang(loop_lang); }
			}
			else
			{
				B_REST_Utils.console_warn(`Doing unsure code; check B_REST_VueApp_base::_routes_updateAll_forLang()`);
				
				switch (B_REST_VueApp_base.TMP_ROUTES_ON_SWITCH_BEHAVIOR)
				{
					//If we should just update {path, regex, matchAs} for all routes (not good ex if we do back navigation)
					case "update":
						for (const loop_vueRouterObj of this._router.getRoutes())
						{
							const loop_bREST_routeDef = B_REST_VueApp_RouteDef.getRouteDefFromVueRouterObj(loop_vueRouterObj) || this.throwEx(`Couldn't find a bREST_routeDef meta in the Vue Router route obj`,loop_vueRouterObj);
							const loop_newPath        = loop_bREST_routeDef.langUrls[lang];
							
							loop_vueRouterObj.path    = loop_newPath;
							loop_vueRouterObj.regex   = node_modules_vueRouter_pathToRegexp_tmpHack(loop_newPath, [], {sensitive:true});
							loop_vueRouterObj.matchAs = loop_vueRouterObj.matchAs ? loop_newPath.replace("*","") : undefined;
							
							//Extra hell check
							if (loop_vueRouterObj.matchAs && !loop_bREST_routeDef.type_isPublic404) { this.throwEx(`Expected only 404 routes to have a .matchAll prop`,loop_vueRouterObj); }
						}
					break;
					//If it wasn't added yet
					case "add":
						if (this._routes_tmp_definedLangs.includes(lang)) { return; }
						
						this._routes_updateAll_forLang_addOneLang(lang);
					break;
					//Rem old, then add new
					case "remove":
						if (hint_oldLang)
						{
							//WARNING: Will break in Vue Router <4
							for (const loop_routeDefName in this._routeDefs) { this._router.removeRoute(this._routeDefs[loop_routeDefName].name); }
							B_REST_Utils.array_remove_byVal(this._routes_tmp_definedLangs);
						}
						this._routes_updateAll_forLang_addOneLang(lang);
					break;
					default:
						this.throwEx(`Got unhandled behavior "${B_REST_VueApp_base.TMP_ROUTES_ON_SWITCH_BEHAVIOR}"`);
					break;
				}
			}
		}
			_routes_tmp_definedLangs = []; //Arr of langs
			_routes_updateAll_forLang_addOneLang(lang)
			{
				this._routes_tmp_definedLangs.push(lang);
				
				for (const loop_routeDefName in this._routeDefs)
				{
					const loop_routeDef = this._routeDefs[loop_routeDefName];
					const loop_vueRouterObj = loop_routeDef.convertToVueRouteDefObj(lang);
					
					this._router.addRoute(loop_vueRouterObj);
				}
			}
		/*
		Helper funcs for definining routes, to use only while in _abstract_routes_defineRoutes()
		For modules with a list/form, where users need to be authenticated (so we have access token, etc), use _routes_define_genericListFormModule()
		Usage ex:
			import PublicLayoutComponent from "./layouts/PublicLayout.vue";
			import MainLayoutComponent   from "./layouts/MainLayout.vue";
			_abstract_routes_defineRoutes()
			{
				this._routes_define_x_setCurrentLayoutComponent(PublicLayoutComponent);
					this._routes_define_landpage(            {fr:"/fr/",      en:"/en/"},       ()=>import("./routerViews/Landpage.vue"));
					this._routes_define_login(               {fr:"/logine/",  en:"/login/"},    ()=>import("./routerViews/Login.vue"));
					this._routes_define_404(                 {fr:"/tombe/",   en:"/fallback/"}, ()=>import("./routerViews/Fallback.vue"));
					this._routes_define_403(                 {fr:"/perms/",   en:"/perms/"},    ()=>import("./routerViews/Perms.vue"));
					this._routes_define_public("publicTest", {fr:"/publique/",en:"/public/"},   ()=>import("./routerViews/Public.vue"));
				
				this._routes_define_x_setCurrentLayoutComponent(MainLayoutComponent);
					this._routes_define_genericListFormModule("citizen",      {fr:"/citoyens/",     en:"/en/citizens/"});
					this._routes_define_genericListFormModule("intervention", {fr:"/interventions/",en:"/en/interventions/"});
			}
		NOTE:
			If route is the same in all langs, instead of passing an obj like {fr,en} for langUrls, we can just pass a single string
		IMPORTANT:
			Must call _routes_define_x_setCurrentLayoutComponent() at least once at the beginning, otherwise we can't group the routes under a given layout
			We expect to end up with the following template:
				<BrAppBooter>
					<router-view />
						<SomeLayoutComponent>
							<component :is="routeTransition_componentName" mode="out-in" origin="">
								<router-view />
									<Forms etc>
		*/
			_routes_define_x_setCurrentLayoutComponent(layoutComponent)
			{
				this._routes_define_x_assertCan();
				this._routes_define_x_currentLayoutComponent = layoutComponent;
			}
				_routes_define_x_assertLayoutComponent() { if(!this._routes_define_x_currentLayoutComponent){this.throwEx(`Must call _routes_define_x_setCurrentLayoutComponent() first`);} }
			//Since in _routes_define_x() we accept either objs like {fr,en,es} and single strings, always convert to obj to KISS
			_routes_define_langUrlsToObj(langUrlsOrSingleString)
			{
				if (B_REST_Utils.object_is(langUrlsOrSingleString)) { return langUrlsOrSingleString; }
				if (B_REST_Utils.string_is(langUrlsOrSingleString))
				{
					const langUrls = {};
					for (const loop_langUrl of this._appLangs) { langUrls[loop_langUrl]=langUrlsOrSingleString; }
					return langUrls;
				}
				
				B_REST_VueApp_base.throwEx(`lang urls must be an obj or single string`);
			}
			//To make generic auth routes (get kicked out when we're not auth). Check B_REST_VueApp_RouteDef docs for info on all params
			_routes_define_auth(name, langUrlsOrSingleString, viewComponent, meta={}, transferComponentProps_forRouteInfo=undefined, type=B_REST_VueApp_RouteDef.TYPE_AUTH_MISC)
			{
				this._routes_define_x_assertLayoutComponent();
					
				this._routes_define(new B_REST_VueApp_RouteDef({
					name,
					type,
					langUrls: this._routes_define_langUrlsToObj(langUrlsOrSingleString),
					viewComponent,
					meta,
					transferComponentProps_forRouteInfo,
					needsAuth: true,
					layoutComponent: this._routes_define_x_currentLayoutComponent,
				}));
			}
			//To make generic public routes (get moved away when we're already auth). Check B_REST_VueApp_RouteDef docs for info on all params
			_routes_define_public(name, langUrlsOrSingleString, viewComponent, meta={}, transferComponentProps_forRouteInfo=undefined, type=B_REST_VueApp_RouteDef.TYPE_PUBLIC_MISC)
			{
				this._routes_define_x_assertLayoutComponent();
				
				this._routes_define(new B_REST_VueApp_RouteDef({
					name,
					type,
					layoutComponent: this._routes_define_x_currentLayoutComponent,
					viewComponent,
					langUrls: this._routes_define_langUrlsToObj(langUrlsOrSingleString),
					meta,
					transferComponentProps_forRouteInfo,
					needsAuth: false
				}));
			}
				//Helpers
				_routes_define_landpage(langUrlsOrSingleString, viewComponent, meta={}, transferComponentProps_forRouteInfo=undefined) { this._routes_define_public(B_REST_VueApp_RouteDef.NAME_LANDPAGE, langUrlsOrSingleString,viewComponent,meta,transferComponentProps_forRouteInfo,B_REST_VueApp_RouteDef.TYPE_PUBLIC_LANDPAGE);  }
				_routes_define_login(   langUrlsOrSingleString, viewComponent, meta={}, transferComponentProps_forRouteInfo=undefined) { this._routes_define_public(B_REST_VueApp_RouteDef.NAME_LOGIN,    langUrlsOrSingleString,viewComponent,meta,transferComponentProps_forRouteInfo,B_REST_VueApp_RouteDef.TYPE_PUBLIC_LOGIN);     }
				_routes_define_resetPwd(langUrlsOrSingleString, viewComponent, meta={}, transferComponentProps_forRouteInfo=undefined) { this._routes_define_public(B_REST_VueApp_RouteDef.NAME_RESET_PWD,langUrlsOrSingleString,viewComponent,meta,transferComponentProps_forRouteInfo,B_REST_VueApp_RouteDef.TYPE_PUBLIC_RESET_PWD); }
				_routes_define_404(     langUrlsOrSingleString, viewComponent, meta={}, transferComponentProps_forRouteInfo=undefined) { this._routes_define_public(B_REST_VueApp_RouteDef.NAME_404,      langUrlsOrSingleString,viewComponent,meta,transferComponentProps_forRouteInfo,B_REST_VueApp_RouteDef.TYPE_PUBLIC_404);       }
				_routes_define_403(     langUrlsOrSingleString, viewComponent, meta={}, transferComponentProps_forRouteInfo=undefined) { this._routes_define_public(B_REST_VueApp_RouteDef.NAME_403,      langUrlsOrSingleString,viewComponent,meta,transferComponentProps_forRouteInfo,B_REST_VueApp_RouteDef.TYPE_PUBLIC_403);       }
				_routes_define_profile( langUrlsOrSingleString, viewComponent, meta={}, transferComponentProps_forRouteInfo=undefined) { this._routes_define_auth(  B_REST_VueApp_RouteDef.NAME_PROFILE,  langUrlsOrSingleString,viewComponent,meta,transferComponentProps_forRouteInfo,B_REST_VueApp_RouteDef.TYPE_AUTH_PROFILE);     }
			/*
			Expects module to be found at @/custom/routerViews/modules/<moduleTag>/ and have <ModuleTag>List & <ModuleTag>Form.vue
			WARNING about _routerHack_genericListFormModule_x reactivity prob:
				Since Vue Router's vueRouteObj contains a ref to Vue Router deep inside, it means:
					-Vue "knows" B_REST_VueApp_RouteInfo::fromVueRouterObj() must react when router's current route changes (even if we don't care about the current route but another one)
					-If we make a deep watch on a/something containing a B_REST_VueApp_RouteInfo instance, it might react when router's current route changes
					-Means here, even if transferComponentProps_forRouteInfo() doesn't get called, BrGenericListBase's watch on fromLoader would be called at that time IF we set it as {deep:true}
				To reduce hell, in list & form's transferComponentProps_forRouteInfo() we'll check if the received vueRouterObj hasn't changed,
					and if so, just ret the same obj we sent earlier, to prevent watches from firing again
				Then, in router's afterEach() guard, we'll check if we should "forget" about that cache, so that if we alter between list / form / list / form,
					it will know it shouldn't reuse the same obj
				Also in form, when we go from "/clients/*" to "/clients/123" from creating, we don't want the form to reload but we still want route info in B_REST_App_base etc to change,
					so we use routerHack_isIgnoringReplaceEvents ex in BrGenericFormBase::_fromX_setLoadModel() to prevent trying to reload for no reason
				Finally, if we want to go from "/clients/123" to "/clients/456", we need _routerHack_isCurrentRouteMainComponent_watchHack to force BrGenericFormBase::_fromX_setLoadModel() to be called, since component is being reused and its isCurrentRouteMainComponent doesn't change val (stays true)
			*/
			_routes_define_genericListFormModule(moduleTag, langUrlsOrSingleString, options=null)
			{
				const list_has = !options?.noList;
				const form_has = !options?.noForm;
				
				const ucFirst_moduleTag = B_REST_Utils.string_ucFirst(moduleTag);
				const listComponent = list_has ? () => import(/* webpackMode: "eager" */ "../../../../custom/routerViews/modules/"+moduleTag+"/"+ucFirst_moduleTag+"List.vue") : null;
				const formComponent = form_has ? () => import(/* webpackMode: "eager" */ "../../../../custom/routerViews/modules/"+moduleTag+"/"+ucFirst_moduleTag+"Form.vue") : null;
					//NOTE: The import statement doesn't get run until we need the component, so if path is wrong / doesn't exist, won't know yet (but we'll know just below if businessConfig.checkCodeIntegrity)
				
				//Checks that should only be done in dev, otherwise we end up loading all components on boot
				if (this._businessConfig.checkCodeIntegrity)
				{
					if (list_has && !B_REST_Vuetify_GenericListBase_isDerivedComponentImport(listComponent)) { B_REST_Utils.throwEx(`Module "${moduleTag}"'s received listComponent doesn't extend BrGenericListBase`); }
					if (form_has && !B_REST_Vuetify_GenericFormBase_isDerivedComponentImport(formComponent)) { B_REST_Utils.throwEx(`Module "${moduleTag}"'s received formComponent doesn't extend BrGenericFormBase`); }
				}
				
				const langUrls = this._routes_define_langUrlsToObj(langUrlsOrSingleString);
				this._routes_define_x_assertLayoutComponent();
				
				//List mode
				if (list_has)
				{
					this._routes_define(new B_REST_VueApp_RouteDef({
						name:            `${moduleTag}-list`, //WARNING: If we change this, will have impact in routes_go_moduleList()
						type:            B_REST_VueApp_RouteDef.TYPE_AUTH_MODULE_LIST,
						langUrls:        B_REST_Utils.object_copy(langUrls,/*bDeep*/false),
						viewComponent:   listComponent,
						meta:            {},
						needsAuth:       true,
						layoutComponent: this._routes_define_x_currentLayoutComponent,
						transferComponentProps_forRouteInfo: (routeInfo) => {
							return {
								showTitle:                   true,
								isCurrentRouteMainComponent: true,
								fromLoader:                  true,
							};
								//WARNING: If we alter stuff here, consider doing the same in _routes_define_genericListFormSubModule()
						},
					}));
				}
				
				//Form mode
				if (form_has)
				{
					const formLangUrls = {};
					for (const loop_lang in langUrls)
					{
						let loop_langUrl = langUrls[loop_lang];
						if (loop_langUrl[loop_langUrl.length-1]!=="/") { loop_langUrl+="/"; }
						
						formLangUrls[loop_lang] = `${loop_langUrl}:${B_REST_VueApp_base.ROUTES_PATH_VARS_PK_TAG}(\\*|[\\w-]+)`; //Allows ex for new records "/citizens/*" and existing "/citizens/123-fr"
							//WARNING: If we change this, will have impact in routes_go_moduleForm_x()
					}
					
					this._routes_define(new B_REST_VueApp_RouteDef({
						name:               `${moduleTag}-form`, //WARNING: If we change this, will have impact in routes_go_moduleForm_x()
						type:               B_REST_VueApp_RouteDef.TYPE_AUTH_MODULE_FORM,
						pathVarNames_pkTag: B_REST_App_base.ROUTES_PATH_VARS_PK_TAG,
						langUrls:           formLangUrls,
						viewComponent:      formComponent,
						meta:               {},
						needsAuth:          true,
						layoutComponent:    this._routes_define_x_currentLayoutComponent,
						transferComponentProps_forRouteInfo: (routeInfo) => //Only call by routes_transferComponentPropsToVueRouterObj_forRouteDef(), which is only called by B_REST_VueApp_RouteDef::convertToVueRouteDefObj() when route changes
						{
							//check _routes_define_genericListFormModule() docs for what this is about
							if (!this._routerHack_isIgnoringReplaceEvents) { this._routerHack_isCurrentRouteMainComponent_watchHack++; } //NOTE: If we change algo, change in _routes_define_genericListFormModule() & _routes_define_genericListFormSubModule()
							
							return {
								showTitle:                             true,
								isCurrentRouteMainComponent:           true,
								isCurrentRouteMainComponent_watchHack: this._routerHack_isCurrentRouteMainComponent_watchHack,
								fromLoader:                            true,
								parent_pkTag:                          null,
								parent_modelName:                      null,
								parent_routeName:                      null,
							};
								//WARNING: If we alter stuff here, consider doing the same in _routes_define_genericListFormSubModule()
						},
					}));
				}
			}
				_routes_define_genericListFormModule_listOnly(moduleTag,langUrlsOrSingleString) { this._routes_define_genericListFormModule(moduleTag,langUrlsOrSingleString,{noForm:true}); }
				_routes_define_genericListFormModule_formOnly(moduleTag,langUrlsOrSingleString) { this._routes_define_genericListFormModule(moduleTag,langUrlsOrSingleString,{noList:true}); }
			/*
			Usage ex:
				_routes_define_genericListFormModule("citizen", "/citizens/");
				_routes_define_genericListFormModule("animal",  "/animals/");
				_routes_define_genericListFormSubModule("citizen>animal");
			Yields routes:
				citizen-list:                 "/citizens/"
				citizen-form:                 "/citizens/:pkTag"
				animal-list:                  "/animals/"
				animal-form:                  "/animals/:pkTag"
				citizen-form-sub-animal-list: "/citizens/:citizen/animals"
				citizen-form-sub-animal-form: "/citizens/:citizen/animals/:pkTag"
			*/
			_routes_define_genericListFormSubModule(moduleTags)
			{
				const moduleTagParts = moduleTags.split(">");
				if (moduleTagParts.length!==2) { B_REST_Utils.throwEx(`moduleTags must be like "citizen>animal". Got "${moduleTags}"`); }
				
				const parent_moduleTag = moduleTagParts[0];
				const sub_moduleTag    = moduleTagParts[1];
				if (!parent_moduleTag || !sub_moduleTag) { B_REST_Utils.throwEx(`moduleTags must be non-empty on both sides. Got "${moduleTags}"`); }
				
				const parent_listRouteDef = this._routeDefs[`${parent_moduleTag}-list`] ?? null;
				const sub_listRouteDef    = this._routeDefs[`${sub_moduleTag}-list`]    ?? null;
				const sub_formRouteDef    = this._routeDefs[`${sub_moduleTag}-form`]    ?? null; //Opt, in case we used _routes_define_genericListFormModule_listOnly()
				if (!parent_listRouteDef || !sub_listRouteDef) { this.throwEx(`To use this, must define "${parent_moduleTag}" and "${sub_moduleTag}" routes via 2 calls to _routes_define_genericListFormModule() or _routes_define_genericListFormModule_listOnly() for ex`); }
				
				/*
				Having parent lang urls like "/citoyens" and "/animaux", merge both like "/citoyens/:citizen/animaux"
				WARNING: If we change this, will have impact in routes_go_subModuleForm_x()
				*/
				const langUrls = {};
				for (const loop_lang of this._appLangs)
				{
					let loop_parentPart = parent_listRouteDef.langUrls[loop_lang];
					if (loop_parentPart[loop_parentPart.length-1]!=="/") { loop_parentPart+="/"; }
						
					langUrls[loop_lang] = `${loop_parentPart}:${parent_moduleTag}([\\w-]+)${sub_listRouteDef.langUrls[loop_lang]}`;
				}
				
				//List mode (sub module)
				{
					this._routes_define(new B_REST_VueApp_RouteDef({
						name:                      `${parent_moduleTag}-form-sub-${sub_moduleTag}-list`, //WARNING: If we change this, will have impact in routes_go_subModuleList()
						type:                      B_REST_VueApp_RouteDef.TYPE_AUTH_SUB_MODULE_LIST,
						pathVarNames_parent_pkTag: parent_moduleTag,
						langUrls:                  B_REST_Utils.object_copy(langUrls,/*bDeep*/false), //So ex like the above docs, would be {fr:"/citoyens/:citizen/animaux"}
						viewComponent:             sub_listRouteDef.viewComponent,
						meta:                      {},
						needsAuth:                 true,
						layoutComponent:           sub_listRouteDef.layoutComponent,
						transferComponentProps_forRouteInfo: (routeInfo) => {
							return {
								showTitle:                   true,
								isCurrentRouteMainComponent: true,
								fromLoader:                  true,
							};
						},
					}));
				}
				
				//Form mode (sub module)
				if (sub_formRouteDef)
				{
					const formLangUrls = {};
					for (const loop_lang in langUrls)
					{
						let loop_langUrl = langUrls[loop_lang];
						if (loop_langUrl[loop_langUrl.length-1]!=="/") { loop_langUrl+="/"; }
						
						formLangUrls[loop_lang] = `${loop_langUrl}:${B_REST_VueApp_base.ROUTES_PATH_VARS_PK_TAG}(\\*|[\\w-]+)`; //So ex like the above docs, would be {fr:"/citoyens/:citizen/animaux/:pkTag"}
							//WARNING: If we change this, will have impact in routes_go_subModuleForm_x()
					}
					
					this._routes_define(new B_REST_VueApp_RouteDef({
						name:                      `${parent_moduleTag}-form-sub-${sub_moduleTag}-form`, //WARNING: If we change this, will have impact in routes_go_subModuleForm_x()
						type:                      B_REST_VueApp_RouteDef.TYPE_AUTH_SUB_MODULE_FORM,
						pathVarNames_parent_pkTag: parent_moduleTag,
						pathVarNames_sub_pkTag:    B_REST_VueApp_base.ROUTES_PATH_VARS_PK_TAG,
						langUrls:                  formLangUrls,
						viewComponent:             sub_formRouteDef.viewComponent,
						meta:                      {},
						needsAuth:                 true,
						layoutComponent:           sub_formRouteDef.layoutComponent,
						transferComponentProps_forRouteInfo: (routeInfo) => {
							//Equivalent to B_REST_App_base::routes_current_pathVars_parent_pkTag()
							const parent_pkTag_pathVarName = routeInfo.routeDef.pathVarNames_parent_pkTag;
							if (!parent_pkTag_pathVarName) { B_REST_Utils.throwEx(`Route def got no pathVarName for parent_pkTag`,routeInfo.routeDef); }
							
							const parent_pkTag = routeInfo.pathVars?.[parent_pkTag_pathVarName] ?? null;
							if (!parent_pkTag) { B_REST_Utils.throwEx(`Route info got no parent_pkTag as "${parent_pkTag_pathVarName}" pathVar`,routeInfo); }
							
							//WARNING: If we want to change algo, consider complex CPA case where we have 3 models at the same time, ex we're in a EventForm, we want to choose a FranchiseePark, so open FranchiseeParkList picker, click [+], and transfer a franchisee_fk that has nothing to do w the parent EventForm
							
							//check _routes_define_genericListFormModule() docs for what this is about
							if (!this._routerHack_isIgnoringReplaceEvents) { this._routerHack_isCurrentRouteMainComponent_watchHack++; } //NOTE: If we change algo, change in _routes_define_genericListFormModule() & _routes_define_genericListFormSubModule()
							
							return {
								showTitle:                             true,
								isCurrentRouteMainComponent:           true,
								isCurrentRouteMainComponent_watchHack: this._routerHack_isCurrentRouteMainComponent_watchHack,
								fromLoader:                            true,
								parent_pkTag,
								parent_modelName:                      null,
								parent_routeName:                      parent_moduleTag,
							};
						},
					}));
				}
			}
		_abstract_routes_getRouteInfo_fromPath_retFound(fullPath,routeDef=null,pathVars=null,qsa=null,hashTag=null,lang=null) { return new B_REST_VueApp_RouteInfo(fullPath,routeDef,pathVars,qsa,hashTag,lang); }
		//Funcs to replace the URL wo triggering page reload
			_abstract_routes_go_x_replace_back()
			{
				//NOTE: If we get prob like page scrolls up or etc for no reason, check to merge w helper_router_silentRouteUpdate()
				
				this._router.back(); //NOTE: Back rets undefined, so we can't do back().catch() like for push()
			}
			_abstract_routes_go_x_replace_path(path)
			{
				//NOTE: If we get prob like page scrolls up or etc for no reason, check to merge w helper_router_silentRouteUpdate()
				
				this._router.push(path).catch(B_REST_VueApp_base._routes_go_x_replace_errorHandler);
			}
				static _routes_go_x_replace_errorHandler(e)
				{
					if (Router.isNavigationFailure(e)) { B_REST_Utils.console_info( `Silencing Vue Router navigation failure`,       e); } //Ignore Vue Router throwing errs when we do next(false) on purpose
					else                               { B_REST_Utils.console_error(`Vue Router beforeEach() caught a general error`,e); }
				}
		/*
		Setups checks to do between each navigation (from boot and after), to make sure we have perms and allow redirecting, etc
		NOTES:
			-When we boot, no matter the URL (and existing or not), Vue Router's "from" will always be {fullPath:"/", matched:[], name:null, query, hash}
				See https://router.vuejs.org/api/#start-location
			-The following always apply to "to", and to "from" after boot:
				-If it matches a Vue route obj, we can get its {name, meta:{bREST_routeDef}}
					We'll then convert all of it into a B_REST_App_RouteInfo_base der instance, using its meta.bREST_routeDef
				-Otherwise, we'll get {fullPath:<actual URL>, matched:[], name:null, query, hash}
					We'll also make a B_REST_App_RouteInfo_base der instance, but leaving no link to a routeDef
		*/
		_routes_setupBeforeAfterEachHook()
		{
			this._router.beforeEach(async(to,from,next) =>
			{
				//If we're trying to reboot, stop here wo calling next(), to prevent async stuff from happening when it shouldn't. Check _boot_setUnbooting() docs
				if (this._boot_isUnbooting) { return; }
				
				//Make sure app is loaded before doing anything
				if (this.boot_isBooting) { await this._boot_promiseInfo.promise; } //Can throw
				
				try
				{
					/*
					Get B_REST_VueApp_RouteInfo instances about where we come from and want to go to
					Yields NULL when Vue Router obj don't match our route defs
					IMPORTANT:
						-If Vue Router objs don't point to a known route, we'll still ret a B_REST_VueApp_RouteInfo, but the routeDef prop will be NULL,
							even if we have a catch-all routeDef (B_REST_VueApp_RouteDef::NAME_404)
							We can use B_REST_VueApp_RouteInfo::isUn/Known() to figure out
						-On boot, routeInfo_from will be NULL
						-In B_REST_VueApp_RouteDef::convertToVueRouteDefObj(), we add alias for NAME_LANDPAGE & NAME_404, for either "/" or "*"
					WARNING:
						<v-btn> and such "to" prop matches a path by default but not a route name, so we should do:
							<v-btn :to="{name:'someRouteName'}" />
					*/
					const routeInfo_from        = B_REST_VueApp_base.routes_getRouteInfo_fromVueRouterObj(from);
					const routeInfo_to_intended = B_REST_VueApp_base.routes_getRouteInfo_fromVueRouterObj(to);
					
					const boolOrDiffRouteInfo = await this._routes_beforeNavigationChange(routeInfo_to_intended, routeInfo_from);
					
					if      (boolOrDiffRouteInfo===false)                            { next(false);                        }
					else if (boolOrDiffRouteInfo===true)                             { next(undefined);                    }
					else if (boolOrDiffRouteInfo instanceof B_REST_VueApp_RouteInfo) { next(boolOrDiffRouteInfo.fullPath); } //WARNING: If we get here, boolOrDiffRouteInfo.fullPath must never match routeInfo_to_intended.fullPath, or we'll get an infinite nav guard loop
					
					//Make sure no (filters etc) drawer panel stays open while we change route, as it wouldn't make sense anymore
					if (boolOrDiffRouteInfo!==false && B_REST_Vuetify_RightDrawer.instance.visible) { B_REST_Vuetify_RightDrawer.instance.close(); }
				}
				catch (e)
				{
					B_REST_Utils.console_error(`Got error while switching routes`,e);
					next(false);
				}
			});
			
			/*
			NOTE:
				-Not called if in beforeEach():
					-We end with next(false)
					-We throw an err
					... so for now, not sure how the failure param could be filled
				-Called even when we do routes_silentRouteUpdate(), but it will put _routerHack_isIgnoringReplaceEvents=true
			*/
			this._router.afterEach((to,from,failure) =>
			{
				if (failure!==undefined)
				{
					this.throwEx("TODO: In case of failure, will 'to' point to where we wanted to go, or where we end up being ? Also check against NavigationFailureType");
				}
				
				//NOTE: Check _routes_define_genericListFormModule() docs for what's going on here
				if (this._routerHack_transferComponentPropsToVueRouterObj_last_vueRouterObj!==to)
				{
					this._routerHack_transferComponentPropsToVueRouterObj_last_vueRouterObj = null;
					this._routerHack_transferComponentPropsToVueRouterObj_last_output       = null;
				}
				
				this._routes_current_info = B_REST_VueApp_RouteInfo.fromVueRouterObj(to);
			});
		}
		/*
		Converts Vue Router route info into a uniform instance of B_REST_VueApp_RouteInfo (check its docs)
		Rets NULL if no match
		IMPORTANT:
			-If it doesn't point to a known route, we'll still ret a B_REST_VueApp_RouteInfo, but leave the routeDef prop NULL,
				even if we have a catch-all routeDef (B_REST_VueApp_RouteDef::NAME_404)
				We can use B_REST_VueApp_RouteInfo::isUn/Known() to figure out
		*/
		static routes_getRouteInfo_fromVueRouterObj(vueRouterObj) { return B_REST_VueApp_RouteInfo.fromVueRouterObj(vueRouterObj); }
		//Can ret NULL. Check routes_getRouteInfo_fromVueRouterObj() docs
		routes_getRouteInfo_fromVueRouterObj_currentRoute() { return B_REST_VueApp_base.routes_getRouteInfo_fromVueRouterObj(this._router.currentRoute); }
		/*
		Wrapper for Vue Router's replace() method
		When we call _router.replace() (ex because we've just created a record and want to go from "/citizens/*" to "/citizens/123"),
		it does 2 things we don't want, and there's no way to go around this wo hacking Vue Router:
			-Call beforeRouteUpdate() hooks
			-Call scrollBehavior() hook
		Wrap it here so we can indicate everywhere that we want to ignore such events
		*/
		async routes_silentRouteUpdate(options)
		{
			this._routerHack_isIgnoringReplaceEvents = true;
			
			try
			{
				await this._router.replace(options);
				//WARNING: If we figure out this never ends, it's prolly because we forgot to call next() in our beforeRouteEnter() / beforeRouteUpdate() / beforeRouteLeave() hooks
			}
			catch (e)
			{
				if (e.name==="NavigationDuplicated") { B_REST_Utils.console_warn(`Got a NavigationDuplicated warning from Vue Router`,e);                                            }
				else                                 { B_REST_Utils.console_warn(`Got an error in Vue Router. Prolly ok if it's because we've just done "next(false)" in a hook`,e); }
			}
			
			this._routerHack_isIgnoringReplaceEvents = false;
		}
		/*
		Check _routes_define_genericListFormModule() docs for what's going on here. Used by B_REST_VueApp_RouteDef::convertToVueRouteDefObj()
		Called on target route def for each route change, even if we stay on the same component (ex silently replacing from /clients/* to "/clients/123")
		*/
		routes_transferComponentPropsToVueRouterObj_forRouteDef(routeDef, vueRouterObj)
		{
			B_REST_Utils.instance_isOfClass_assert(B_REST_VueApp_RouteDef, routeDef);
			B_REST_Utils.object_assert(vueRouterObj);
			
			let props = null;
			
			if (this._routerHack_transferComponentPropsToVueRouterObj_last_vueRouterObj===vueRouterObj)
			{
				props = this._routerHack_transferComponentPropsToVueRouterObj_last_output;
				B_REST_Utils.console_info(`transferComponentProps_forRouteInfo() re-called even though route hasn't changed; resending previous returned obj to reduce deep watch hell.\nMight happen when we change UI breakpoint and cols:{} we need aren't the same as prev breakpoint`,{routeDef,vueRouterObj});
			}
			else
			{
				const routeInfo = B_REST_VueApp_base.routes_getRouteInfo_fromVueRouterObj(vueRouterObj);
				
				props = routeDef.transferComponentProps_forRouteInfo ? routeDef.transferComponentProps_forRouteInfo(routeInfo) : null;	
			}
			
			this._routerHack_transferComponentPropsToVueRouterObj_last_vueRouterObj = vueRouterObj;
			this._routerHack_transferComponentPropsToVueRouterObj_last_output       = props;
			
			return props;
		}
		
	
	
	//PICKER POPUPS RELATED. NOTE: Base class also has models_toLabelCache_get() & models_toLabelCache_set(), that help converting an FK into a toLabel()
		get pickers_defs()    { return this._pickers_defs;    }
		get pickers_handles() { return this._pickers_handles; }
		pickers_getDef(name)  { return this._pickers_defs[name] || this.throwEx(`Unknown picker def "${name}"`); }
		/*
		Prepares and rets an instance of B_REST_Vuetify_PickerHandle, without making it appear yet in the UI
		When component appears in UI, can use B_REST_Vuetify_PickerHandle::component to communicate w the inner component
		Check B_REST_Vuetify_PickerHandle docs for possible options
		Throws if picker is unknown
		WARNING:
			If this is used for a picker that binds to a component that is a BrGenericListBase der, then it'll break if we don't pass something like the following for options:
				{
					vBind: {fromLoader:true},
				}
				We can figure out using B_REST_VuetifyPickerDef::component_isGenericList or B_REST_VuetifyPickerDef::component_ifGenericList_moduleName though
			Check BrFieldDb::_final_slotConfig_x()'s usage of pickers_prompt_x(), which ultimately calls pickers_getHandle()
			-> It'll be simpler if passing no option at all would imply the above, but we could create pickers for something else than a generic list
				Maybe we could add a prop in B_REST_Vuetify_PickerDef to tell if it's a generic list or not, so it's done auto
		Pre filtering:
			For now, check BrFieldDb::_picker_emit() docs to infer for what's possible
		*/
		pickers_getHandle(name, options={})
		{
			const pickerDef = this.pickers_getDef(name);
			
			let pickerHandle = null;
			switch (pickerDef.reuseMode)
			{
				//In this mode, we always want to have exactly 1 allocated instance
				case B_REST_Vuetify_PickerDef.REUSE_MODE_CANCEL_PREVIOUS:
					pickerHandle = this._pickers_handles.find(loop_pickerHandle => loop_pickerHandle.def===pickerDef);
				break;
				//In this mode, always use distinct instances
				case B_REST_Vuetify_PickerDef.REUSE_MODE_OFF:
					pickerHandle = null;
				break;
				//Here, depends on the nb of handles we have for that def; keep at least one allocated
				case B_REST_Vuetify_PickerDef.REUSE_MODE_IF_NOT_PROMPTING:
					pickerHandle = this._pickers_handles.find(loop_pickerHandle => loop_pickerHandle.def===pickerDef);
					if (pickerHandle?.isPrompting) { pickerHandle=null; }
				break;
				default:
					this.throwEx(`Unexpected picker handle reuseMode`,pickerHandle);
				break;
			}
			
			if (pickerHandle) { pickerHandle.updateOptions(options); }
			else
			{
				pickerHandle = new B_REST_Vuetify_PickerHandle(pickerDef, options);
				this._pickers_handles.push(pickerHandle);
			}
			
			return pickerHandle;
		}
		/*
		Like pickers_getHandle(), but instead of returning the B_REST_Vuetify_PickerHandle instance,
		it opens it and awaits the result of selecting / closing the picker, returning either:
			<anything>
			[<anything>]
			NULL
		So if we want to access B_REST_Vuetify_PickerHandle::component, we should use pickers_getHandle() instead
		WARNING: Check warning docs in pickers_getHandle() about "vBind:{fromLoader:true}" + pre-filtering
		*/
		async pickers_prompt_single(  name,options={}) { return this._pickers_prompt_x(name,options,false); }
		async pickers_prompt_multiple(name,options={}) { return this._pickers_prompt_x(name,options,true);  }
			async _pickers_prompt_x(name, options, isMultiple)
			{
				B_REST_Utils.object_assert(options);
				
				const pickerHandle = this.pickers_getHandle(name, {...options,isMultiple}); //Throws
				return pickerHandle.prompt();
			}
		//Called by B_REST_Vuetify_PickerHandle::_select(), to check if we should rem them from the handles arr
		pickers_checkRelease(pickerHandle)
		{
			let release = null;
			
			switch (pickerHandle.def.reuseMode)
			{
				//In this mode, we always want to have exactly 1 allocated instance, so never release it
				case B_REST_Vuetify_PickerDef.REUSE_MODE_CANCEL_PREVIOUS:
					release = false;
				break;
				//In this mode, always release after usage
				case B_REST_Vuetify_PickerDef.REUSE_MODE_OFF:
					release = true;
				break;
				//Here, depends on the nb of handles we have for that def; keep at least one allocated
				case B_REST_Vuetify_PickerDef.REUSE_MODE_IF_NOT_PROMPTING:
					release = this._pickers_handles.filter(loop_pickerHandle => loop_pickerHandle.def===pickerHandle.def).length>1;
				break;
				default:
					this.throwEx(`Unexpected picker handle reuseMode`,pickerHandle.def);
				break;
			}
			
			if (release) { B_REST_Utils.array_remove_byVal(this._pickers_handles,pickerHandle); }
		}
			//NOTE: Base class also has models_toLabelCache_get() & models_toLabelCache_set(), that help converting an FK into a toLabel()
	
	
	
	//HELPERS
		/*
		Gives access to a component's <script> definition
		Usage ex:
			//From dynamic import func
			const someComponent = () => import("./someComponent.vue");
			const {props,data,created,computed,methods} = await getVueComponentOptions(someComponent);
			
			//From static import
			import SomeComponent from "./SomeComponent.vue";
			const {props,data,created,computed,methods} = await getVueComponentOptions(SomeComponent);
		NOTE: Doing this 100 times won't slow down the app or cause mem leak. Vue/Webpack will ret the same obj ptr
		*/
		static async getVueComponentOptions(dynamicImportFunc_or_staticImport)
		{
			return B_REST_Utils.function_is(dynamicImportFunc_or_staticImport) ? (await dynamicImportFunc_or_staticImport()).default : dynamicImportFunc_or_staticImport;
		}
		/*
		Vue :class props accept strings, arr and objs
		Makes it easier to add more item, to a prev val we might not have control on
		Usage ex:
			computed: {
				myClasses()
				{
					let classes = <something that gen a string, arr, obj..>
					
					if (...) { x=classProp_addTag(classes,"thing--is-highlighted"); }
					if (...) { x=classProp_addTag(classes,"thing--is-del");         }
					if (...) { x=classProp_addTag(classes,"thing--etc");            }
					
					return classes
				},
			}
		*/
		classProp_addTag(currVal, className)
		{
			if (!currVal)                             { currVal=className;        }
			else if (B_REST_Utils.string_is(currVal)) { currVal+=` ${className}`; }
			else if (B_REST_Utils.array_is( currVal)) { currVal.push(className);  }
			else if (B_REST_Utils.object_is(currVal)) { currVal[className]=true;  }
			else { this.throwEx(`Expected a string, arr or obj`,currVal);         }
			
			return currVal;
		}
		/*
		Same as classProp_addTag(), but for styles, where we must separate what's before and after the =
		Ex:
			styleProp_addTag(style, "backgroundColor","red")
		WARNING: For now, not implementing diffs for kebab-case vs camelCase
		*/
		styleProp_addTag(currVal, propName,propVal)
		{
			if (B_REST_Utils.object_is(currVal)) { currVal[propName]=propVal; }
			else
			{
				const stringified = `${propName}=${propVal}`;
				
				if (!currVal)                             { currVal=stringified;        }
				else if (B_REST_Utils.string_is(currVal)) { currVal+=` ${stringified}`; }
				else if (B_REST_Utils.array_is( currVal)) { currVal.push(stringified);  }
				else { this.throwEx(`Expected a string, arr or obj`,currVal);           }
			}
			
			return currVal;
		}
		/*
		Rets the closest (self included) theme definition on a DOM element, ex ".theme--light" vs ".theme--dark", otherwise app's theme
		NOTE: Rets as "light" and not "theme--light"
		*/
		dom_getClosestTheme(element)
		{
			const self_classList = element.classList;
			if (self_classList.contains("theme--dark"))  { return "dark";  }
			if (self_classList.contains("theme--light")) { return "light"; }
			
			const parent = element.closest(":is(.theme--dark,.theme--light)");
			if (parent) { return parent.classList.contains("theme--dark") ? "dark" : "light"; }
			
			return this.uiTheme;
		}
			/*
			Check dom_getClosestTheme_component() docs; To be used on a Vue component, also checking for self "light" & "dark" props
			WARNING: Mustn't be used before rendered, otherwise $el will be undefined
			*/
			dom_getClosestTheme_component(component)
			{
				if (component.$attrs.dark)  { return "dark";  }
				if (component.$attrs.light) { return "light"; }
				return component.$el ? this.dom_getClosestTheme(component.$el) : this.throwEx(`Using dom_getClosestTheme_component() too early`,component);
			}
	
	
	
	/*
	Helpers to make common prompts (though you can implement all yourself; ex check BrGenericListBase's xPrompt)
	They will appear in BrAppBooter
	For options, check B_REST_Vuetify_Prompt::helper() docs
	Variations:
		await prompt_helper_alert():               Rets nothing; just to block UI to mention something that has to be acknowledged.
		const yes = await prompt_helper_confirm(): Rets bool depending on if we end w B_REST_Vuetify_Prompt.COMMON_ACTIONS_YES vs B_REST_Vuetify_Prompt.COMMON_ACTIONS_CANCEL
		For both, must at least provide {title,body} in loc, and since it sets useCoreCommonLoc=true by default, then no need to make loc for actions, but if we do, will be used instead of core ones
	*/
		get prompts() { return this._prompts; }
		async prompt_helper(options)
		{
			const prompt = B_REST_Vuetify_Prompt.helper(options);
			return this._prompt_helper_await(prompt,options);
		}
		async prompt_helper_alert(options)
		{
			const prompt = B_REST_Vuetify_Prompt.helper_alert(options);
			await this._prompt_helper_await(prompt,options);
			return undefined; //Just to make it clearer that we don't care about the fact that it was B_REST_Vuetify_Prompt.COMMON_ACTIONS_OK
		}
		async prompt_helper_confirm(options)
		{
			const prompt = B_REST_Vuetify_Prompt.helper_confirm(options);
			const result = await this._prompt_helper_await(prompt,options);
			return result===B_REST_Vuetify_Prompt.COMMON_ACTIONS_YES;
		}
			async _prompt_helper_await(prompt,options)
			{
				this._prompts.push(prompt); //Req so it shows up in BrAppBooter
				const result = await prompt.show(options.interceptor??null);
				B_REST_Utils.array_remove_byVal(this._prompts, prompt);
				return result;
			}
	
	
	
	//TMP; Check B_REST_App_base::notifs_tmp_x() docs, to eventually plug w the unused B_REST_App_Notif.js
		get toasterManager() { return this._toasterManager; }
		_abstract_notifs_tmp(msg, color)
		{
			/*
			WARNING:
				Since this is used to toast error msgs from _setupGlobalErrorListeners_parseOne() & Vue.config.warnHandler(),
				it's possible we end up calling this before toaster manager is ready, etc, so it might be still NULL, and mustn't throw
			*/
			
			if (!this._toasterManager) { return; }
			
			try
			{
				this._toasterManager.push(msg, color, B_REST_Vuetify_ToasterManager_Item.POS_BOTTOM);
			}
			catch (e) { } //Prevent endless loops
		}
};
