
import B_REST_Utils      from "../B_REST_Utils.js";
import B_REST_DOMFilePtr from "../files/B_REST_DOMFilePtr.js";



export class B_REST_Request_base
{
	static get METHOD_GET()    { return "GET";    }
	static get METHOD_POST()   { return "POST";   }
	static get METHOD_PUT()    { return "PUT";    }
	static get METHOD_PATCH()  { return "PATCH";  }
	static get METHOD_DELETE() { return "DELETE"; }
	
	static get NEEDS_ACCESS_TOKEN_DONT() { return "dont"; } //Ex for login calls, where if we were already logged in we don't want to pass old token
	
	
	_method              = null;                           //One of self::METHOD_x
	_path_raw            = null;                           //Ex "/brands/{brand}/compats/{lead}/action"
	_path_vars           = {};                             //Ex {brand:123, lead:456}
	_path_parsed         = null;                           //Ex "/brands/123/compats/456/action"
	_qsa                 = {};                             //The query string arguments, ex "?bob=123&patente=5879", as obj
	_expectsContentType  = B_REST_Utils.CONTENT_TYPE_JSON; //What we expect in return. Note that it can also be B_REST_Utils.CONTENT_TYPE_EMPTY for 204, or B_REST_Utils.CONTENT_TYPE_ANYTHING
	_fetchAsBlob         = false;                          //If we want the parser to retrieve data automatically with .text() or .json(), or use a .blob() instead (usually for files). NOTE: Not related to expected content type
	_data                = null;                           //As obj, string or FormData, if POST/PUT/PATCH
	_isMultipartFormData = null;                           //If it should be as FormData and hold some instances of B_REST_DOMFilePtr that will be sent raw, instead of base64 encoded in a JSON call
	_needsAccessToken    = true;                           //If required and token isn't set, will die. Either a bool or NEEDS_ACCESS_TOKEN_DONT (ex for login calls)
	_extraHeaders        = {};                             //Map to pass extra stuff in headers. Will be sent as ONE JSON header in B_REST_API. Check B_REST_API::call() usage docs for why it's so
	_shortName           = null;                           //Optional name to describe the request
	
	
	//WARNING: If path_raw hardcoded QSA, they'll be stripped off the path and moved to _qsa
	constructor(method, path_raw, path_vars=null, isMultipartFormData=false, fetchAsBlob=false)
	{
		this._reConstruct(method, path_raw, path_vars, isMultipartFormData, fetchAsBlob)
	}
		/*
		WARNING:
			If we change method, we could end up w a B_REST_Request_GET where method is POST instead of GET,
			so never check for "instanceof <derived class>" directly; always check against method
		*/
		_reConstruct(method, path_raw, path_vars=null, isMultipartFormData=false, fetchAsBlob=false)
		{
			if (!path_raw) { B_REST_Request_base._throwEx("Got no path"); }
			
			//Do this, to make sure we strip any forced QSA out of the path
			const path_raw_urlInfo = B_REST_Utils.url_getInfo(path_raw);
			
			const inlineThrowEx = (msg) =>
			{
				const qsaString = path_raw_urlInfo.qsa ? `?${B_REST_Utils.url_qsaToString(path_raw_urlInfo.qsa)}` : "";
				B_REST_Request_base._throwEx(`${msg}, for "${method}: ${path_raw_urlInfo.path}${qsaString}"`);
			};
			
			let path_parsed = path_raw_urlInfo.path;
			
			//Do validation now
			{
				if (isMultipartFormData && !B_REST_Request_base._method_canHaveData(method)) { inlineThrowEx(`Method "${method}" can't hold data, so can't be flagged as multipart/form-data`); }
				
				//Path vars related
				{
					const path_vars_reqArr       = B_REST_Request_base._path_vars_find(path_raw_urlInfo.path);
					const path_vars_received_has = path_vars!==null && !B_REST_Utils.object_isEmpty(path_vars); //Throws if not an obj
					
					if (path_vars_reqArr)
					{
						if (!path_vars_received_has) { inlineThrowEx("Path vars expected"); }
						
						const path_vars_reqArr_hashed   = path_vars_reqArr.sort().join(",");
						const path_vars_received_hashed = Object.keys(path_vars).sort().join(",");
						if (path_vars_reqArr_hashed!==path_vars_received_hashed) { inlineThrowEx(`Expected path vars {${path_vars_reqArr_hashed}}, got {${path_vars_received_hashed}}`); }
						
						//Now we can finish parsing path
						for (const loop_var_name of path_vars_reqArr)
						{
							path_parsed = path_parsed.replace(new RegExp(`{${loop_var_name}}`,"g"), path_vars[loop_var_name]);
						}
					}
					else if (path_vars_received_has) { inlineThrowEx("Path vars not expected"); }
				}
			}
			
			/*
			Check to initialize POST/PUT/PATCH data as obj or FormData, if required / if we re-call this again, just make sure having data or not made sense
			NOTE for data / FormData:
				User can still unset it back to NULL, or to string
				B_REST_API's constructor takes care of calling B_REST_Utils.assert_formData_support(); (doesn't work in IE)
			*/
			{
				const method_canHaveData = B_REST_Request_base._method_canHaveData(method);
				
				//If was never instantiated
				if (this._isMultipartFormData===null)
				{
					if (method_canHaveData) { this._data=isMultipartFormData?new FormData():{}; }
				}
				else if (this._data)
				{
					if (!method_canHaveData)                             { inlineThrowEx(`New method can't keep the data already in request; consider nullifying data first`);                      }
					if (this._isMultipartFormData!==isMultipartFormData) { inlineThrowEx(`Can't change isMultipartFormData while we already have data in request; consider nullifying data first`); }
				}
				else if (method_canHaveData) { this._data=isMultipartFormData?new FormData():{}; }
			}
			
			this._method              = method;
			this._path_raw            = path_raw_urlInfo.path;
			this._path_vars           = path_vars;
			this._path_parsed         = path_parsed;
			this._qsa                 = path_raw_urlInfo.qsa;
			this._fetchAsBlob         = fetchAsBlob;
			this._isMultipartFormData = isMultipartFormData;
		}
	
	static _throwEx(msg) { B_REST_Utils.throwEx(msg); }
	       _throwEx(msg) { B_REST_Utils.throwEx(`${msg}, for "${this._method}:${this.url_parsed}"`); }
	
	
	
	get method()           { return this._method; }
	get method_is_GET()    { return this._method===B_REST_Request_base.METHOD_GET;    }
	get method_is_POST()   { return this._method===B_REST_Request_base.METHOD_POST;   }
	get method_is_PUT()    { return this._method===B_REST_Request_base.METHOD_PUT;    }
	get method_is_PATCH()  { return this._method===B_REST_Request_base.METHOD_PATCH;  }
	get method_is_DELETE() { return this._method===B_REST_Request_base.METHOD_DELETE; }
	static _method_canHaveData(method) { return [B_REST_Request_base.METHOD_POST,B_REST_Request_base.METHOD_PUT,B_REST_Request_base.METHOD_PATCH].includes(method); }
		_method_canHaveData_assert()
		{
			if (!B_REST_Request_base._method_canHaveData(this._method)) { this._throwEx(`Method ${this._method} can't have data`); }
		}
	
	
	
	get path_raw()    { return this._path_raw;    }
	get path_parsed() { return this._path_parsed; }
	//NOTE: Rets a copy of the path vars, to avoid hell
	get path_vars() { return B_REST_Utils.object_copy(this._path_vars,true); }
		/*
		Rets NULL, or an arr of unique path vars found in a path
		Usage ex:
			"/brands/{brand}/compats/{lead}/action"
				-> ["brand", "lead"]
			"/brands/123/compats/456/action"
				-> null
		*/
		static _path_vars_find(path_raw)
		{
			const arr = [];
			
			for (const loop_match of path_raw.matchAll(/\{([^}]+)\}/g))
			{
				arr.push(loop_match[1]);
			}
			
			return arr.length>0 ? arr : null;
		}
	
	
	
	set method(val)              { this._throwEx_useReConstruct(); }
	set path_raw(val)            { this._throwEx_useReConstruct(); }
	set path_vars(val)           { this._throwEx_useReConstruct(); }
	set path_parsed(val)         { this._throwEx_useReConstruct(); }
	set fetchAsBlob(val)         { this._throwEx_useReConstruct(); }
	set isMultipartFormData(val) { this._throwEx_useReConstruct(); }
		_throwEx_useReConstruct() { this._throwEx(`To change this, use reConstruct()`); }
		/*
		Allows changing method & whole URL again. If we don't pass isMultipartFormData / fetchAsBlob, will keep prev values
		WARNING: Check _reConstruct warnings
		*/
		reConstruct(method, path_raw, path_vars=null, isMultipartFormData=null, fetchAsBlob=null)
		{
			if (isMultipartFormData===null) { isMultipartFormData=this._isMultipartFormData; }
			if (fetchAsBlob        ===null) { fetchAsBlob        =this._fetchAsBlob;         }
			this._reConstruct(method, path_raw, path_vars, isMultipartFormData, fetchAsBlob);
		}
	
	
	
	get qsa()     { return this._qsa; }
	get qsa_has() { return !B_REST_Utils.object_isEmpty(this._qsa); }
	get qsa_parsed()
	{
		if (!this.qsa_has) { this._throwEx("Has no QSA"); }
		return B_REST_Utils.url_qsaToString(this._qsa);
	}
	/*
	NOTE:
		For now, we can't specify a nested struct to be auto reverted in backend, ex:
			request.qsa_add("filters", {firstName:"hey",age:30});
		Must do this for now:
			request.qsa_add("filters[firstName]", "hey");
			request.qsa_add("filters[age]",       30);
		Then, backend will properly get it as a nested arr
		It could be easier to improve, if we had a mode where _qsa was allowed to be a string instead of an obj
	*/
	set qsa(obj)
	{
		B_REST_Utils.object_assert(obj);
		this._qsa = obj;
	}
		//Shortcut
		qsa_add(key, val) { this._qsa[key] = val; }
	
	//IMPORTANT: Check var's docs
	get extraHeaders()     { return this._extraHeaders; }
	get extraHeaders_has() { return !B_REST_Utils.object_isEmpty(this._extraHeaders); }
	set extraHeaders(obj)
	{
		B_REST_Utils.object_assert(obj);
		this._extraHeaders = obj;
	}
		//Shortcut
		extraHeaders_add(key, val) { this._extraHeaders[key] = val; }
	
	get extraData_ui()  { B_REST_Utils.throwEx(`Confusing w B_REST_Model props`); }
	get extraData_api() { B_REST_Utils.throwEx(`Confusing w B_REST_Model props`); }
	
	//path_parsed + qsa
	get url_parsed()
	{
		return this.qsa_has ? `${this.path_parsed}?${this.qsa_parsed}` : this.path_parsed;
	}
	//Ex as "[shortName] <METHOD>: <url_parsed>"
	get url_debug()
	{
		return (this._shortName?`[${this._shortName}] `:"") + `${this._method}: ${this.url_parsed}`;
	}
	
	
	
	get expectsContentType()    { return this._expectsContentType; }
	set expectsContentType(val) { this._expectsContentType = val;  }
	//Helpers
		expectsContentType_anything() { this._expectsContentType = B_REST_Utils.CONTENT_TYPE_ANYTHING; }
		expectsContentType_empty()    { this._expectsContentType = B_REST_Utils.CONTENT_TYPE_EMPTY;    }
		expectsContentType_text()     { this._expectsContentType = B_REST_Utils.CONTENT_TYPE_TEXT;     }
		expectsContentType_csv()      { this._expectsContentType = B_REST_Utils.CONTENT_TYPE_CSV;      }
		expectsContentType_html()     { this._expectsContentType = B_REST_Utils.CONTENT_TYPE_HTML;     }
		expectsContentType_json()     { this._expectsContentType = B_REST_Utils.CONTENT_TYPE_JSON;     }
		expectsContentType_image()    { this._expectsContentType = B_REST_Utils.CONTENT_TYPE_IMAGE;    }
		expectsContentType_pdf()      { this._expectsContentType = B_REST_Utils.CONTENT_TYPE_PDF;      }
	
	
	
	get data()     { return this._data;        }
	get data_has() { return this._data!==null; }
	//NOTE: User can still unset it back to NULL, or to string
	set data(val)
	{
		this._method_canHaveData_assert();
		
		if (val===undefined) { this._throwEx(`Data can't be undefined`); }
		
		if (val!==null)
		{
			if (this._isMultipartFormData !== (val instanceof FormData)) { this._throwEx("Can only have an instance of FormData if it's in multipart/form-data mode"); }
		}
		
		this._data = val;
	}
	/*
	Shortcut to add 1 prop at a time.
	Ex:
		Instead of:
			#1:
				request.data = {
					firstName: ...,
					lastName:  ...,
				};
			#2:
				const formData = new FormData();
				formData.append("firstName", ...);
				formData.append("lastName",  ...);
				request.data = formData;
		Do:
			request.data_set("firstName", ...);
			request.data_set("lastName",  ...);
	Works no matter we want data as JSON or multipart/form-data.
	To add instances of B_REST_DOMFilePtr, use data_add_file_x() instead
	For more info about fieldNamePath:
		Ex "cv", "docs" or "subThing.logo", for a model like:
			{
				firstName,
				lastName,
				cv:{...},
				docs:[{...}],
				subThing: {
					logo:{...}
				}
			}
		Check B_REST_Utils.parseFieldNamePath() & server side Model_base::_field_parseFieldNamePath() for dot notation
	*/
	data_set(fieldNamePath, val)
	{
		this._method_canHaveData_assert();
		
		const {self_fieldName, atIdx, target_fieldNameOrExpr} = B_REST_Utils.parseFieldNamePath(fieldNamePath);
		if (atIdx!==null || target_fieldNameOrExpr) { this._throwEx(`In multipart form/data mode, fieldNamePath can't contain arrs or nested obj struct, for "${fieldNamePath}"`); }
		
		if (val instanceof B_REST_DOMFilePtr) { this._throwEx(`For B_REST_DOMFilePtr instances, use data_add_file_x() instead, for "${self_fieldName}"`); }
		
		if (this._isMultipartFormData) { this._data.append(self_fieldName,val); }
		else
		{
			this._data_set_nested(this._data, self_fieldName, atIdx, target_fieldNameOrExpr, val);
		}
	}
		_data_set_nested(objLvl, self_fieldName, atIdx, target_fieldNameOrExpr, val)
		{
			const expectArr = atIdx!==null;
			
			if (!B_REST_Utils.object_hasPropName(objLvl,self_fieldName) || objLvl[self_fieldName]===null)
			{
				//Either create an arr of at least X items, an empty obj or a null
				if (expectArr) { objLvl[self_fieldName] = Array.from({length:atIdx+1},(_,i)=>null); }
				else           { objLvl[self_fieldName] = target_fieldNameOrExpr ? {} : null;       }
			}
			
			//Validate type of what we look into
			if (expectArr)
			{
				B_REST_Utils.array_assert(objLvl[self_fieldName]);
				if (atIdx>=objLvl[self_fieldName].length) { this._throwEx(`Accessing out of range [${atIdx}] for "${self_fieldName}"`); }
			}
			else if (objLvl[self_fieldName]!==null) { B_REST_Utils.object_assert(objLvl[self_fieldName]); }
			
			//If we'll need to nest again
			if (target_fieldNameOrExpr)
			{
				if      ( expectArr && objLvl[self_fieldName][atIdx]===null) { objLvl[self_fieldName][atIdx]={}; }
				else if (!expectArr && objLvl[self_fieldName]       ===null) { objLvl[self_fieldName]       ={}; }
				
				const sub_objLvl = expectArr ? objLvl[self_fieldName][atIdx] : objLvl[self_fieldName];
				
				const {sub_self_fieldName, sub_atIdx, sub_target_fieldNameOrExpr} = B_REST_Utils.parseFieldNamePath(target_fieldNameOrExpr);
				this._data_set_nested(sub_objLvl, sub_self_fieldName, sub_atIdx, sub_target_fieldNameOrExpr, val);
			}
			else if (expectArr) { objLvl[self_fieldName][atIdx] = val; }
			else                { objLvl[self_fieldName]        = val; }
		}
	/*
	What this does depends on if we wanted to convert files as base64 in a JSON prop, or as raw files in a FormData instance.
	Ex:
		As multipart/form-data:
			const formData = new FormData();
			formData.append("files[]", new Blob(...))
			formData.append("files[]", await bRestFile.to_blob())
		As JSON:
			{
				firstName,
				lastName,
				cv: <base64>,
				otherStuff: await bRestFile.to_base64DataURL(),
			}
	Other usage exs:
		var files = [
			new B_REST_DOMFilePtr(canvas, "my-canvas.png"),
			new B_REST_DOMFilePtr(img,    "my-img.png"),
			B_REST_DOMFilePtr.from_fileInput(<input>), //WARNING: Rets NULL though if not set
		];
		const multiple_files = B_REST_DOMFilePtr.from_fileInput(<input multiple>); //Or NULL
		if (multiple_files) { files = files.concat(multiple_files); }
		await request.data_add_file_multiple("files[]", files);
	Or even simpler:
		await request.data_add_file_multiple("files[]", B_REST_DOMFilePtr.from_fileInput(<input multiple>);
		await request.data_add_file_single("files",     B_REST_DOMFilePtr.from_fileInput(<input>);
	Expects an instance of B_REST_DOMFilePtr, holding any of <img>, <canvas>, ArrayBuffer, File, Blob, base64DataURL or objectURL
	If we choose to upload as multipart/form-data, then we can specify file names, via B_REST_DOMFilePtr.baseNameWExt
	Async, because of conversion between the various accepted formats
	*/
	async data_add_file_single(key, file)
	{
		this._method_canHaveData_assert();
		this._data_add_file_x_assertFile(key, file);
		
		try
		{
			if (this._isMultipartFormData)
			{
				const blobData = await file.to_blob();
				this._data.append(key, blobData, file.baseNameWExt);
			}
			else
			{
				const base64 = await file.to_base64DataURL();
				this._data[key] = base64;
			}
		}
		catch (e) { this._data_add_file_x_catchConversionError(e); }
	}
	//Same thing as for data_add_file_single(), but expects an arr of B_REST_DOMFilePtr instances, and the key must end in "[]", ex "files[]", if multipart/form-data
	async data_add_file_multiple(key, fileList)
	{
		this._method_canHaveData_assert();
		B_REST_Utils.array_isOfClassInstances_assert(B_REST_DOMFilePtr, fileList);
		if (fileList.length === 0)            { this._throwEx(`Got no files, for Key "${key}"`);   }
		for (let i=0; i<fileList.length; i++) { this._data_add_file_x_assertFile(key,fileList[i]); }
		
		if (this._isMultipartFormData)
		{
			if (!key.match(/\[\]$/)) { this._throwEx(`Key "${key}" must be as "multipleFiles[]" in multipart/form-data mode`); }
			
			try
			{
				for (let i=0; i<fileList.length; i++)
				{
					const loop_file     = fileList[i];
					const loop_blobData = await loop_file.to_blob();
					this._data.append(key, loop_blobData, loop_file.baseNameWExt);
				}
			}
			catch (e) { this._data_add_file_x_catchConversionError(e); }
		}
		else
		{
			this._data[key] = [];
			
			try
			{
				for (let i=0; i<fileList.length; i++)
				{
					const loop_file   = fileList[i];
					const loop_base64 = await loop_file.to_base64DataURL();
					this._data[key].push(loop_base64);
				}
			}
			catch (e) { this._data_add_file_x_catchConversionError(e); }
		}
	}
		_data_add_file_x_assertFile(key, file)
		{
			if (!(file instanceof B_REST_DOMFilePtr)) { this._throwEx(`Expected an instance of B_REST_DOMFilePtr, for key "${key}"`); }
		}
		_data_add_file_x_catchConversionError(e)
		{
			this._throwEx(`Got exception while trying to convert file to other format. If it's because we're trying to convert a <canvas> or <img> on localhost, it won't work :(:\n${e}`);
		}
	/*
	Evals content type against specified data, whether it's a string, json, or FormData
	Yields one of:
		B_REST_Utils.CONTENT_TYPE_TEXT
		B_REST_Utils.CONTENT_TYPE_FORM_DATA
		B_REST_Utils.CONTENT_TYPE_JSON
	*/
	get data_contentType()
	{
		this._method_canHaveData_assert();
		if (!this.data_has) { this._throwEx("Data not set"); }
		
		return B_REST_Utils.contentType_evalFromData(this._data);
	}
	
	data_calculateSize()
	{
		this._method_canHaveData_assert();
		if (!this.data_has) { this._throwEx("Data not set"); }
		
		if (this._isMultipartFormData)
		{
			let sum = 0;
			
			for (const loop_val of this._data)
			{
				sum += loop_val instanceof Blob ? loop_val.size : loop_val.toString().length;
			}
			
			return sum;
		}
		else { return B_REST_Utils.json_encode(this._data).length; }
	}
	
	
	//Either bool or B_REST_Request_base::NEEDS_ACCESS_TOKEN_DONT (ex for login calls)
	get needsAccessToken()     { return this._needsAccessToken; }
	set needsAccessToken(val)  { this._needsAccessToken = val;  }
	needsAccessToken_setDont() { this._needsAccessToken = B_REST_Request_base.NEEDS_ACCESS_TOKEN_DONT; } //Helper
	
	
	get shortName()    { return this._shortName; }
	set shortName(val) { this._shortName = val;  }
	
	
	get fetchAsBlob() { return this._fetchAsBlob; }
};






//WARNING: Check warnings in _reConstruct() about changing method
	export class B_REST_Request_GET extends B_REST_Request_base
	{
		constructor(path_raw,path_vars=null) { super(B_REST_Request_base.METHOD_GET,path_raw,path_vars,null,false); }	
		set pageIndex(val) { this.qsa_add("pageIndex", val); } //Zero based
		set pageSize(val)  { this.qsa_add("pageSize",  val); } //Nb of results per call
		set sort(val)      { this.qsa_add("sort",      val); }
	};

	export class B_REST_Request_POST            extends B_REST_Request_base { constructor(path_raw,path_vars=null) { super(B_REST_Request_base.METHOD_POST,  path_raw,path_vars,false,false); } };
	export class B_REST_Request_POST_Multipart  extends B_REST_Request_base { constructor(path_raw,path_vars=null) { super(B_REST_Request_base.METHOD_POST,  path_raw,path_vars,true, false); } };
	export class B_REST_Request_PUT             extends B_REST_Request_base { constructor(path_raw,path_vars=null) { super(B_REST_Request_base.METHOD_PUT,   path_raw,path_vars,false,false); } };
	export class B_REST_Request_PUT_Multipart   extends B_REST_Request_base { constructor(path_raw,path_vars=null) { super(B_REST_Request_base.METHOD_PUT,   path_raw,path_vars,true, false); } };
	export class B_REST_Request_PATCH           extends B_REST_Request_base { constructor(path_raw,path_vars=null) { super(B_REST_Request_base.METHOD_PATCH, path_raw,path_vars,false,false); } };
	export class B_REST_Request_PATCH_Multipart extends B_REST_Request_base { constructor(path_raw,path_vars=null) { super(B_REST_Request_base.METHOD_PATCH, path_raw,path_vars,true, false); } };
	export class B_REST_Request_DELETE          extends B_REST_Request_base { constructor(path_raw,path_vars=null) { super(B_REST_Request_base.METHOD_DELETE,path_raw,path_vars,null, false); } };


	//Check B_REST_API::call_download() & B_REST_API::call_download_inlineNewWindow()
	export class B_REST_Request_GET_File extends B_REST_Request_base
	{
		constructor(path_raw,path_vars=null)
		{
			super(B_REST_Request_base.METHOD_GET, path_raw, path_vars, null, true); //fetchAsBlob = true
			this.expectsContentType_anything();
		}
	};
	export class B_REST_Request_POST_File extends B_REST_Request_base
	{
		constructor(path_raw,path_vars=null)
		{
			super(B_REST_Request_base.METHOD_POST, path_raw, path_vars, null, true); //fetchAsBlob = true
			this.expectsContentType_anything();
		}
	};
